import { BlankStateOnCreate } from "@ui/PageBlankState";
import { TableBlankState } from "@ui/TableBlankState";

export const WebhooksBlankState = ({
  onCreate,
  disabled,
}: BlankStateOnCreate) => {
  return (
    <TableBlankState
      title="No webhooks created yet"
      description={<>Add a webhook to start receiving webhook events.</>}
      buttonText="Create webhook"
      onCreate={onCreate}
      disabled={disabled}
    />
  );
};
