import { NotFound } from "@components/blanks/pages/NotFound";
import { SinglePageLoader } from "@components/loaders/SinglePageLoader";
import { LabeledTooltip } from "@components/ui/LabeledTooltip";
import { useContextQuery } from "@hooks/useContextQuery";
import { usePermission } from "@hooks/usePermission";
import { CompanyMembershipDetailResponseData } from "@models/api";
import { ClerkUserPermission } from "@models/clerkUser";
import { getCompanyUser } from "@modules/companyUsers/queries";
import { useSchematicFlag } from "@schematichq/schematic-react";
import { BreadCrumbTypes } from "@ui/BreadCrumbs";
import { DataBlock, DataBlockTypes } from "@ui/DataBlocks";
import { DropdownDots } from "@ui/DropdownDots";
import { Logo } from "@ui/Logo";
import { PageWrapper } from "@ui/PageWrapper";
import { PermissionButton } from "@ui/PermissionButton";
import { ProfileDetails } from "@ui/ProfileDetails";
import { UserInitials } from "@ui/UserInitials";
import { ViewPageBody } from "@ui/ViewPageBody";
import { ViewPageHeader } from "@ui/ViewPageHeader";
import { timeAgo } from "@utils/date";
import cx from "classnames";
import pluralize from "pluralize";
import { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { CompanyUserDeleteOverlay, CompanyUserEditOverlay } from "./overlays";
import { CompanyUserSubview } from "../types";

export const CompanyUserView = () => {
  const { id } = useParams() as {
    id: string;
  };

  const { data: user, isLoading } = useContextQuery({
    queryKey: [`user`, id],
    queryFn: () => getCompanyUser(id),
    retry: false,
  });

  const [editOverlay, setEditOverlay] = useState(false);
  const [deleteOverlay, setDeleteOverlay] = useState(false);
  const companyUserEditAllowed = usePermission(
    ClerkUserPermission.company_users_edit,
  );
  const userEditFlag = useSchematicFlag("user.edit", { fallback: true });

  // Overlays
  const onEdit = () => {
    setEditOverlay(true);
  };

  const onDelete = () => {
    setDeleteOverlay(true);
  };

  const onClose = () => {
    setEditOverlay(false);
    setDeleteOverlay(false);
  };

  if (isLoading) {
    return <SinglePageLoader />;
  } else if (!user) {
    return <NotFound />;
  }

  const crumbsData: BreadCrumbTypes[] = [
    {
      name: "users",
      active: false,
      url: "../users",
    },
    {
      name: `${user.name}`,
      active: true,
      url: `../users/${id}`,
    },
  ];

  const tabsData = [
    {
      path: CompanyUserSubview.Overview,
      label: "Overview",
    },
    {
      path: CompanyUserSubview.Usage,
      label: "Usage",
    },
  ];

  const infoData = [
    user.companyMemberships && {
      title: pluralize("Company", user.companyMemberships.length),
      label: (
        <div
          className={cx(
            "grid mt-2",
            user.companyMemberships.length === 1
              ? "grid-cols-1 gap-0"
              : "grid-cols-2 gap-2",
          )}
        >
          {user.companyMemberships.map(
            (membership: CompanyMembershipDetailResponseData) => {
              const { company } = membership;
              if (!company) return null;

              return (
                <Link to={`../companies/${company.id}`} key={company.id}>
                  <div className="flex items-center mr-2">
                    <div className="flex items-center justify-center">
                      <Logo
                        src={company.logoUrl}
                        alt={company.name}
                        size="sm"
                      />
                    </div>
                    <div className="ml-1">{company.name}</div>
                  </div>
                </Link>
              );
            },
          )}
        </div>
      ),
    },
    ...(userEditFlag
      ? [
          {
            children: (
              <div className="flex space-x-2">
                <PermissionButton
                  onClick={onEdit}
                  disabled={!companyUserEditAllowed}
                >
                  Edit user
                </PermissionButton>
                <DropdownDots
                  size="md"
                  links={[
                    {
                      label: "Delete user",
                      disabled: !companyUserEditAllowed,
                      onClick: onDelete,
                    },
                  ]}
                />
              </div>
            ),
          },
        ]
      : []),
  ].filter(Boolean) as DataBlockTypes[];

  const lastSeen = user.lastSeenAt
    ? `Last seen ${timeAgo(user.lastSeenAt)}`
    : "No events from user yet";

  return (
    <PageWrapper>
      <div className="flex flex-col flex-1 h-[calc(100vh-92px)] overflow-hidden overflow-y-auto">
        <ViewPageHeader crumbsData={crumbsData} tabsData={tabsData}>
          <ProfileDetails
            image={<UserInitials name={user.name} size="xl" />}
            title={user.name}
            label={
              <div className="flex items-center space-x-1">
                <div>{lastSeen}</div>
                <LabeledTooltip
                  description="This is the date a track or identify event associated with this user was last received by Schematic."
                  className="!bg-transparent"
                  placement="top-right"
                  size="lg"
                />
              </div>
            }
          />

          <DataBlock data={infoData} />
        </ViewPageHeader>
        <ViewPageBody data={{ user }} />
      </div>
      {editOverlay && <CompanyUserEditOverlay onClose={onClose} user={user} />}
      {deleteOverlay && (
        <CompanyUserDeleteOverlay onClose={onClose} user={user} />
      )}
    </PageWrapper>
  );
};
