import { BlankStateOnCreate, PageBlankState } from "@ui/PageBlankState";

export const PlansBlankState = ({ onCreate, disabled }: BlankStateOnCreate) => {
  return (
    <PageBlankState
      title="💼 Create your first plan!"
      description={
        <>
          A plan can be a base plan or an add-on. Tie the plan to a
          <br />
          billing product to track and target based on subscription.
        </>
      }
      buttons={
        onCreate && [
          {
            color: "blue",
            children: <>Create plan</>,
            disabled,
            onClick: onCreate,
          },
        ]
      }
      type="static"
      className="py-28"
    />
  );
};
