import * as Tooltip from "@radix-ui/react-tooltip";
import cx from "classnames";
import { ButtonHTMLAttributes, ReactNode } from "react";

export type ButtonStyleTypes = "blue" | "white" | "red" | "black";
export type ButtonSizeTypes = "2xs" | "xs" | "sm" | "md" | "lg";

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  color?: ButtonStyleTypes;
  className?: string;
  size?: ButtonSizeTypes;
  disabledTooltip?: string | ReactNode;
}

export const Button = ({
  color = "white",
  size = "md",
  onClick,
  disabled = false,
  children,
  className,
  disabledTooltip,
  ...props
}: ButtonProps) => {
  const sizeMap = {
    "2xs": "button-2xs",
    xs: "button-xs",
    sm: "button-sm",
    md: "button-md",
    lg: "button-lg",
  };

  const disabledStyles = "button-disabled";
  const styleMap = {
    blue: "button-blue",
    white: "button-white",
    red: "button-red",
    black: "button-black",
  };

  const styles = cx(
    "button",
    disabled ? disabledStyles : styleMap[color],
    sizeMap[size],
    className,
  );

  return (
    <>
      {disabled && disabledTooltip ? (
        <Tooltip.Provider delayDuration={0}>
          <Tooltip.Root>
            <Tooltip.Trigger asChild>
              <button
                className={styles}
                onClick={onClick}
                disabled={disabled}
                {...props}
              >
                {children}
              </button>
            </Tooltip.Trigger>
            <Tooltip.Portal>
              <Tooltip.Content
                sideOffset={10}
                className="bg-white p-4 rounded-md shadow-md w-64 text-gray-500 text-sm"
              >
                {disabledTooltip}
              </Tooltip.Content>
            </Tooltip.Portal>
          </Tooltip.Root>
        </Tooltip.Provider>
      ) : (
        <button
          className={styles}
          onClick={onClick}
          disabled={disabled}
          {...props}
        >
          {children}
        </button>
      )}
    </>
  );
};
