import { Icon } from "@components/ui/Icon";
import * as integrationsApi from "@data/integrations";
import { useContextQuery } from "@hooks/useContextQuery";
import { InstalledIntegration } from "@models/integrations";
import { BillingCustomersTable } from "@modules/integrations/components/tables/BillingCustomersTable";
import { BillingProductsTable } from "@modules/integrations/components/tables/BillingProductsTable";
import { useSchematicFlag } from "@schematichq/schematic-react";
import { SettingsBox } from "@ui/SettingsBox";
import { StripeIntegrationForm } from "../../../settings/components/StripeIntegrationForm";

export const StripeIntegrationPage = () => {
  const getIntegrations = useContextQuery({
    queryKey: ["getIntegrations"],
    queryFn: () => integrationsApi.listIntegrations({ type: "stripe" }),
  });

  const customerImportEnabledFlag = useSchematicFlag(
    "integration.stripe.customers.import",
  );

  const integration = (): InstalledIntegration | null => {
    if (
      getIntegrations.isSuccess &&
      getIntegrations.data?.[0] &&
      getIntegrations.data?.[0].state === "active"
    ) {
      return getIntegrations.data?.[0];
    }
    if (
      getIntegrations.isSuccess &&
      getIntegrations.data?.[0] &&
      (getIntegrations.data?.[0] as InstalledIntegration).config?.version ===
        2 &&
      (getIntegrations.data?.[0] as InstalledIntegration).config
        ?.onboarding_url !== undefined
    ) {
      return getIntegrations.data?.[0];
    }

    return null;
  };

  const getConnectedStripeEnvironment = () => {
    if (!integration()) {
      return <></>;
    }
    const stripeIntegration = getIntegrations.data?.[0];
    if (stripeIntegration && stripeIntegration.config?.version === 2) {
      switch (stripeIntegration.config?.live_mode) {
        case false:
          return (
            <label className="text-sm text-yellow-700">
              Connected in test mode
            </label>
          );
        default:
          return (
            <label className="text-sm text-green-700">
              Connected in live mode
            </label>
          );
      }
    }

    if (!stripeIntegration || !stripeIntegration.config?.version) {
      return <></>;
    }

    return getIntegrations.data?.[0]?.config?.api_key?.startsWith("sk_test")
      ? "test"
      : "live";
  };

  return (
    <div className="flex flex-col space-y-8 max-w-full justify-items-start w-full">
      <div>
        <div>
          <SettingsBox
            title="Stripe Integration"
            label={getConnectedStripeEnvironment()}
            description={
              <>
                Rollout flags, define entitlements, and build plan audiences
                based on Stripe subscriptions.{" "}
                <a
                  target="_blank"
                  href="https://docs.schematichq.com/integrations/stripe"
                  className="cursor-pointer text-blue-400 hover:underline hover:opacity-80"
                >
                  Learn more in the docs
                  <Icon
                    name="arrow-right"
                    className="-rotate-45 inline-block ml-1 text-base"
                  />
                </a>
                .
              </>
            }
            style="boxed"
            className="h-auto"
          >
            <div className=" absolute right-12 top-12 flex p-1 items-center rounded-full border border-gray-300">
              <Icon
                name="stripe"
                className="text-[#6772E5] text-2xl leading-none"
              />
            </div>
            <StripeIntegrationForm />
          </SettingsBox>
        </div>
      </div>

      {getIntegrations.isSuccess &&
        integration() &&
        customerImportEnabledFlag && (
          <div>
            <BillingCustomersTable />
          </div>
        )}

      {getIntegrations.isSuccess && integration() && (
        <div>
          <BillingProductsTable />
        </div>
      )}

      <div>
        <SettingsBox
          title="Unique Identifier Key"
          description="Map Stripe Customers to Companies in Schematic. Include the Stripe customer ID in any API request or event sent to Schematic."
          style="boxed"
          className="h-auto"
        >
          <div className="flex space-x-6 items-center">
            <div className="flex items-center space-x-4">
              <div className="inline-flex p-1 items-center rounded-full border border-gray-300">
                <Icon
                  name="stripe"
                  className="text-[#6772E5] text-2xl leading-none"
                />
              </div>
              <div className="font-medium text-lg">customer_id</div>
            </div>
            <div>
              <Icon name="arrow-right" className="text-3xl leading-none" />
            </div>
            <div className="flex items-center space-x-4">
              <div className="inline-flex p-1 items-center rounded-full border border-gray-300">
                <Icon
                  name="schematic"
                  className="text-[#DB6769] text-2xl leading-none"
                />
              </div>
              <div className="font-medium text-lg">stripe_customer_id</div>
            </div>
          </div>
        </SettingsBox>
      </div>
    </div>
  );
};
