import { NotFound } from "@components/blanks/pages/NotFound";
import { SinglePageLoader } from "@components/loaders/SinglePageLoader";
import { ClipCopy } from "@components/ui/ClipCopy";
import { DropdownDots } from "@components/ui/DropdownDots";
import * as api from "@data/features";
import { useContextQuery } from "@hooks/useContextQuery";
import { usePermission } from "@hooks/usePermission";
import { ClerkUserPermission } from "@models/clerkUser";
import { FeatureFlag } from "@models/feature";
import { FeatureDeleteOverlay } from "@modules/features/components/overlays/FeatureDeleteOverlay";
import { BreadCrumbTypes } from "@ui/BreadCrumbs";
import { DataBlock, DataBlockTypes, DataPillBlock } from "@ui/DataBlocks";
import { Icon, IconRound } from "@ui/Icon";
import { PageWrapper } from "@ui/PageWrapper";
import { PermissionButton } from "@ui/PermissionButton";
import { Pill } from "@ui/Pill";
import { ProfileDetails } from "@ui/ProfileDetails";
import { FeatureSidebar } from "@ui/Sidebar/FeaturesSidebar";
import { User } from "@ui/User";
import { ViewPageBody } from "@ui/ViewPageBody";
import { ViewPageHeader } from "@ui/ViewPageHeader";
import { timeAgo } from "@utils/date";
import { useMemo, useState } from "react";
import { useParams } from "react-router-dom";

import { FeatureOverlay } from "./overlays/FeatureOverlay";
import { FeatureTypeDisplay, FeatureTypeIcon } from "../consts";
import { FeatureSubview } from "../types";

export const FeatureView = () => {
  const { id } = useParams() as {
    id: string;
  };
  const [editOverlay, setEditOverlay] = useState(false);
  const [deleteOverlay, setDeleteOverlay] = useState(false);
  const featureEditAllowed = usePermission(ClerkUserPermission.features_edit);

  // Overlays
  const onEdit = () => {
    setEditOverlay(true);
  };

  const onDelete = () => {
    setDeleteOverlay(true);
  };

  const onClose = () => {
    setEditOverlay(false);
    setDeleteOverlay(false);
  };

  const { data: feature, isLoading } = useContextQuery({
    queryKey: [`feature`, id],
    queryFn: () => api.getFeature(id),
    retry: false,
  });

  const infoData = useMemo<Array<DataBlockTypes>>(() => {
    const editButton = {
      children: (
        <div className="flex items-center justify-center flex-1 space-x-2 h-full">
          <PermissionButton onClick={onEdit} disabled={!featureEditAllowed}>
            Edit
          </PermissionButton>
          <DropdownDots
            size="md"
            links={[
              {
                label: "Delete feature",
                onClick: onDelete,
                disabled: !featureEditAllowed,
              },
            ]}
          />
        </div>
      ),
    };

    if (!feature?.maintainer) {
      return [editButton];
    }

    let displayName = feature.maintainer.id;
    if (feature.maintainer.firstName && feature.maintainer.lastName) {
      displayName = `${feature.maintainer.firstName} ${feature.maintainer.lastName}`;
    } else if (feature.maintainer.emailAddresses.length > 0) {
      displayName = feature.maintainer.emailAddresses[0].emailAddress;
    }

    return [
      feature?.trait
        ? {
            title: "Trait",
            label: (
              <DataPillBlock data={feature.trait?.displayName} icon="hash" />
            ),
            className: "hidden md:block",
          }
        : null,
      feature?.eventSubtype
        ? {
            title: "Event",
            label: (
              <DataPillBlock data={feature.eventSubtype} icon="arrow-upward" />
            ),
            className: "hidden lg:block",
          }
        : null,
      {
        title: "Type",
        label: (
          <div className="flex flex-row items-center">
            <Icon
              className="text-2xl leading-[.8em] text-gray-500 mr-0.5"
              name={FeatureTypeIcon[feature.featureType]}
            />
            <span className="text-sm">
              {FeatureTypeDisplay[feature.featureType]}
            </span>
          </div>
        ),
        className: "md:hidden lg:block",
      },
      {
        title: "Maintainer",
        label: (
          <User
            image={feature.maintainer.profileImageUrl || undefined}
            name={displayName}
          />
        ),
        className: "hidden 2xl:block",
      },
      editButton,
    ].filter(Boolean) as DataBlockTypes[];
  }, [feature, featureEditAllowed]);

  if (isLoading) {
    return <SinglePageLoader />;
  } else if (!feature) {
    return <NotFound />;
  }

  const flag = feature.flags[0];

  const crumbData: BreadCrumbTypes[] = [
    {
      name: "features",
      active: false,
      url: "../features",
    },
    {
      name: feature.name,
      active: true,
      url: `../features/${id}`,
    },
  ];

  const tabsData = (flag: FeatureFlag | null) => {
    if (flag) {
      return [
        {
          path: FeatureSubview.Entitlements,
          label: "Entitlements",
        },
        {
          path: FeatureSubview.Flag,
          label: "Flag",
        },
        {
          path: FeatureSubview.Usage,
          label: "Usage",
        },
        {
          path: FeatureSubview.Companies,
          label: "Companies",
        },
        {
          path: FeatureSubview.Users,
          label: "Users",
        },
      ];
    } else {
      return [
        {
          path: FeatureSubview.Usage,
          label: "Events",
        },
      ];
    }
  };

  const featureLastModified = feature.updatedAt
    ? `Last edited ${timeAgo(feature.updatedAt)}`
    : "-";

  const featureProfileTitle = (
    <>
      {feature.name}
      {flag && (
        <Pill color="gray" type="tag" text="code" className="ml-2 lowercase">
          <ClipCopy data={flag.key} size="sm" />
        </Pill>
      )}
    </>
  );

  return (
    <PageWrapper>
      <div className="flex flex-row w-full">
        <div className="flex flex-col flex-1  h-[calc(100vh-92px)] overflow-hidden overflow-y-auto">
          <ViewPageHeader
            crumbsData={crumbData}
            description={feature?.description}
            tabsData={tabsData(flag)}
          >
            <ProfileDetails
              image={<IconRound style="outline" name={feature.icon} />}
              title={featureProfileTitle}
              label={featureLastModified}
            />
            <DataBlock data={infoData} />
          </ViewPageHeader>
          <ViewPageBody data={{ feature, onEdit }} />
        </div>
        <FeatureSidebar feature={feature} onEdit={onEdit} />
      </div>

      {editOverlay && <FeatureOverlay onClose={onClose} feature={feature} />}
      {deleteOverlay && (
        <FeatureDeleteOverlay
          onClose={onClose}
          feature={feature}
          onDelete={onDelete}
        />
      )}
    </PageWrapper>
  );
};
