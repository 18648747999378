import { Article } from "@consts/links";
import { BlankStateOnCreate, PageBlankState } from "@ui/PageBlankState";

export const CompaniesBlankState = ({
  onCreate,
  disabled,
}: BlankStateOnCreate) => {
  return (
    <PageBlankState
      title="⏳ No data yet"
      type="static"
      description={
        <>
          Send events into Schematic to begin tracking and targeting
          <br />
          based on Companies, Users, and usage.
        </>
      }
      buttons={
        onCreate && [
          {
            color: "blue",
            children: <>Create company</>,
            disabled,
            onClick: onCreate,
          },
        ]
      }
      links={{
        title: "Learn how to:",
        links: [
          {
            label: "Identify Companies and Users",
            url: Article.IDENTIFY_COMPANY_AND_USERS,
          },
        ],
      }}
    />
  );
};
