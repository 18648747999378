import SchematicOverlayLoader from "@components/loaders/SchematicOverlayLoader";
import { listFeatures } from "@data/features";
import { errorMessage } from "@data/index";
import { FormikAsyncSelect, Option } from "@forms/FormikAsyncSelect";
import {
  CreatePlanEntitlementRequestBody,
  EntityTraitDefinitionResponseData,
} from "@models/api";
import { EntitlementValueType, PlanEntitlement } from "@models/entitlement";
import { Feature, FeatureType } from "@models/feature";
import { Plan, PlanType } from "@models/plan";
import {
  createPlanEntitlement,
  listPlans,
  updatePlanEntitlement,
} from "@modules/plans";
import { useQueryClient } from "@tanstack/react-query";
import { Alert } from "@ui/Alert";
import { DynamicTitle } from "@ui/DynamicTitle";
import { FormColumn, FormHeader } from "@ui/FormParts";
import {
  Overlay,
  OverlayFooter,
  OverlayHeaderClose,
  OverlayModal,
} from "@ui/Overlay";
import { Separator } from "@ui/Separator";
import { Form, Formik, FormikHelpers } from "formik";
import { useState } from "react";
import { EntitlementFeatureBlock } from "./EntitlementFeatureBlock";
import { editEntitlementValidationSchema } from "./validation";

export type FormValues = CreatePlanEntitlementRequestBody & {
  feature?: Feature;
  plan?: Plan;
  trait?: EntityTraitDefinitionResponseData;
};

export interface PlanEntitlementOverlayProps {
  feature?: Feature;
  onClose: () => void;
  plan?: Plan;
  planEntitlement?: PlanEntitlement;
}

export const PlanEntitlementEditOverlay = ({
  feature,
  onClose,
  plan,
  planEntitlement,
}: PlanEntitlementOverlayProps) => {
  feature ||= planEntitlement?.feature;
  plan ||= planEntitlement?.plan;

  const queryClient = useQueryClient();
  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState<string | undefined>();

  const defaultValueType =
    feature?.featureType === FeatureType.Boolean
      ? EntitlementValueType.Boolean
      : EntitlementValueType.Numeric;

  const initialValues: FormValues = {
    feature: planEntitlement?.feature ?? feature,
    featureId: planEntitlement?.featureId ?? feature?.id ?? "",
    metricPeriod: planEntitlement?.metricPeriod ?? undefined,
    metricPeriodMonthReset:
      planEntitlement?.metricPeriodMonthReset ?? undefined,
    plan: planEntitlement?.plan ?? plan,
    planId: planEntitlement?.planId ?? plan?.id ?? "",
    trait: planEntitlement?.valueTrait,
    valueBool: planEntitlement?.valueBool ?? true,
    valueNumeric: planEntitlement?.valueNumeric ?? 0,
    valueTraitId: planEntitlement?.valueTraitId,
    valueType: planEntitlement?.valueType ?? defaultValueType,
  };

  const onSubmit = async (
    { feature, plan, trait, ...rest }: FormValues,
    helpers: FormikHelpers<CreatePlanEntitlementRequestBody>,
  ) => {
    setLoading(true);

    try {
      const saveFn = planEntitlement?.id
        ? (values: CreatePlanEntitlementRequestBody) =>
            updatePlanEntitlement(planEntitlement.id, values)
        : createPlanEntitlement;

      await saveFn(rest);

      await queryClient.invalidateQueries();

      onClose();

      setApiError(undefined);
      helpers.setSubmitting(false);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setApiError(errorMessage(error));
      helpers.setSubmitting(false);
      setLoading(false);
    }
  };

  return (
    <Overlay
      onClose={onClose}
      className="flex items-center justify-center py-24"
    >
      {loading && <SchematicOverlayLoader />}
      <OverlayModal size="lg">
        <OverlayHeaderClose onClose={onClose} />
        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={editEntitlementValidationSchema}
          validateOnChange={false}
          validateOnBlur={false}
        >
          {({ values, setFieldValue, errors, isValid }) => (
            <Form className="py-12">
              <div className="flex-1 px-12">
                <FormHeader
                  label="Plan entitlement"
                  title={
                    <DynamicTitle
                      fromLabel={values.feature?.name ?? "Feature"}
                      from={values.feature?.name}
                      toLabel={values.plan?.name ?? "Plan"}
                      to={values.plan?.name}
                    />
                  }
                  compact
                />
                <FormColumn>
                  <FormikAsyncSelect
                    label="Plan"
                    name="plan"
                    defaultOptions
                    disabled={!!plan}
                    loadOptions={listPlans(PlanType.Plan)}
                    loadOptionsMappers={{
                      requestFilter: feature && {
                        withoutEntitlementFor: feature.id,
                      },
                    }}
                    selectedOption={
                      values.plan && {
                        value: values.plan,
                        label: values.plan?.name || values.planId,
                      }
                    }
                    onChange={async (option: {
                      value: Plan;
                      label: string;
                    }) => {
                      await setFieldValue("planId", option?.value.id);
                    }}
                  />

                  <Separator />

                  <FormikAsyncSelect
                    label="Feature"
                    name="feature"
                    defaultOptions
                    disabled={!!feature}
                    loadOptions={listFeatures}
                    loadOptionsMappers={{
                      requestFilter: plan && {
                        withoutPlanEntitlementFor: plan.id,
                      },
                    }}
                    selectedOption={
                      values.feature && {
                        value: values.feature,
                        label: values.feature?.name || values.featureId,
                      }
                    }
                    onChange={async (option: Option) => {
                      await setFieldValue("featureId", option?.value.id);

                      if (option?.value.featureType === FeatureType.Boolean) {
                        await Promise.all([
                          setFieldValue("metricPeriod", undefined),
                          setFieldValue("metricPeriodMonthReset", undefined),
                          setFieldValue("valueBool", true),
                          setFieldValue("valueNumeric", undefined),
                          setFieldValue(
                            "valueType",
                            EntitlementValueType.Boolean,
                          ),
                        ]);
                      } else {
                        await Promise.all([
                          setFieldValue("valueType", defaultValueType),
                          setFieldValue("valueBool", undefined),
                          setFieldValue("valueNumeric", 0),

                          // If value type was set to boolean, set it to numeric, since boolean value type is invalid for a non-boolean feature
                          values.valueType === EntitlementValueType.Boolean &&
                            setFieldValue(
                              "valueType",
                              EntitlementValueType.Numeric,
                            ),
                        ]);
                      }
                    }}
                  />

                  {!isValid && (
                    <Alert size="xs" style="yellow">
                      {JSON.stringify(errors)}
                    </Alert>
                  )}
                </FormColumn>
              </div>

              <EntitlementFeatureBlock
                values={values}
                setFieldValue={setFieldValue}
              />

              {apiError && (
                <div className="pt-12 px-12">
                  <Alert size="xs" style="red">
                    <div className="flex items-center justify-center space-x-2">
                      <div className="text-base font-body">
                        <span className="font-semibold">Uh-oh!</span> {apiError}
                      </div>
                    </div>
                  </Alert>
                </div>
              )}

              {values.featureId && values.planId && (
                <div className="px-12 pt-6">
                  <OverlayFooter>
                    <button
                      className="button button-sm button-blue"
                      type="submit"
                    >
                      Save changes
                    </button>
                  </OverlayFooter>
                </div>
              )}
            </Form>
          )}
        </Formik>
      </OverlayModal>
    </Overlay>
  );
};
