import { PlanType } from "@models/plan";
import { PlansConfigurationView } from "@modules/plans/components/PlansConfigurationView";
import { ErrorBoundaryFallback } from "@ui/ErrorBoundaryFallback";
import { Navigate, RouteObject } from "react-router-dom";
import { PlansView, PlanView } from "./components";
import {
  PlanAudienceTab,
  PlanBillingTab,
  PlanEntitlementsTab,
} from "./components/tabs";
import { PlanRoutePaths } from "./consts";
import { PlanSubview } from "./types";

export const planRoutes: RouteObject[] = [
  {
    path: PlanRoutePaths.Plans,
    element: <PlansView type={PlanType.Plan} />,
    errorElement: <ErrorBoundaryFallback />,
  },
  {
    path: PlanRoutePaths.AddOns,
    element: <PlansView type={PlanType.AddOn} />,
    errorElement: <ErrorBoundaryFallback />,
  },
  {
    path: PlanRoutePaths.Configuration,
    element: <PlansConfigurationView />,
    errorElement: <ErrorBoundaryFallback />,
  },
  {
    path: `${PlanRoutePaths.Plans}/:id/:subview?`,
    element: <PlanView type={PlanType.Plan} />,
    errorElement: <ErrorBoundaryFallback />,
    children: [
      {
        index: true,
        element: <Navigate to={PlanSubview.Entitlements} replace />,
      },
      {
        path: PlanSubview.Entitlements,
        element: <PlanEntitlementsTab />,
      },
      { path: PlanSubview.Audience, element: <PlanAudienceTab /> },
      { path: PlanSubview.Billing, element: <PlanBillingTab /> },
    ],
  },
  {
    path: `${PlanRoutePaths.AddOns}/:id/:subview?`,
    element: <PlanView type={PlanType.AddOn} />,
    errorElement: <ErrorBoundaryFallback />,
    children: [
      {
        index: true,
        element: <Navigate to={PlanSubview.Entitlements} replace />,
      },
      {
        path: PlanSubview.Entitlements,
        element: <PlanEntitlementsTab />,
      },
      { path: PlanSubview.Audience, element: <PlanAudienceTab /> },
      { path: PlanSubview.Billing, element: <PlanBillingTab /> },
    ],
  },
];
