import { ClipCopy } from "@components/ui/ClipCopy";
import { EntityKeyDetailResponseData } from "@models/api";
import { EntityTraitDetailResponseData } from "@models/api";
import { EntityTraitType } from "@models/entityTrait";
import { ReactNode } from "react";
import { formatDate } from "./date";
import { formatCurrency } from "./strings";

export type DetailData = {
  label: string;
  children: ReactNode | string;
};

export const formatKeyValue = (
  entityKey: EntityKeyDetailResponseData,
  copiable = false,
): DetailData => {
  const { key, value } = entityKey;
  const children = copiable ? <ClipCopy data={value} truncate /> : value;

  return {
    label: key,
    children,
  };
};

export const truncatedString = (string: string, length: number): ReactNode => {
  try {
    if (string.length <= length) {
      return string;
    }

    return <span title={string}>{string.slice(0, length)}...</span>;
  } catch (error) {
    console.error("Error truncating string", string, error); // TODO: Sentry
    return string;
  }
};

export const formatEntityTraitValue = (
  entityTrait: EntityTraitDetailResponseData,
) => {
  const stringValue =
    (entityTrait.value as unknown) instanceof Date
      ? entityTrait.value.toString()
      : entityTrait.value;

  const { definition } = entityTrait;
  if (!definition) {
    return;
  }

  let children: ReactNode;
  const maxLength = 50;

  try {
    switch (definition.traitType) {
      case EntityTraitType.Boolean:
        children =
          entityTrait.value === "true" ? (
            <span>
              <span className="mr-2 inline-block text-xs">🟢</span> True
            </span>
          ) : (
            <span>
              <span className="mr-2 inline-block text-xs">🔴</span> False
            </span>
          );
        break;
      case EntityTraitType.Currency:
        children = formatCurrency(parseInt(stringValue, 10));
        break;
      case EntityTraitType.Date:
        children = formatDate(stringValue);
        break;
      case EntityTraitType.URL:
        children = (
          <ClipCopy data={stringValue} truncate>
            <a href={stringValue} target="_blank" rel="noopener noreferrer">
              {stringValue}
            </a>
          </ClipCopy>
        );
        break;
      default:
        children = truncatedString(stringValue, maxLength);
    }
  } catch (error) {
    console.error("Error formatting entity trait value", entityTrait, error); // TODO: Sentry
    children = truncatedString(stringValue, maxLength);
  }

  return children;
};

export function arrayMove<T>(array: readonly T[], from: number, to: number) {
  const slicedArray = array.slice();
  slicedArray.splice(
    to < 0 ? array.length + to : to,
    0,
    slicedArray.splice(from, 1)[0],
  );

  return slicedArray;
}
