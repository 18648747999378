import { FormikAsyncSelect, Option } from "@forms/FormikAsyncSelect";
import { PlanGroupPlanDetailResponseData } from "@models/api";
import { listAddOnsWithEntitlements } from "@modules/plans/queries/planConfiguration";
import { Alert } from "@ui/Alert";
import { FormColumn, FormHeader } from "@ui/FormParts";
import { Icon } from "@ui/Icon";
import { Overlay, OverlayModal } from "@ui/Overlay";
import { PlanLabel } from "@ui/PlanLabel";
import { Form, Formik } from "formik";
import React from "react";

type AddOnOption = Option & {
  entity: PlanGroupPlanDetailResponseData;
};

type FormValues = {
  addOns: PlanGroupPlanDetailResponseData[];
};

type AddActiveAddOnsOverlayProps = {
  selectedAddOns: PlanGroupPlanDetailResponseData[];
  setActiveAddOns: (addOns: PlanGroupPlanDetailResponseData[]) => void;
  onClose: () => void;
};

export const AddActiveAddOnsOverlay = ({
  selectedAddOns,
  setActiveAddOns,
  onClose,
}: AddActiveAddOnsOverlayProps) => {
  const initialValues: FormValues = {
    addOns: [],
  };

  const onSubmit = ({ addOns }: FormValues) => {
    setActiveAddOns(addOns);
    onClose();
  };

  return (
    <Overlay
      onClose={onClose}
      className="flex items-center justify-center py-24"
    >
      <OverlayModal>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={onSubmit}
        >
          {({ setFieldValue }) => (
            <Form className="flex flex-col">
              <div className="flex h-full flex-1 max-h-full overflow-hidden relative">
                <button
                  type="button"
                  onClick={onClose}
                  className="inline-flex absolute z-[500] top-6 right-6 hover:cursor-pointer text-black/50 hover:text-blue-400"
                >
                  <Icon name="close" className="text-3xl" />
                </button>

                <div className="flex flex-col flex-1 px-12 py-12 h-full min-h-[632px] max-h-[750px]  max-w-full overflow-hidden overflow-y-scroll">
                  <div className="flex-1 overflow-y-auto">
                    <FormHeader
                      label="Live add ons"
                      title="Choose Live Add Ons"
                      compact
                    />
                    <FormColumn>
                      <Alert style="yellow" size="xs" className="flex">
                        <div className="mr-3">
                          <Icon
                            name="exclamation-rounded-filled"
                            className="text-2xl leading-none text-yellow-300"
                          />
                        </div>
                        <div>
                          {`Only add ons with a Stripe product and a default price
                          (monthly and/or yearly) can be selected.`}
                        </div>
                      </Alert>
                      <FormikAsyncSelect
                        className="flex-1"
                        label="Add On"
                        name="addOnIds"
                        placeholder="Type to select add on..."
                        defaultOptions
                        isMulti
                        loadOptions={listAddOnsWithEntitlements}
                        loadOptionsMappers={{
                          mapperFunction: (addOn) => ({
                            value: addOn.id,
                            label: <PlanLabel plan={addOn} font="normal" />,
                            entity: addOn,
                          }),
                          requestFilter: {
                            hasProductId: true,
                          },
                        }}
                        selectedOption={selectedAddOns.map((addOn) => ({
                          value: addOn.id,
                          label: <PlanLabel plan={addOn} font="normal" />,
                          entity: addOn,
                        }))}
                        onChange={(options: AddOnOption[]) => {
                          setFieldValue(
                            "addOns",
                            options.map(({ entity }) => entity),
                          );
                        }}
                      />
                    </FormColumn>
                  </div>
                  <div className="flex mt-6 items-end justify-end space-x-2">
                    <button
                      className="button button-sm button-blue"
                      type="submit"
                    >
                      Save changes
                    </button>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </OverlayModal>
    </Overlay>
  );
};
