import { listBillingPrices } from "@data/billing";
import { listFeatures } from "@data/features";
import { FormikAsyncSelect, Option } from "@forms/FormikAsyncSelect";
import { FormikSelect } from "@forms/FormikSelect";
import { useContextQuery } from "@hooks/useContextQuery";
import { BillingPriceView } from "@models/api";
import { BillingPriceDetailResponseData } from "@models/api/models/BillingPriceDetailResponseData";
import { Feature } from "@models/feature";
import { listPlanEntitlements } from "@modules/plans";
import { Button } from "@ui/Button";
import { formatCurrency } from "@utils/strings";
import { useEffect, useState } from "react";
import {
  UsageBasedEntitlements,
  UsageBasedPriceBehavior,
} from "./PlanEditOverlay";

const UsageBasedPriceOptionLabel = ({
  prop,
}: {
  prop: BillingPriceDetailResponseData;
}) => {
  return (
    <div className={"flex flex-row gap-2 justify-between p-2"}>
      <div className={"font-semibold text-sm"}>{prop.productName}</div>
      <div className={"text-sm"}>
        {formatCurrency(prop.price, prop.currency)}/{prop.interval} per unit
      </div>
    </div>
  );
};

type UsageBasedEntitlementsProps = {
  planId?: string;
  onEntitlementsChange: (entitlements: UsageBasedEntitlements[]) => void;
};

type EntitlementRenderOptions = {
  usageType: "pay_as_you_go" | "pay_in_advance" | null;
  price?: BillingPriceView | null;
  feature?: Feature | null;
  planId?: string | undefined;
  interval?: string | null;
};

const usageTypeLabels = {
  pay_as_you_go: "Pay as you go",
  pay_in_advance: "Pay in Advance",
};

const UsageBasedEntitlementsBlock = ({
  planId,
  onEntitlementsChange,
}: UsageBasedEntitlementsProps) => {
  const [existingUsageBasedEntitlements, setExistingUsageBasedEntitlements] =
    useState<EntitlementRenderOptions[]>([]);

  const {
    isPending,
    isSuccess,
    data: entitlements,
  } = useContextQuery({
    queryKey: ["list_usage_based_plan_entitlements"],
    queryFn: () => {
      if (!planId) {
        return Promise.resolve([]);
      }

      return listPlanEntitlements({
        planId: planId,
        withMeteredProducts: true,
      });
    },
  });

  useEffect(() => {
    if (isSuccess) {
      // prepare options to render
      const prepared: EntitlementRenderOptions[] = entitlements.map((e) => {
        const price = e.meteredMonthlyPrice
          ? e.meteredMonthlyPrice
          : e.meteredYearlyPrice;
        return {
          usageType:
            e.priceBehavior === UsageBasedPriceBehavior.PayAsYouGo
              ? "pay_as_you_go"
              : "pay_in_advance",
          feature: e.feature,
          price: price,
          interval: price?.interval,
        };
      });

      if (prepared.length === 0) {
        prepared.push({
          price: null,
          feature: null,
          usageType: null,
          interval: null,
        });
      }

      setExistingUsageBasedEntitlements(prepared);
      console.log("Rendered entitlements:", prepared);
    }
  }, [isSuccess]);

  const addNewUsageBasedEntitlement = () => {
    setExistingUsageBasedEntitlements([
      ...existingUsageBasedEntitlements,
      {
        planId: planId,
        feature: null,
        price: null,
        usageType: null,
        interval: null,
      },
    ]);
    console.log(existingUsageBasedEntitlements);
  };

  const removeUsageEntitlementByIdx = (idx: number) => {
    setExistingUsageBasedEntitlements(
      existingUsageBasedEntitlements.filter((_, index) => index !== idx),
    );
  };

  useEffect(() => {
    const newEntitlementsStateToUpdateTheForm =
      existingUsageBasedEntitlements.map(
        (entitlementRenderOption: EntitlementRenderOptions) => {
          let behavior = UsageBasedPriceBehavior.PayAsYouGo;
          if (entitlementRenderOption.usageType === "pay_in_advance") {
            behavior = UsageBasedPriceBehavior.PayInAdvance;
          }

          return {
            meteredMonthlyPrice:
              entitlementRenderOption.interval === "month"
                ? entitlementRenderOption.price!
                : undefined,
            meteredYearlyPrice:
              entitlementRenderOption.interval === "year"
                ? entitlementRenderOption.price!
                : undefined,
            feature: entitlementRenderOption.feature!,
            priceBehavior: behavior,
          } as UsageBasedEntitlements;
        },
      );

    onEntitlementsChange(newEntitlementsStateToUpdateTheForm);
  }, [existingUsageBasedEntitlements]);

  if (isPending) {
    return (
      <div>
        <p>Loading entitlements</p>
      </div>
    );
  }

  return (
    <div className="max-w-2xl mx-auto p-2 space-y-8">
      <div className="space-y-5">
        <div
          className={
            "flex flex-col justify-between gap-5 bg-gray-100 p-6 rounded"
          }
        >
          {existingUsageBasedEntitlements.length === 0 && (
            <div>
              <p className={"text-lg"}>
                Add your first usage-based entitlement
              </p>
            </div>
          )}

          {/*Rendering existing usage based entitlements for the billing plan*/}
          {existingUsageBasedEntitlements.map((entitlement, index) => {
            return (
              <div
                key={index}
                className={"flex flex-col justify-between gap-5"}
              >
                <div className={"flex flex-row justify-between gap-5"}>
                  <span className={"text-xl block"}>
                    Usage based feature #{index + 1}
                  </span>

                  <span
                    className={"cursor-pointer text-red-600"}
                    onClick={() => removeUsageEntitlementByIdx(index)}
                  >
                    Delete
                  </span>
                </div>

                <FormikSelect
                  label={"Type"}
                  name={`entitlement[${index}].usageType`}
                  defaultOptions
                  selectedOption={() => {
                    if (!entitlement.usageType) {
                      return null;
                    }

                    return {
                      label: usageTypeLabels[entitlement.usageType],
                      value: entitlement.usageType,
                    };
                  }}
                  options={[
                    {
                      label: "Pay as you go",
                      value: "pay_as_you_go",
                    },
                    {
                      label: "Pay in advance",
                      value: "pay_in_advance",
                    },
                  ]}
                  maxNumberOfSelected={1}
                  onChange={(option: Option) => {
                    console.log(option.value);
                    existingUsageBasedEntitlements[index].usageType =
                      option.value as "pay_as_you_go" | "pay_in_advance";
                    entitlement.usageType = option.value;
                    console.log(
                      "Usage type changed",
                      option.value,
                      "on index",
                      index,
                    );
                    existingUsageBasedEntitlements[index] = entitlement;
                    setExistingUsageBasedEntitlements([
                      ...existingUsageBasedEntitlements,
                    ]);
                  }}
                />

                {entitlement.usageType !== null && (
                  <div className={"flex flex-col justify-evenly gap-5"}>
                    <FormikAsyncSelect
                      label="Feature"
                      name={`entitlement[${index}]`}
                      defaultOptions
                      selectedOption={() => {
                        if (!entitlement.feature) {
                          return null;
                        }

                        return {
                          label: entitlement.feature?.name,
                          value: entitlement.feature,
                        };
                      }}
                      maxNumberOfSelected={1}
                      loadOptions={listFeatures}
                      loadOptionsMappers={{
                        requestFilter: {
                          withoutPlanEntitlementFor: entitlement.planId,
                        },
                        resultsFilter: (feature: Feature) => {
                          if (entitlement.usageType === "pay_as_you_go") {
                            return !!feature.eventSubtype;
                          }
                          if (entitlement.usageType === "pay_in_advance") {
                            return !!feature.traitId;
                          }
                          return false;
                        },
                      }}
                      onChange={async (options: Option) => {
                        entitlement.feature = options.value;
                        existingUsageBasedEntitlements[index] = entitlement;
                        setExistingUsageBasedEntitlements([
                          ...existingUsageBasedEntitlements,
                        ]);
                      }}
                    />

                    <div className={"flex flex-row justify-between gap-5"}>
                      <FormikAsyncSelect
                        label="Stripe monthly price"
                        name={`entitlement[${index}]`}
                        defaultOptions
                        selectedOption={() => {
                          if (
                            !entitlement.price ||
                            entitlement.interval !== "month"
                          ) {
                            return null;
                          }

                          return {
                            label: entitlement.price?.productName,
                            value: entitlement.price,
                          };
                        }}
                        maxNumberOfSelected={1}
                        loadOptions={listBillingPrices}
                        loadOptionsMappers={{
                          requestFilter: {
                            interval: "month",
                            usageType:
                              entitlement.usageType === "pay_as_you_go"
                                ? "metered"
                                : "licensed",
                          },
                          mapperFunction: (
                            price: BillingPriceDetailResponseData,
                          ) => ({
                            value: price,
                            label: <UsageBasedPriceOptionLabel prop={price} />,
                          }),
                        }}
                        onChange={async (options: Option) => {
                          entitlement.price = options.value;
                          entitlement.interval = "month";
                          existingUsageBasedEntitlements[index] = entitlement;
                          setExistingUsageBasedEntitlements([
                            ...existingUsageBasedEntitlements,
                          ]);
                        }}
                      />

                      <FormikAsyncSelect
                        label="Stripe yearly price"
                        name={`entitlement[${index}]`}
                        defaultOptions
                        selectedOption={() => {
                          if (
                            !entitlement.price ||
                            entitlement.interval !== "year"
                          ) {
                            return null;
                          }

                          return {
                            label: entitlement.price?.productName,
                            value: entitlement.price,
                          };
                        }}
                        maxNumberOfSelected={1}
                        loadOptions={listBillingPrices}
                        loadOptionsMappers={{
                          requestFilter: {
                            interval: "year",
                            usageType:
                              entitlement.usageType === "pay_as_you_go"
                                ? "metered"
                                : "licensed",
                          },
                          mapperFunction: (
                            price: BillingPriceDetailResponseData,
                          ) => ({
                            value: price,
                            label: <UsageBasedPriceOptionLabel prop={price} />,
                          }),
                        }}
                        onChange={async (options: Option) => {
                          entitlement.price = options.value;
                          entitlement.interval = "year";
                          existingUsageBasedEntitlements[index] = entitlement;
                          setExistingUsageBasedEntitlements([
                            ...existingUsageBasedEntitlements,
                          ]);
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>
            );
          })}
          <Button
            type={"button"}
            onClick={() => {
              console.log("Add new usage based entitlement...");
              addNewUsageBasedEntitlement();
            }}
            className="mt-4 w-1/2 flex items-center justify-center gap-2"
          >
            Add usage-based charge
          </Button>
        </div>
      </div>
    </div>
  );
};

export default UsageBasedEntitlementsBlock;
