import { NotFound } from "@components/blanks/pages/NotFound";
import { SinglePageLoader } from "@components/loaders/SinglePageLoader";
import { LabeledTooltip } from "@components/ui/LabeledTooltip";
import { CompanySidebar } from "@components/ui/Sidebar/CompanySidebar";
import { useContextQuery } from "@hooks/useContextQuery";
import { useNavigateEnvironment } from "@hooks/useNavigateEnvironment";
import { usePermission } from "@hooks/usePermission";
import { ClerkUserPermission } from "@models/clerkUser";
import {
  CompanyDeleteOverlay,
  CompanyEditOverlay,
} from "@modules/companies/components/overlays";
import * as api from "@modules/companies/queries";
import { useSchematicFlag } from "@schematichq/schematic-react";
import { BreadCrumbTypes } from "@ui/BreadCrumbs";
import { DataBlock } from "@ui/DataBlocks";
import { DropdownDots } from "@ui/DropdownDots";
import { Logo } from "@ui/Logo";
import { PageWrapper } from "@ui/PageWrapper";
import { PermissionButton } from "@ui/PermissionButton";
import { Pill } from "@ui/Pill";
import { PillGroup } from "@ui/PillGroup";
import { ProfileDetails } from "@ui/ProfileDetails";
import { ViewPageBody } from "@ui/ViewPageBody";
import { ViewPageHeader } from "@ui/ViewPageHeader";
import { timeAgo } from "@utils/date";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { SubscriptionPriceDisplay } from "./SubscriptionPriceDisplay";
import { CompanySubview } from "../types";

export const CompanyView = () => {
  const { id } = useParams() as {
    id: string;
  };
  const navigate = useNavigateEnvironment();

  const { data: company, isLoading } = useContextQuery({
    queryKey: [`company`, id],
    queryFn: () => api.getCompany(id),
    retry: false,
  });

  const [editOverlay, setEditOverlay] = useState(false);
  const [deleteOverlay, setDeleteOverlay] = useState(false);
  const companyEditAllowed = usePermission(ClerkUserPermission.companies_edit);
  const companyEditFlag = useSchematicFlag("company.edit", { fallback: true });

  // Overlays
  const onEdit = () => {
    setEditOverlay(true);
  };

  const onDelete = () => {
    setDeleteOverlay(true);
  };

  const onClose = () => {
    setEditOverlay(false);
    setDeleteOverlay(false);
  };

  if (isLoading) {
    return <SinglePageLoader />;
  } else if (!company) {
    return <NotFound />;
  }

  const crumbsData: BreadCrumbTypes[] = [
    {
      name: "companies",
      active: false,
      url: "../companies",
    },
    {
      name: `${company.name}`,
      active: true,
      url: `../companies/${id}`,
    },
  ];

  const tabsData = [
    {
      path: CompanySubview.Entitlements,
      label: "Entitlements",
    },
    {
      path: CompanySubview.Subscription,
      label: "Subscription",
    },
    {
      path: CompanySubview.Usage,
      label: "Usage",
    },
    // {
    //   path: CompanySubview.CRM,
    //   label: "CRM",
    // },
    {
      path: CompanySubview.Checks,
      label: "Checks",
    },
    {
      path: CompanySubview.Users,
      label: "Users",
    },
  ];

  const infoData = [
    ...(company.plan
      ? [
          {
            title: "Plan",
            label: (
              <Pill
                text="normal"
                onClick={(e) => {
                  e.stopPropagation();
                  company.plan && navigate(`plans/${company.plan.id}`);
                }}
              >
                {company.plan.name}
              </Pill>
            ),
          },
        ]
      : []),
    ...(company.addOns.length > 0
      ? [
          {
            title: "Add-ons",
            label: <PillGroup items={company.addOns} />,
          },
        ]
      : []),
    ...(company?.billingSubscription
      ? [
          {
            title: "Subscription",
            label: (
              <SubscriptionPriceDisplay
                subscription={company.billingSubscription}
              />
            ),
          },
        ]
      : []),
    {
      title: "Users",
      label: company.userCount,
    },
    ...(companyEditFlag
      ? [
          {
            children: (
              <div className="flex space-x-2">
                <PermissionButton
                  onClick={onEdit}
                  disabled={!companyEditAllowed}
                >
                  Edit company
                </PermissionButton>
                <DropdownDots
                  size="md"
                  links={[
                    {
                      label: "Delete company",
                      disabled: !companyEditAllowed,
                      onClick: onDelete,
                    },
                  ]}
                />
              </div>
            ),
          },
        ]
      : []),
  ];

  const companyLastSeen = company.lastSeenAt
    ? `Last seen ${timeAgo(company.lastSeenAt)}`
    : "Not seen yet";

  return (
    <PageWrapper>
      <div className="flex flex-row w-full">
        <div className="flex flex-col flex-1 h-[calc(100vh-92px)] overflow-hidden overflow-y-auto">
          <ViewPageHeader crumbsData={crumbsData} tabsData={tabsData}>
            <ProfileDetails
              image={<Logo src={company.logoUrl} alt={company.name} />}
              title={company.name}
              label={
                <div className="flex items-center space-x-1">
                  <div>{companyLastSeen}</div>
                  <LabeledTooltip
                    description="This is the date a track or identify event associated with this company was last received by Schematic."
                    className="!bg-transparent"
                    placement="top-right"
                    size="lg"
                  />
                </div>
              }
            />
            <DataBlock data={infoData} />
          </ViewPageHeader>
          <ViewPageBody data={{ company }} />
        </div>
        <CompanySidebar company={company} />
      </div>

      {editOverlay && (
        <CompanyEditOverlay onClose={onClose} company={company} />
      )}
      {deleteOverlay && (
        <CompanyDeleteOverlay onClose={onClose} company={company} />
      )}
    </PageWrapper>
  );
};
