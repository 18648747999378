import { ListWebhookEventsParams } from "@models/api";
import { WebhookEvent } from "@models/webhook";
import { pillColorWebhookEventStatus } from "@modules/settings/consts";
import { listWebhookEvents } from "@modules/settings/queries/webhooks";
import { CellContext, ColumnDef } from "@tanstack/react-table";
import { ClipCopy } from "@ui/ClipCopy";
import { InfiniteTable } from "@ui/InfiniteTable";
import { Pill } from "@ui/Pill";
import { TableHeader } from "@ui/TableHeader";
import { formatDate } from "@utils/date";
import { formatJson } from "@utils/strings";
import React, { useEffect, useMemo, useState } from "react";
import { WebhookEventsBlankState } from "../blanks/WebhookEventsBlankState";

type WebhookEventsTableProps = {
  webhookId?: string;
};

export const WebhookEventsTable = ({ webhookId }: WebhookEventsTableProps) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [filter, setFilter] = useState<ListWebhookEventsParams>({ webhookId });

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  useEffect(() => {
    setFilter((prevState) =>
      searchTerm === ""
        ? { ...prevState, q: undefined }
        : { ...prevState, q: searchTerm },
    );
  }, [searchTerm]);

  const columns = useMemo<ColumnDef<WebhookEvent>[]>(() => {
    return [
      {
        id: "createdAt",
        header: "Timestamp",
        accessorKey: "createdAt",
        size: 200,
        cell: (cellInfo) => {
          return (
            <div className="inline-block space-y-1">
              <div className="leading-none text-gray-400">
                {formatDate(cellInfo.row.original.createdAt)}
              </div>
            </div>
          );
        },
      },
      ...(!webhookId
        ? [
            {
              id: "webhook",
              header: "Webhook",
              accessorKey: "webhookId",
              cell: (cellInfo: CellContext<WebhookEvent, any>) => {
                const webhook = cellInfo.row.original.webhook;
                const webhookId = cellInfo.row.original.webhookId;
                return (
                  <div className="inline-block space-y-1">
                    <div className="leading-none text-gray-400">
                      {webhook ? webhook.name : webhookId}
                    </div>
                  </div>
                );
              },
            },
          ]
        : []),
      {
        id: "trigger",
        header: "Trigger",
        accessorKey: "requestType",
        cell: (cellInfo) => {
          const requestType = cellInfo.row.original.requestType;
          return <Pill color="gray">{requestType}</Pill>;
        },
      },
      {
        id: "status",
        header: "Status",
        accessorKey: "status",
        size: 200,
        cell: (cellInfo) => {
          const status = cellInfo.row.original.status;
          return (
            <Pill color={pillColorWebhookEventStatus(status)}>{status}</Pill>
          );
        },
      },
      {
        id: "payload",
        header: "Payload",
        accessorKey: "payload",
        cell: (cellInfo) => {
          const payload = cellInfo.row.original.payload;

          return (
            <div className="inline-block max-w-full overflow-hidden">
              <div className="leading-none text-gray-400 overflow-hidden max-w-[300px] text-ellipsis">
                <ClipCopy data={formatJson(payload || "{}")} truncate />
              </div>
            </div>
          );
        },
      },
    ];
  }, [webhookId]);

  return (
    <div className="w-full">
      <TableHeader
        headerText="Log"
        className="mt-8"
        searchPlaceholder="Search events"
        onSearch={handleSearch}
      />

      <InfiniteTable
        columns={columns}
        queryKey={["webhook-events", webhookId || "all"]}
        filter={filter}
        queryFn={listWebhookEvents}
        blankStateComponent={<WebhookEventsBlankState />}
      />
    </div>
  );
};
