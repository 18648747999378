import { SinglePageLoader } from "@components/loaders/SinglePageLoader";
import { listIntegrations } from "@data/integrations";
import { useContextQuery } from "@hooks/useContextQuery";
import { useLocalStorage } from "@hooks/useLocalStorage";
import { useNavigateEnvironment } from "@hooks/useNavigateEnvironment";
import { IntegrationsPage } from "@modules/integrations/consts";
import {
  QuickstartCard,
  QuickStartCardData,
} from "@modules/quickstart/components/QuickstartCard";
import { QuickstartPage } from "@modules/quickstart/consts";
import { useEffect } from "react";
import { Navigate } from "react-router-dom";

export const QuickstartHowDoYouBill = () => {
  const navigate = useNavigateEnvironment();
  const [howDoYouBillViewed, setHowDoYouBillViewed] = useLocalStorage(
    "quickstart.howDoYouBillViewed",
    false,
  );
  const { data, isLoading } = useContextQuery({
    queryKey: ["integrations", "stripe"],
    queryFn: async () => {
      try {
        return await listIntegrations({ type: "stripe" });
      } catch (error: any) {
        // TODO: Fix integrations returning 500
        if (error.responseCode <= 500) {
          return [];
        }

        throw error;
      }
    },
    retry: (failureCount, error: any) => {
      // TODO: Fix integrations returning 500
      if (error.responseCode <= 500) {
        return false;
      }

      return failureCount < 3;
    },
  });

  // If Stripe is already linked, skip this page
  useEffect(() => {
    if (data?.length) {
      setHowDoYouBillViewed(true);
    }
  }, [data, setHowDoYouBillViewed]);

  if (isLoading) {
    return <SinglePageLoader />;
  }

  if (howDoYouBillViewed) {
    return <Navigate to={`../${QuickstartPage.GetStarted}`} replace />;
  }

  const cardsInfo: QuickStartCardData[] = [
    {
      title: "I use Stripe for billing",
      bodyText: "Import customers and products to start implementation.",
      imageUrl: "/connect-stripe.png",
      altText: "Connect Stripe to Schematic",
      buttonText: "Connect Stripe",
      onClick: async () => {
        await (async () => {
          setHowDoYouBillViewed(true);
        })();
        navigate(`integrations/${IntegrationsPage.StripeIntegration}`);
      },
    },
    {
      title: "I don't use Stripe for billing",
      bodyText: "Set up Schematic to work with your system.",
      imageUrl: "/quickstart/plan-table.png",
      altText: "Plan table",
      buttonText: "I don't use Stripe",
      onClick: async () => {
        await (async () => {
          setHowDoYouBillViewed(true);
        })();
        navigate(`quickstart/${QuickstartPage.GetStarted}`);
      },
    },
  ];

  return (
    <div className="space-y-16 relative">
      <div className="absolute w-full h-full">
        <img
          className="opacity-10 w-[1000px] ml-[30%] mt-[-27%]"
          src="/quickstart/schematic-background.png"
          alt=""
        />
      </div>
      <div>
        <div className="flex flex-col items-center space-y-2 mt-8">
          <h1 className="text-3xl font-medium font-display">
            How do you bill your customers?
          </h1>
          <h5 className="text-base font-body">
            Schematic adapts to your stack.
          </h5>
        </div>
      </div>
      <div className="relative flex space-x-4 justify-center">
        {cardsInfo.map((card) => (
          <QuickstartCard key={card.title} data={card} />
        ))}
      </div>
    </div>
  );
};
