import { NotFound } from "@components/blanks/pages/NotFound";
import { SinglePageLoader } from "@components/loaders/SinglePageLoader";
import { SettingsBox } from "@components/ui/SettingsBox";
import { useContextQuery } from "@hooks/useContextQuery";
import { SchematicEmbed } from "@schematichq/schematic-components";
import { createTemporaryToken } from "../../queries/tokens";

export const PlanPage = () => {
  const tokenQuery = useContextQuery({
    queryFn: createTemporaryToken,
    // Try to avoid generating redundant tokens within the same minute
    queryKey: ["createTemporaryToken", new Date().toISOString().slice(0, 16)],
  });

  const componentId = import.meta.env.VITE_SCHEMATIC_COMPONENT_ID;
  if (!componentId) {
    return <NotFound />;
  }

  if (tokenQuery.isPending || !tokenQuery.data) {
    return <SinglePageLoader />;
  }

  const { token } = tokenQuery.data;

  return (
    <SettingsBox>
      <SchematicEmbed accessToken={token} id={componentId} />
    </SettingsBox>
  );
};
