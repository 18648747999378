import { NotFound } from "@components/blanks/pages/NotFound";
import { SinglePageLoader } from "@components/loaders/SinglePageLoader";
import { ClipCopy } from "@components/ui/ClipCopy";
import { DropdownDots } from "@components/ui/DropdownDots";
import * as api from "@data/flags";
import { useContextQuery } from "@hooks/useContextQuery";
import { useNavigateEnvironment } from "@hooks/useNavigateEnvironment";
import { usePermission } from "@hooks/usePermission";
import { ClerkUserPermission } from "@models/clerkUser";
import { Maintainer } from "@models/maintainer";
import { BreadCrumbTypes } from "@ui/BreadCrumbs";
import { DataBlock, DataBlockTypes } from "@ui/DataBlocks";
import { PageWrapper } from "@ui/PageWrapper";
import { PermissionButton } from "@ui/PermissionButton";
import { Pill } from "@ui/Pill";
import { ProfileDetails } from "@ui/ProfileDetails";
import { User } from "@ui/User";
import { ViewPageBody } from "@ui/ViewPageBody";
import { ViewPageHeader } from "@ui/ViewPageHeader";
import { timeAgo } from "@utils/date";
import { useState } from "react";
import { useParams } from "react-router-dom";

import { FlagDeleteOverlay } from "./overlays/FlagDeleteOverlay";
import { FeatureFlagOverlay } from "./overlays/FlagOverlay";
import { FlagSubview } from "../types";

export const FeatureFlagView = () => {
  const { id } = useParams() as {
    id: string;
  };
  const navigate = useNavigateEnvironment();
  const [editOverlay, setEditOverlay] = useState(false);
  const [deleteOverlay, setDeleteOverlay] = useState(false);

  const { data: flag, isLoading } = useContextQuery({
    queryKey: [`flag`, id],
    queryFn: () => api.getFeatureFlag(id),
    retry: false,
  });
  const flagEditAllowed = usePermission(ClerkUserPermission.flags_edit);

  // Overlays
  const onEdit = () => {
    setEditOverlay(true);
  };

  const onDelete = () => {
    setDeleteOverlay(true);
  };

  const onClose = () => {
    setEditOverlay(false);
    setDeleteOverlay(false);
  };

  const maintainerDisplayName = (maintainer: Maintainer) => {
    let displayName = maintainer.id;
    if (maintainer.firstName && maintainer.lastName) {
      displayName = `${maintainer.firstName} ${maintainer.lastName}`;
    } else if (maintainer.emailAddresses.length > 0) {
      displayName = maintainer.emailAddresses[0].emailAddress;
    }

    return displayName;
  };

  const infoData = [
    {
      children: (
        <div className="flex space-x-2">
          <PermissionButton onClick={onEdit} disabled={!flagEditAllowed}>
            Edit flag
          </PermissionButton>
          <DropdownDots
            size="md"
            links={[
              {
                label: "Delete flag",
                disabled: !flagEditAllowed,
                onClick: onDelete,
              },
            ]}
          />
        </div>
      ),
    },
    flag?.maintainer && {
      title: "Maintainer",
      label: (
        <User
          image={flag.maintainer.profileImageUrl as string}
          name={maintainerDisplayName(flag.maintainer)}
        />
      ),
    },
  ].filter(Boolean) as DataBlockTypes[];

  if (isLoading) {
    return <SinglePageLoader />;
  } else if (!flag) {
    return <NotFound />;
  }

  const crumbData: BreadCrumbTypes[] = [
    {
      name: "flags",
      active: false,
      url: "../flags",
    },
    {
      name: flag.name,
      active: true,
      url: `../flags/${id}`,
    },
  ];

  const tabsData = [
    {
      path: FlagSubview.Targeting,
      label: "Targeting",
    },
    {
      path: FlagSubview.Checks,
      label: "Checks",
    },
  ];

  const flagLastModified = flag.updatedAt
    ? `Last edited ${timeAgo(flag.updatedAt)}`
    : "-";

  const flagProfileTitle = (
    <>
      {flag.name}
      {flag && (
        <Pill color="gray" type="tag" text="code" className="ml-2 lowercase">
          <ClipCopy data={flag.key} size="sm" />
        </Pill>
      )}
    </>
  );

  return (
    <PageWrapper>
      <div className="flex flex-col flex-1  h-[calc(100vh-92px)] overflow-hidden overflow-y-auto">
        <ViewPageHeader
          crumbsData={crumbData}
          tabsData={tabsData}
          description={flag.description}
        >
          <ProfileDetails title={flagProfileTitle} label={flagLastModified} />
          <DataBlock data={infoData} />
        </ViewPageHeader>
        <ViewPageBody data={{ flag, showFeature: true }} />
      </div>

      {editOverlay && <FeatureFlagOverlay onClose={onClose} flag={flag} />}
      {deleteOverlay && (
        <FlagDeleteOverlay
          onClose={onClose}
          onDelete={() => {
            navigate("flags");
          }}
          flag={flag}
        />
      )}
    </PageWrapper>
  );
};
