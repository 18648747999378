import { Outlet } from "react-router-dom";

type ViewPageBodyProps = {
  data: any;
};

export const ViewPageBody = ({ data }: ViewPageBodyProps) => {
  return (
    <div className="bg-white py-8 lg:py-12 flex-1">
      <div className="tw-wrapper px-8">
        <Outlet context={data} />
      </div>
    </div>
  );
};
