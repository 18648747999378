import {
  CreateOrUpdateConditionRequestBodyConditionTypeEnum,
  RuleConditionDetailResponseData,
} from "@models/api";
import { EntityType } from "@models/entityTrait";
import { newCondition } from "@ui/RuleBlock/utils";
import { ArrayHelpers, FieldArray } from "formik";
import * as Yup from "yup";
import {
  AND_CONDITION_MAX_LENGTH,
  ConditionLogicalOperator,
  OR_CONDITION_MAX_LENGTH,
} from "./consts";
import { RuleConditionRow } from "./RuleConditionRow";
import { RuleConditionRowValidationSchema } from "./RuleConditionRow";

export const RuleConditionGroupValidationSchema = Yup.object().shape({
  conditions: Yup.array().of(RuleConditionRowValidationSchema),
});

interface RuleConditionGroupProps {
  conditions: RuleConditionDetailResponseData[];
  logicalOperator: ConditionLogicalOperator;
  field: string;
  readonly?: boolean;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  setFieldValue?: (field: string, value: any) => void;
  onAddAndCondition: () => void;
  onRemove: (condition?: RuleConditionDetailResponseData) => void;
  ruleConditionGroupLength: number;
  permittedConditionTypes?: CreateOrUpdateConditionRequestBodyConditionTypeEnum[];
  permittedTraitEntityType?: EntityType;
}

export const RuleConditionGroupBlock = ({
  conditions,
  logicalOperator,
  readonly,
  setFieldValue,
  field,
  onAddAndCondition,
  onRemove,
  ruleConditionGroupLength,
  permittedConditionTypes,
  permittedTraitEntityType,
}: RuleConditionGroupProps) => {
  const onAddCondition = (arrayHelpers: ArrayHelpers, index: number) => () => {
    arrayHelpers.insert(index + 1, newCondition());
  };

  const onRemoveCondition =
    (arrayHelpers: ArrayHelpers, index: number) => () => {
      arrayHelpers.remove(index);
    };

  return (
    <FieldArray
      name={`${field}.conditions`}
      render={(arrayHelpers: ArrayHelpers) => (
        <div className="space-y-5">
          <RuleConditionRow
            condition={conditions[0]}
            key={conditions[0].id || conditions[0].createdAt.toString()}
            readonly={readonly}
            conditionsLength={conditions.length}
            logicalOperator={logicalOperator}
            field={`${field}.conditions.${0}`}
            setFieldValue={setFieldValue}
            onAddAndCondition={onAddAndCondition}
            onAddOrCondition={onAddCondition(arrayHelpers, 0)}
            onRemove={onRemove}
            permittedConditionTypes={permittedConditionTypes}
            permittedTraitEntityType={permittedTraitEntityType}
            disableOr={conditions.length >= OR_CONDITION_MAX_LENGTH}
            disableAnd={ruleConditionGroupLength >= AND_CONDITION_MAX_LENGTH}
            showRemoveButton={
              ruleConditionGroupLength > 1 || conditions.length > 1
            }
          />
          <div className="space-y-5 ml-20">
            {conditions.map((condition, index) => {
              if (index === 0) {
                return;
              }

              return (
                <RuleConditionRow
                  condition={condition}
                  key={condition.id || condition.createdAt.toString()}
                  readonly={readonly}
                  conditionsLength={conditions.length}
                  logicalOperator={ConditionLogicalOperator.OR}
                  field={`${field}.conditions.${index}`}
                  setFieldValue={setFieldValue}
                  onAddAndCondition={onAddCondition(arrayHelpers, index)}
                  onRemove={onRemoveCondition(arrayHelpers, index)}
                  permittedConditionTypes={permittedConditionTypes}
                  permittedTraitEntityType={permittedTraitEntityType}
                />
              );
            })}
          </div>
        </div>
      )}
    />
  );
};
