import { TableLoader } from "@components/loaders/TableLoader";
import * as subscriptionsApi from "@data/subscriptions";
import { Product, Subscription } from "@models/subscription";
import { DiscountBlock } from "@modules/companies/components/tabs/blocks/DiscountBlock";
import { useQuery } from "@tanstack/react-query";
import { ColumnDef } from "@tanstack/react-table";
import { Alert } from "@ui/Alert";
import { Button } from "@ui/Button";
import { ClipCopy } from "@ui/ClipCopy";
import { LabelData, LabelDataRow } from "@ui/LabelData";
import { Pill } from "@ui/Pill";
import { Table } from "@ui/Table";
import { TableHeader } from "@ui/TableHeader";
import { formatReadableDate, isDateBeforeCurrent } from "@utils/date";
import { useMemo } from "react";
import { useParams } from "react-router-dom";

interface StripeProduct {
  name: string;
  quantity: string;
  total: string;
}

export const CompanySubscriptionTab = () => {
  const { id } = useParams() as {
    id: string;
  };

  const { data: subscriptionsData, isPending: subscriptionLoading } = useQuery({
    queryKey: ["getSubscriptionQuery", id],
    queryFn: async () => {
      const subscriptions = await subscriptionsApi.getCompanySubscription(id);
      return subscriptions.sort(
        (sub1, sub2) =>
          new Date(sub2.expiredAt).getDate() -
          new Date(sub1.expiredAt).getDate(),
      );
    },
  });

  const columns = useMemo<ColumnDef<StripeProduct>[]>(
    () => [
      {
        id: "product",
        header: "Product",
        accessorKey: "product",
        size: 500,
        cell: (cellInfo) => {
          const product = cellInfo.row.original;
          return (
            <div className="flex flex-row items-center">
              <div className="flex flex-col space-y-1">
                <div className="leading-none font-medium">{product.name}</div>
              </div>
            </div>
          );
        },
      },
      {
        id: "quantity",
        header: "QTY",
        accessorKey: "quantity",
        cell: (cellInfo) => {
          const product = cellInfo.row.original;
          return (
            <div className="flex flex-row items-center">
              <div className="flex flex-col space-y-1">
                <div className="leading-none text-gray-400 text-sm">
                  {product.quantity}
                </div>
              </div>
            </div>
          );
        },
      },
      {
        id: "total",
        header: "total",
        accessorKey: "total",
        cell: (cellInfo) => {
          const product = cellInfo.row.original;
          return (
            <div className="flex flex-row items-center">
              <div className="flex flex-col space-y-1">
                <div className="leading-none text-gray-400 text-sm">
                  {product.total}
                </div>
              </div>
            </div>
          );
        },
      },
    ],
    [],
  );

  if (subscriptionLoading && !subscriptionsData) {
    return <TableLoader />;
  }

  if (!subscriptionLoading && subscriptionsData!.length === 0) {
    return (
      <Alert
        size="lg"
        style="gray"
        className="text-center mt-6"
        background="schematic"
      >
        <div className="flex flex-row justify-between w-full flex-1 relative z-10 text-gray">
          <div className="flex flex-col text-left items-start space-y-2">
            <div className="text-[25px] font-medium font-body">
              No subscription associated with this Company
            </div>
            <div className="text-lg leading-6 text-gray-600">
              There are 2 possible reasons for this:
            </div>

            <ul className="list-disc pl-5 text-gray-600">
              <li>
                Make sure the customer Stripe ID is being passed via the{" "}
                <a
                  href="https://docs.schematichq.com/api-reference/companies/upsert-company"
                  target="_blank"
                  className="text-blue-400 hover:underline"
                >
                  API company upsert
                </a>
                .
              </li>
              <li>
                This company doesn’t have an active subscription in Stripe.
              </li>
            </ul>
          </div>
          <div className="flex items-center">
            <Button
              color="white"
              onClick={() =>
                window.open(
                  "https://docs.schematichq.com/integrations/stripe",
                  "_blank",
                )
              }
            >
              Learn more
            </Button>
          </div>
        </div>
      </Alert>
    );
  }

  const getSubscriptions = () => {
    return subscriptionsData!.map((subscription) => {
      return (
        <SubscriptionBox
          key={subscription!.subscriptionExternalId}
          subscription={subscription!}
          columns={columns}
        />
      );
    });
  };

  const getHeaderText = () => {
    if (subscriptionsData!.length > 1) {
      return `${subscriptionsData!.length} Subscriptions`;
    }

    return "1 Subscription";
  };

  return (
    <>
      {!!subscriptionsData &&
        subscriptionsData!.map((subscription) => (
          <DiscountBlock
            key={subscription.subscriptionExternalId}
            discounts={subscription.discounts}
          />
        ))}
      <div className="space-y-8 pb-16">
        <TableHeader headerText={getHeaderText()} className="!pb-0" />

        {getSubscriptions()}
      </div>
    </>
  );
};

interface SubscriptionBoxProps {
  columns: any[];
  subscription: Subscription;
}

export const SubscriptionBox = ({
  columns,
  subscription,
}: SubscriptionBoxProps) => {
  const currencyFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: subscription.products[0]?.currency?.toUpperCase() || "USD",
  });

  const products: StripeProduct[] = useMemo<StripeProduct[]>(() => {
    return subscription.products.map<StripeProduct>((product: Product) => {
      if (product.usageType === "metered") {
        const total = `${currencyFormatter.format(
          product.price / 100,
        )} / per unit`;
        return {
          name: product.name,
          quantity: "Varies",
          total,
        };
      }

      return {
        name: product.name,
        quantity: product.quantity?.toString(),
        total: currencyFormatter.format(product.price / 100),
      };
    });
  }, [subscription]);

  const totalSubscriptionPrice = useMemo(() => {
    const productWithMeteredUsage = subscription.products.find(
      (product) => product.usageType === "metered",
    );
    if (productWithMeteredUsage) {
      return "varies";
    }
    if (subscription.totalPrice) {
      return subscription.totalPrice / 100;
    }

    return subscription.products.reduce(
      (acc, curr: Product) => acc + curr.price / 100,
      0,
    );
  }, [subscription]);

  const getTotalPriceFormatted = () => {
    if (totalSubscriptionPrice === "varies") {
      return "Varies";
    }

    return `${currencyFormatter.format(totalSubscriptionPrice)}/${
      subscription.interval
    }`;
  };

  const SubscriptionStateBadge = () => {
    if (isDateBeforeCurrent(new Date(subscription.expiredAt))) {
      return (
        <Pill color="red" type="rounded" text="normal">
          Expired
        </Pill>
      );
    }

    if (subscription.status === "trialing") {
      return (
        <Pill color="blue" type="rounded" text="normal">
          Trialing
        </Pill>
      );
    }

    if (subscription.status === "past_due") {
      return (
        <Pill color="red" type="rounded" text="normal">
          Past Due
        </Pill>
      );
    }

    return (
      <Pill color="green" type="rounded" text="normal">
        Active
      </Pill>
    );
  };

  return (
    <div className="bg-white shadow-lg border border-gray-400/15 rounded-lg">
      <div className="px-8 py-8">
        <LabelDataRow className="items-center !justify-between !space-x-24">
          <LabelData label="Subscription ID">
            <ClipCopy data={subscription.subscriptionExternalId} />{" "}
          </LabelData>
          <LabelData>
            <SubscriptionStateBadge />
          </LabelData>
          <LabelData label="Price">{getTotalPriceFormatted()}</LabelData>
          <LabelData label="Period">
            {formatReadableDate(new Date(subscription.expiredAt))}
          </LabelData>

          <div className="flex flex-1 items-center justify-end">
            <div className="text-blue-400 font-medium hover:underline hover:cursor-pointer">
              <a
                href={
                  "https://dashboard.stripe.com/test/subscriptions/" +
                  subscription.subscriptionExternalId
                }
                target="_blank"
              >
                See in Stripe ↗️
              </a>
            </div>
          </div>
        </LabelDataRow>
      </div>
      <div className="bg-gray-200 px-8 py-8">
        <Table style="compact" columns={columns} data={products} />

        <div className="border-t border-gray-400/15 flex items-start justify-end pr-4 pt-6">
          <span className="w-[140px]">Total</span>
          <div className="flex flex-col text-sm text-gray-400">
            <span>{getTotalPriceFormatted()}</span>
            {/* <span>+ $0.30 per</span> */}
            {/* <span>AI Query</span> */}
          </div>
        </div>
      </div>
    </div>
  );
};
