import { EnvironmentContext } from "@contexts/EnvironmentContext";
import {
  useQuery,
  useQueryClient,
  UseQueryOptions,
  SetDataOptions,
  Updater,
  useInfiniteQuery,
  keepPreviousData,
} from "@tanstack/react-query";
import type { UseInfiniteQueryOptions } from "@tanstack/react-query/src/types";
import { useContext } from "react";

export const useContextQuery = <T>({
  queryKey,
  queryFn,
  ...options
}: UseQueryOptions<T>) => {
  const { environmentId } = useContext(EnvironmentContext);

  return useQuery<T>({
    queryKey: ["env", environmentId, ...queryKey],
    queryFn,
    ...options,
  });
};

export const useContextSetQueryData = <T>() => {
  const queryClient = useQueryClient();
  const { environmentId } = useContext(EnvironmentContext);

  return (
    queryKey: string[],
    updater: Updater<T | undefined, T | undefined>,
    options?: SetDataOptions,
  ) => {
    if (environmentId) {
      queryKey.unshift("env", environmentId);
    }

    return queryClient.setQueryData<T>(queryKey, updater, options);
  };
};

export const useInfiniteContextQuery = <T>({
  queryKey,
  queryFn,
  ...options
}: Pick<UseInfiniteQueryOptions<T[]>, "queryKey" | "queryFn">) => {
  const { environmentId } = useContext(EnvironmentContext);

  return useInfiniteQuery({
    queryKey: ["env", environmentId, ...queryKey],
    queryFn,
    initialPageParam: 0,
    getNextPageParam: (lastPage, allPages, lastPageParam) => {
      if ((lastPage?.length ?? 0) === 0) {
        return null;
      }

      return lastPageParam + 10;
    },
    refetchOnWindowFocus: false,
    placeholderData: keepPreviousData,
    ...options,
  });
};
