import { randomIcon } from "@components/icons";
import {
  CreateOrUpdateRuleRequestBodyRuleTypeEnum,
  RuleDetailResponseData,
} from "@models/api";
import {
  Feature,
  FeatureFlag,
  FeatureIcon,
  FeatureType,
} from "@models/feature";
import { RuleDetailResponseDataWithoutConditions } from "@modules/features/components/tabs/FeatureFlagTargetingTab/FeatureFlagTargetingTab";
import { compareRuleArrays } from "@ui/PageNotification/PageNotification";
import { newCondition, newConditionGroup } from "@ui/RuleBlock/utils";
import { ReactNode } from "react";
import { v4 as uuidv4 } from "uuid";

export type FeatureFlagWithRuleGroups = FeatureFlag & {
  companyOverrideRules: RuleDetailResponseDataWithoutConditions[];
  planEntitlementRules: RuleDetailResponseDataWithoutConditions[];
  standardRules: RuleDetailResponseDataWithoutConditions[];
  defaultRule: RuleDetailResponseData;
};

// Initial values for Feature form when converting a flag to a feature.
export function featureConversion(flag: FeatureFlag): Feature {
  return {
    id: "",
    createdAt: new Date(),
    description: flag.description,
    featureType: FeatureType.Boolean,
    flags: [flag],
    icon: randomIcon() as FeatureIcon,
    name: flag.name,
    plans: [],
    updatedAt: new Date(),
  };
}

export function pageNotificationChanges(
  initial: FeatureFlagWithRuleGroups,
  current: FeatureFlagWithRuleGroups,
): ReactNode[] {
  const changes: ReactNode[] = [];

  if (initial.defaultRule.value !== current.defaultRule.value) {
    changes.push(
      <div>
        Default value changed from{" "}
        <span className="font-semibold">
          {initial.defaultRule.value ? "true" : "false"}
        </span>{" "}
        to{" "}
        <span className="font-semibold">
          {current.defaultRule.value ? "true" : "false"}
        </span>
        .
      </div>,
    );
  }

  return [
    ...changes,
    ...compareRuleArrays(initial.standardRules, current.standardRules),
  ];
}

export function newRule(
  values: FeatureFlagWithRuleGroups | FeatureFlag,
): RuleDetailResponseDataWithoutConditions & { formikId: string } {
  const standardRules =
    "standardRules" in values
      ? values.standardRules
      : values.rules.filter(
          (r) =>
            r.ruleType === CreateOrUpdateRuleRequestBodyRuleTypeEnum.Standard,
        );

  return {
    createdAt: new Date(),
    environmentId: "",
    flagId: values.id,
    id: "",
    formikId: uuidv4(),
    name: `Rule ${standardRules.length + 1}`,
    priority: values.rules.length + 1,
    ruleType: CreateOrUpdateRuleRequestBodyRuleTypeEnum.Standard,
    updatedAt: new Date(),
    value: true,
    conditionGroups: [newConditionGroup(newCondition())],
  };
}
