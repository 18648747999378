import { useEditor } from "@craftjs/core";
import { type EmbedSettings } from "@schematichq/schematic-components";
import { type EmbedContextProps } from "@schematichq/schematic-components";
import ReactSelect, {
  components,
  type GroupBase,
  type MultiValueGenericProps,
  type MultiValueProps,
  type Props,
  type StylesConfig,
} from "react-select";
import AsyncReactSelect, { type AsyncProps } from "react-select/async";
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from "react-sortable-hoc";

const createStyles = <
  Option,
  IsMulti extends boolean,
  Group extends GroupBase<Option>,
>(
  settings: EmbedSettings,
): StylesConfig<Option, IsMulti, Group> => ({
  control: (styles) => ({
    ...styles,
    height: "100%",
    minHeight: "auto",
    borderColor: "#E2E5E9",
  }),
  valueContainer: (styles, props) => ({
    ...styles,
    padding: props.isMulti ? "0.5rem" : "0 0.25rem",
  }),
  singleValue: (styles, props) => {
    // @ts-expect-error: unknown option value
    if (!props.rawValue) {
      return styles;
    }

    const { fontFamily, fontWeight } =
      // @ts-expect-error: unknown option value
      settings.theme.typography[rawValue] || {};

    return {
      ...styles,
      fontFamily,
      fontWeight,
    };
  },
  multiValue: (styles) => ({
    ...styles,
    zIndex: 999999,
    padding: "0.125rem 0.5rem",
    borderRadius: "9999px",
  }),
  input: (styles) => ({
    ...styles,
    padding: 0,
  }),
  indicatorSeparator: (styles) => ({
    ...styles,
    display: "none",
  }),
  dropdownIndicator: (styles) => ({
    ...styles,
    padding: "0 0.25rem",
    color: "#667085",
  }),
  option: (styles, props) => {
    const { fontFamily, fontWeight } =
      // @ts-expect-error: unknown option value
      settings.theme.typography[props.data.value] || {};

    return {
      ...styles,
      fontFamily,
      fontWeight,
      padding: "0.25rem 0.75rem",
    };
  },
});

interface SettingsSelectProps<
  Option,
  IsMulti extends boolean,
  Group extends GroupBase<Option>,
> extends Props<Option, IsMulti, Group> {
  rawValue?: unknown;
}

export const Select = <
  Option,
  IsMulti extends boolean,
  Group extends GroupBase<Option>,
>({
  options = [],
  value,
  rawValue,
  onChange,
  ...rest
}: SettingsSelectProps<Option, IsMulti, Group>) => {
  const { settings } = useEditor((state) => {
    return {
      settings: state.nodes.ROOT.data.props
        .settings as EmbedContextProps["settings"],
    };
  });

  return (
    <ReactSelect
      options={options}
      value={value}
      onChange={onChange}
      styles={createStyles<Option, IsMulti, Group>(settings)}
      {...rest}
    />
  );
};

interface SettingsAsyncProps<
  Option,
  IsMulti extends boolean,
  Group extends GroupBase<Option>,
> extends AsyncProps<Option, IsMulti, Group> {
  rawValue?: unknown;
}

export const AsyncSelect = <
  Option,
  IsMulti extends boolean,
  Group extends GroupBase<Option>,
>({
  options = [],
  value,
  rawValue,
  onChange,
  ...rest
}: SettingsAsyncProps<Option, IsMulti, Group>) => {
  const { settings } = useEditor((state) => {
    return {
      settings: state.nodes.ROOT.data.props
        .settings as EmbedContextProps["settings"],
    };
  });

  return (
    <AsyncReactSelect
      options={options}
      value={value}
      onChange={onChange}
      styles={createStyles<Option, IsMulti, Group>(settings)}
      {...rest}
    />
  );
};

export interface SelectOption {
  value: string;
  label: string;
}

export const SortableMultiSelect =
  SortableContainer<Props<SelectOption, true, GroupBase<SelectOption>>>(Select);

export const SortableMultiValue = SortableElement<
  MultiValueProps<SelectOption, true, GroupBase<SelectOption>>
>((props: MultiValueProps<SelectOption>) => {
  const onMouseDown: React.MouseEventHandler<HTMLDivElement> = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };
  const innerProps = { ...props.innerProps, onMouseDown };
  return <components.MultiValue {...props} innerProps={innerProps} />;
});

export const SortableMultiValueLabel = SortableHandle<
  MultiValueGenericProps<SelectOption, true, GroupBase<SelectOption>>
>((props: MultiValueGenericProps) => <components.MultiValueLabel {...props} />);
