import { PermissionButton } from "@ui/PermissionButton";
import cx from "classnames";
import { ReactNode } from "react";
import { ButtonProps } from "../Button";
import { ButtonGroup } from "../ButtonGroup";

interface BlankStateProps {
  className?: string;
  children?: ReactNode;
  title: string;
  description?: string | ReactNode;
  buttons?: ButtonProps[];
  type?: "static" | "fixed";
}

export const BlankState = ({
  className,
  children,
  title,
  buttons,
  description,
  type = "fixed",
}: BlankStateProps) => {
  const typeStyles = {
    static: "",
    fixed:
      "fixed top-0 left-0 w-full h-full mt-[81px] flex flex-col items-center justify-start z-[51]",
  };
  const paddingStyles = {
    static: "pt-12",
    fixed: "pt-28",
  };
  const styles = cx(typeStyles[type], className);

  return (
    <div className={styles}>
      <div className="absolute left-0 top-0 z-[-1] h-full w-full bg-white/95 blur-lg"></div>
      <div className={paddingStyles[type]}>
        <div className="flex flex-col w-full text-center space-y-4">
          <p className="text-4xl">{title}</p>
          {description && <p className="text-lg">{description}</p>}
          {buttons && (
            <ButtonGroup
              buttons={buttons}
              ButtonComponent={PermissionButton}
              className="pt-8"
            />
          )}
        </div>
        {children}
      </div>
    </div>
  );
};
