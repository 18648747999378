import { useEditor, useNode, type UserComponent } from "@craftjs/core";
import {
  useEmbed,
  PricingTable as EmbedPricingTable,
  type EmbedContextProps,
  type PricingTableProps,
  type ButtonSizeTypes,
} from "@schematichq/schematic-components";
import { Button } from "@ui/Button";
import { titlecase } from "@utils/strings";
import { useMemo } from "react";
import * as Settings from "../../controls/Sidebar/Settings";
import { Checkbox, Select } from "../../ui";

type FontStyle = keyof EmbedContextProps["settings"]["theme"]["typography"];
type ThemeSettings = EmbedContextProps["settings"]["theme"];

export const PricingTable: UserComponent = () => {
  const { rootElement } = useEditor((state) => {
    return {
      rootElement: state.nodes.ROOT.dom,
    };
  });

  const {
    connectors: { connect, drag },
    props,
  } = useNode((node) => ({
    props: node.data.props as PricingTableProps,
  }));

  return (
    <EmbedPricingTable
      ref={(ref) => connect(drag(ref!))}
      className="relative z-[2] cursor-pointer"
      portal={rootElement}
      {...props}
    />
  );
};

const PricingTableSettings = () => {
  const { theme } = useEditor((state) => {
    return {
      theme: state.nodes.ROOT.data.props.settings.theme as ThemeSettings,
    };
  });

  const {
    actions: { setProp },
    nodeProps,
  } = useNode((node) => ({
    nodeProps: node.data.props as PricingTableProps,
  }));

  const { setLayout } = useEmbed();

  const typographyOptions: { value: FontStyle; label: string }[] = useMemo(
    () => [
      ...Object.keys(theme.typography).map((key) => ({
        value: key as FontStyle,
        label: titlecase(key),
      })),
    ],
    [theme.typography],
  );

  const buttonSizeOptions: { value: ButtonSizeTypes; label: string }[] = [
    { value: "sm", label: "Small" },
    { value: "md", label: "Medium" },
    { value: "lg", label: "Large" },
  ];

  const buttonStyleOptions: {
    value: "primary" | "secondary";
    label: string;
  }[] = [
    { value: "primary", label: "Primary" },
    { value: "secondary", label: "Secondary" },
  ];

  return (
    <Settings.Root title="💸 Pricing Table" category="Entitlements">
      <Settings.Section>
        <Settings.Header>Related Views</Settings.Header>
        <Settings.Input
          label={
            <>
              <div>Checkout</div>
              <div className="text-[0.8125rem] text-[#939393]">
                Triggered Change plan button
              </div>
            </>
          }
        >
          <Button
            className="py-1 px-3 !text-[#194BFB] rounded-[5px] hover:!text-white !border-[#E2E5E9]"
            color="white"
            size="xs"
            onClick={() => {
              setLayout("checkout");
            }}
          >
            View
          </Button>
        </Settings.Input>
      </Settings.Section>

      <Settings.Section>
        <Settings.Header
          isVisible={nodeProps.header.isVisible}
          onVibibilityChange={() => {
            setProp((props: PricingTableProps) => {
              const updated = !props.header.isVisible;
              props.header.isVisible = updated;
            });
          }}
        >
          Pricing table headers
        </Settings.Header>

        <Settings.Input label="Header style">
          <Select
            className="shrink-0 w-36"
            isMulti={false}
            options={typographyOptions}
            rawValue={nodeProps.header.fontStyle as string}
            value={typographyOptions.find(
              (opt) => opt.value === nodeProps.header.fontStyle,
            )}
            onChange={(option) => {
              if (!option) return;
              setProp((props: PricingTableProps) => {
                props.header.fontStyle = option.value;
              });
            }}
          />
        </Settings.Input>

        <Settings.Input label="Show annual/monthly toggle">
          <Checkbox
            checked={nodeProps.showPeriodToggle}
            onChange={() => {
              setProp((props: PricingTableProps) => {
                const updated = !props.showPeriodToggle;
                props.showPeriodToggle = updated;
              });
            }}
          />
        </Settings.Input>

        <Settings.Input label="Show yearly discount promotion">
          <Checkbox
            checked={nodeProps.showDiscount}
            onChange={() => {
              setProp((props: PricingTableProps) => {
                const updated = !props.showDiscount;
                props.showDiscount = updated;
              });
            }}
          />
        </Settings.Input>
      </Settings.Section>

      <Settings.Section>
        <Settings.Header
          isVisible={nodeProps.plans.isVisible}
          onVibibilityChange={() => {
            setProp((props: PricingTableProps) => {
              const updated = !props.plans.isVisible;
              props.plans.isVisible = updated;
            });
          }}
        >
          Plans section
        </Settings.Header>

        <Settings.Input label="Plan name style">
          <Select
            className="shrink-0 w-36"
            isMulti={false}
            options={typographyOptions}
            rawValue={nodeProps.plans.name.fontStyle as string}
            value={typographyOptions.find(
              (opt) => opt.value === nodeProps.plans.name.fontStyle,
            )}
            onChange={(option) => {
              if (!option) return;
              setProp((props: PricingTableProps) => {
                props.plans.name.fontStyle = option.value;
              });
            }}
          />
        </Settings.Input>

        <Settings.Input label="Show plan description">
          <Checkbox
            checked={nodeProps.plans.description.isVisible}
            onChange={() => {
              setProp((props: PricingTableProps) => {
                const updated = !props.plans.description.isVisible;
                props.plans.description.isVisible = updated;
              });
            }}
          />
        </Settings.Input>

        <Settings.Input label="Description font">
          <Select
            className="shrink-0 w-36"
            isMulti={false}
            options={typographyOptions}
            rawValue={nodeProps.plans.description.fontStyle as string}
            value={typographyOptions.find(
              (opt) => opt.value === nodeProps.plans.description.fontStyle,
            )}
            onChange={(option) => {
              if (!option) return;
              setProp((props: PricingTableProps) => {
                props.plans.description.fontStyle = option.value;
              });
            }}
          />
        </Settings.Input>

        <Settings.Input label="“Everything in..”">
          <Checkbox
            checked={nodeProps.plans.showInclusionText}
            onChange={() => {
              setProp((props: PricingTableProps) => {
                const updated = !props.plans.showInclusionText;
                props.plans.showInclusionText = updated;
              });
            }}
          />
        </Settings.Input>

        <Settings.Input label="Show feature icon">
          <Checkbox
            checked={nodeProps.plans.showFeatureIcons}
            onChange={() => {
              setProp((props: PricingTableProps) => {
                const updated = !props.plans.showFeatureIcons;
                props.plans.showFeatureIcons = updated;
              });
            }}
          />
        </Settings.Input>

        <Settings.Input label="Show entitlements">
          <Checkbox
            checked={nodeProps.plans.showEntitlements}
            onChange={() => {
              setProp((props: PricingTableProps) => {
                const updated = !props.plans.showEntitlements;
                props.plans.showEntitlements = updated;
              });
            }}
          />
        </Settings.Input>
      </Settings.Section>

      <Settings.Section>
        <Settings.Header
          isVisible={nodeProps.addOns.isVisible}
          onVibibilityChange={() => {
            setProp((props: PricingTableProps) => {
              const updated = !props.addOns.isVisible;
              props.addOns.isVisible = updated;
            });
          }}
        >
          Addons section
        </Settings.Header>

        <Settings.Input label="Show description">
          <Checkbox
            checked={nodeProps.addOns.showDescription}
            onChange={() => {
              setProp((props: PricingTableProps) => {
                const updated = !props.addOns.showDescription;
                props.addOns.showDescription = updated;
              });
            }}
          />
        </Settings.Input>

        <Settings.Input label="Show add on entitlements">
          <Checkbox
            checked={nodeProps.addOns.showEntitlements}
            onChange={() => {
              setProp((props: PricingTableProps) => {
                const updated = !props.addOns.showEntitlements;
                props.addOns.showEntitlements = updated;
              });
            }}
          />
        </Settings.Input>

        <Settings.Input label="Show feature icon">
          <Checkbox
            checked={nodeProps.addOns.showFeatureIcons}
            onChange={() => {
              setProp((props: PricingTableProps) => {
                const updated = !props.addOns.showFeatureIcons;
                props.addOns.showFeatureIcons = updated;
              });
            }}
          />
        </Settings.Input>
      </Settings.Section>

      <Settings.Section>
        <Settings.Header
          isVisible={nodeProps.upgrade.isVisible}
          onVibibilityChange={() => {
            setProp((props: PricingTableProps) => {
              const updated = !props.upgrade.isVisible;
              props.upgrade.isVisible = updated;
            });
          }}
        >
          Upgrade button
        </Settings.Header>

        <Settings.Input label="Button size">
          <Select
            className="shrink-0 w-36"
            isMulti={false}
            options={buttonSizeOptions}
            rawValue={nodeProps.upgrade.buttonSize as string}
            value={buttonSizeOptions.find(
              (opt) => opt.value === nodeProps.upgrade.buttonSize,
            )}
            onChange={(option) => {
              if (!option) return;
              setProp((props: PricingTableProps) => {
                props.upgrade.buttonSize = option.value;
              });
            }}
          />
        </Settings.Input>

        <Settings.Input label="Button style">
          <Select
            className="shrink-0 w-36"
            isMulti={false}
            options={buttonStyleOptions}
            rawValue={nodeProps.upgrade.buttonStyle as string}
            value={buttonStyleOptions.find(
              (opt) => opt.value === nodeProps.upgrade.buttonStyle,
            )}
            onChange={(option) => {
              if (!option) return;
              setProp((props: PricingTableProps) => {
                props.upgrade.buttonStyle = option.value;
              });
            }}
          />
        </Settings.Input>
      </Settings.Section>

      <Settings.Section>
        <Settings.Header
          isVisible={nodeProps.downgrade.isVisible}
          onVibibilityChange={() => {
            setProp((props: PricingTableProps) => {
              const updated = !props.downgrade.isVisible;
              props.downgrade.isVisible = updated;
            });
          }}
        >
          Downgrade button
        </Settings.Header>

        <Settings.Input label="Button size">
          <Select
            className="shrink-0 w-36"
            isMulti={false}
            options={buttonSizeOptions}
            rawValue={nodeProps.downgrade.buttonSize as string}
            value={buttonSizeOptions.find(
              (opt) => opt.value === nodeProps.downgrade.buttonSize,
            )}
            onChange={(option) => {
              if (!option) return;
              setProp((props: PricingTableProps) => {
                props.downgrade.buttonSize = option.value;
              });
            }}
          />
        </Settings.Input>

        <Settings.Input label="Button style">
          <Select
            className="shrink-0 w-36"
            isMulti={false}
            options={buttonStyleOptions}
            rawValue={nodeProps.downgrade.buttonStyle as string}
            value={buttonStyleOptions.find(
              (opt) => opt.value === nodeProps.downgrade.buttonStyle,
            )}
            onChange={(option) => {
              if (!option) return;
              setProp((props: PricingTableProps) => {
                props.downgrade.buttonStyle = option.value;
              });
            }}
          />
        </Settings.Input>
      </Settings.Section>
    </Settings.Root>
  );
};

PricingTable.craft = {
  displayName: "Pricing Table",
  props: {
    showPeriodToggle: true,
    showDiscount: true,
    header: {
      isVisible: true,
      fontStyle: "heading3",
    },
    plans: {
      isVisible: true,
      name: {
        fontStyle: "heading2",
      },
      description: {
        isVisible: true,
        fontStyle: "text",
      },
      showInclusionText: true,
      showFeatureIcons: true,
      showEntitlements: true,
    },
    addOns: {
      isVisible: true,
      showDescription: true,
      showFeatureIcons: true,
      showEntitlements: true,
    },
    upgrade: {
      isVisible: true,
      buttonSize: "sm",
      buttonStyle: "primary",
    },
    downgrade: {
      isVisible: true,
      buttonSize: "sm",
      buttonStyle: "primary",
    },
  } satisfies PricingTableProps,
  related: {
    settings: PricingTableSettings,
  },
};
