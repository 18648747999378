import { FormikControl } from "@components/forms/FormikControl";
import { Alert } from "@components/ui/Alert";
import { Icon } from "@components/ui/Icon";
import { Switch } from "@components/ui/Switch";
import { useContextQuery } from "@hooks/useContextQuery";
import { useCurrentEnvironment } from "@hooks/useCurrentEnvironment";
import { Plan } from "@models/plan";
import { PlanRoutePaths } from "@modules/plans/consts";
import { getPlansConfiguration } from "@modules/plans/queries/planConfiguration";
import { FormColumn, FormHeader, FormRow } from "@ui/FormParts";
import { PlanLabel } from "@ui/PlanLabel";
import { useFormikContext } from "formik";
import { Link } from "react-router-dom";

type PlanEditTrialStepProps = {
  verb: string;
  apiError?: string;
};

export const PlanEditTrialStep = ({
  verb,
  apiError,
}: PlanEditTrialStepProps) => {
  const { environment } = useCurrentEnvironment();
  const {
    setFieldValue,
    values: { isTrialable },
  } = useFormikContext<Plan>();
  const { data: planGroupData, isLoading: planroupIsLoading } = useContextQuery(
    {
      queryKey: ["plans", "configuration"],
      queryFn: async () => {
        try {
          return await getPlansConfiguration();
        } catch (error: any) {
          if (error.responseCode === 404) {
            return {
              addOns: [],
              addOnIds: [],
              id: undefined,
              defaultPlan: undefined,
              defaultPlanId: undefined,
              plans: [],
              planIds: [],
              trialDays: undefined,
              trialPaymentMethodRequired: false,
            };
          }

          throw error;
        }
      },
      retry: (failureCount, error: any) => {
        if (error.responseCode === 404) {
          return false;
        }

        return failureCount < 3;
      },
    },
  );

  const defaultTrialDays = planGroupData?.trialDays;
  const defaultPlan = planGroupData?.defaultPlan;
  const trialPaymentMethodRequired = planGroupData?.trialPaymentMethodRequired;

  return (
    <>
      <FormHeader
        label={`${verb} plan`}
        title={"Trial"}
        description="Use trails to grant temporary access to paid plans"
      />
      <FormRow className=" justify-start items-start">
        <div className="mt-4">
          <Switch
            name="isTrialable"
            defaultChecked={isTrialable}
            onCheckedChange={(checked) => {
              setFieldValue("isTrialable", checked);
            }}
          />
        </div>
        <FormColumn>
          <FormRow>
            <FormRow className="justify-between">
              <div>
                <div className="text-black text-lg font-body flex leading-xs items-center">
                  <Icon
                    name="watch-pocket"
                    className="-ml-3 text-5xl text-gray-400 leading-none"
                  />
                  <span>Trial Available</span>
                </div>
                <div className="font-body text-gray-600 leading-none text-sm">
                  Allow companies subscribing to this plan the first time to
                  access a free trial.
                </div>
              </div>
            </FormRow>
          </FormRow>

          {isTrialable && !planroupIsLoading && (
            <>
              <div className="relative ">
                <FormikControl
                  control="input"
                  name="trialDays"
                  type="number"
                  label="Trial Period"
                  onChange={(days) => {
                    setFieldValue("trialDays", days);
                  }}
                  defaultValue={defaultTrialDays}
                />
                <span className="cursor-default  select-none absolute bottom-[.75rem] right-[1rem] text-sm text-gray-400">
                  days
                </span>
              </div>

              <Alert size="sm" className="!p-6">
                <ul className="list-disc pl-4 text-sm space-y-1">
                  {trialPaymentMethodRequired && (
                    <>
                      <li>Payment method required for trials.</li>
                      <li>
                        At end of trial, the subscription will start and the
                        company will be billed.
                      </li>
                    </>
                  )}
                  {!trialPaymentMethodRequired && (
                    <>
                      <li>Payment method not required for trials.</li>
                      {defaultPlan && (
                        <li>
                          <div className="">
                            At end of trial, company will be downgraded to the
                            default plan
                            <span className="inline-flex before:mr-1 after:ml-1 before:content-['('] after:content-[')']">
                              <PlanLabel
                                plan={defaultPlan}
                                font="normal"
                                className="text-sm -ml-2.5"
                              />{" "}
                            </span>
                          </div>
                        </li>
                      )}
                      {!defaultPlan && (
                        <li>
                          At end of trial, company will be removed from the plan
                        </li>
                      )}
                    </>
                  )}
                  {defaultTrialDays && (
                    <li>Default Trial Period {defaultTrialDays} days</li>
                  )}
                </ul>

                <Link
                  to={`/${environment?.id}/${PlanRoutePaths.Configuration}`}
                  target="_blank"
                  className="text-blue-400 text-sm mt-5 hover:underline cursor-pointer inline-block"
                >
                  Configure trial settings
                </Link>
              </Alert>
            </>
          )}

          {apiError && (
            <div className="px-2">
              <Alert size="xs" style="red">
                <div className="flex items-center justify-center space-x-2">
                  <div className="text-base font-body ">
                    <span className="font-semibold">Uh-oh!</span> {apiError}
                  </div>
                </div>
              </Alert>
            </div>
          )}
        </FormColumn>
      </FormRow>
    </>
  );
};
