import { ErrorBoundaryFallback } from "@ui/ErrorBoundaryFallback";
import { Navigate, RouteObject } from "react-router-dom";
import { CompanyUsersView, CompanyUserView } from "./components";
import { CompanyUserOverviewTab, CompanyUserUsageTab } from "./components/tabs";
import { CompanyUserSubview } from "./types";

export const companyUserRoutes: RouteObject[] = [
  {
    path: "users",
    element: <CompanyUsersView />,
    errorElement: <ErrorBoundaryFallback />,
  },
  {
    path: "users/:id/:subview?",
    element: <CompanyUserView />,
    errorElement: <ErrorBoundaryFallback />,
    children: [
      {
        index: true,
        element: <Navigate to={CompanyUserSubview.Overview} replace />,
      },

      {
        path: CompanyUserSubview.Overview,
        element: <CompanyUserOverviewTab />,
      },

      {
        path: CompanyUserSubview.Usage,
        element: <CompanyUserUsageTab />,
      },
    ],
  },
];
