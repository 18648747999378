import {
  CompanyDetailResponseData,
  EventSummaryResponseData,
  PlanDetailResponseData,
  UserDetailResponseData,
} from "@models/api";
import { ReactNode } from "react";
import { components, OptionProps } from "react-select";
import { Pill } from "../Pill";

interface OptionLabelProps {
  label: string | ReactNode;
  children: ReactNode;
}

export const OptionLabel = ({ label, children }: OptionLabelProps) => {
  return (
    <div className="flex justify-between items-center">
      <div className="text-base">{label}</div>
      <span className="leading-none text-sm text-gray-400">{children}</span>
    </div>
  );
};

export const UserOptionLabel = (
  props: OptionProps<{ label: string; resource: UserDetailResponseData }>,
) => {
  const { data } = props;
  return (
    <components.Option {...props}>
      <OptionLabel
        label={
          <div className="flex flex-row justify-center items-center space-x-2 ">
            <span>{data.label}</span>
            <Pill text="code">{data.resource.id}</Pill>
          </div>
        }
      >
        {data?.resource?.companyMemberships[0]?.company?.name}
      </OptionLabel>
    </components.Option>
  );
};

export const CompanyOptionLabel = (
  props: OptionProps<{ label: string; resource: CompanyDetailResponseData }>,
) => {
  const { data } = props;
  return (
    <components.Option {...props}>
      <OptionLabel label={data.label}>
        <Pill text="code">{data.resource.id}</Pill>
      </OptionLabel>
    </components.Option>
  );
};

export const PlanOptionLabel = (
  props: OptionProps<{ label: string; resource: PlanDetailResponseData }>,
) => {
  const { data } = props;
  return (
    <components.Option {...props}>
      <OptionLabel label={data.label}>
        <Pill text="code">{data.resource.id}</Pill>
      </OptionLabel>
    </components.Option>
  );
};

interface EventTypeOptionLabelProps {
  eventType: EventSummaryResponseData;
}

export const EventTypeOptionLabel = ({
  eventType,
}: EventTypeOptionLabelProps) => {
  const description = eventType.eventCount
    ? `${eventType.eventCount} events last 60 days`
    : "No recent events";
  return (
    <OptionLabel label={eventType.eventSubtype}>
      <span className="traits">{description}</span>
    </OptionLabel>
  );
};
