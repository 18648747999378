import { useOrganization } from "@clerk/clerk-react";
import type { OrganizationInvitationResource } from "@clerk/types";
import { PaginationState } from "@hooks/useTablePagination";
import { ClerkUserPermission, ClerkUserRole } from "@models/clerkUser";
import { ColumnDef } from "@tanstack/react-table";
import { DropdownDots } from "@ui/DropdownDots";
import { Table } from "@ui/Table";
import { User } from "@ui/User";
import { formatDate } from "@utils/date";
import React, { useEffect, useMemo, useState } from "react";
import { InvitationRevokeOverlay } from "../overlays/InvitationRevokeOverlay";

export type OrganizationInvitation = OrganizationInvitationResource & {
  publicMetadata: {
    email?: string;
    role?: ClerkUserRole;
    permissions?: ClerkUserPermission[];
  };
};

export const InvitationsTable = () => {
  const [revokeInvitation, setRevokeInvitation] = useState<
    OrganizationInvitation | undefined
  >(undefined);
  const { isLoaded, invitations } = useOrganization({
    invitations: true,
  });

  const columns = useMemo<ColumnDef<OrganizationInvitation>[]>(
    () => [
      {
        id: "createdAt",
        header: "Date Sent",
        accessorKey: "createdAt",
        size: 140,
        cell: (cellInfo) => {
          return (
            <div className="inline-block space-y-1">
              <div className="leading-none text-gray-400">
                {formatDate(cellInfo.row.original.createdAt)}
              </div>
            </div>
          );
        },
      },
      {
        id: "name",
        header: "Name",
        accessorKey: "name",
        size: 140,
        cell: (cellInfo) => {
          const { publicMetadata } = cellInfo.row.original;
          const firstName = publicMetadata.firstName as string;
          const lastName = publicMetadata.lastName as string;

          let displayName = "";
          if (firstName && lastName) {
            displayName = `${firstName} ${lastName}`;
          } else if (firstName) {
            displayName = firstName;
          } else if (lastName) {
            displayName = lastName;
          }

          return (
            <div className="flex items-center">
              <User name={displayName} />
            </div>
          );
        },
      },
      {
        id: "email",
        header: "Email",
        accessorKey: "email",
        size: 200,
        cell: (cellInfo) => {
          const { emailAddress } = cellInfo.row.original;
          return <span>{emailAddress}</span>;
        },
      },
      {
        id: "actions",
        header: "",
        accessorKey: "actions",
        maxSize: 75,
        cell: (cellInfo) => {
          return (
            <div className="flex flex-row items-end justify-end">
              <DropdownDots
                links={[
                  {
                    label: "Revoke",
                    onClick: () => {
                      setRevokeInvitation(cellInfo.row.original);
                    },
                  },
                ]}
              />
            </div>
          );
        },
      },
    ],
    [],
  );

  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  useEffect(() => {
    invitations?.fetchPage?.(pagination.pageIndex + 1);
  }, [invitations, pagination]);

  if (!isLoaded) {
    return <>Loading</>;
  }

  if (!invitations?.data) {
    return null;
  }

  return (
    <div className="w-full">
      <Table
        columns={columns}
        data={invitations.data}
        pageCount={invitations.pageCount}
        pageIndex={pagination.pageIndex}
        pageSize={pagination.pageSize}
        setPagination={setPagination}
      />
      {revokeInvitation && (
        <InvitationRevokeOverlay
          onClose={() => {
            setRevokeInvitation(undefined);
            invitations?.revalidate();
          }}
          organizationInvitation={revokeInvitation}
        />
      )}
    </div>
  );
};
