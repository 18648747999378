import { BlankStateOnCreate } from "@ui/PageBlankState";
import { TableBlankState } from "@ui/TableBlankState";

export const PlanEntitlementsTableBlankState = ({
  onCreate,
  disabled,
}: BlankStateOnCreate) => {
  return (
    <TableBlankState
      title="📦 Add a plan entitlement"
      description={
        <>
          Include this feature into one of your plans when you’re ready to go to
          market.
          <br />
          Once in plan, it will be accessible to any customers that have that
          plan.
        </>
      }
      buttonText="Add plan entitlement"
      onCreate={onCreate}
      disabled={disabled}
    />
  );
};

export const CompanyPlanEntitlementsTableBlankState = ({
  onCreate,
  disabled,
}: BlankStateOnCreate) => {
  return (
    <TableBlankState
      title="👥 Assign a plan"
      description={
        <>
          Add this company to a plan audience to assign a plan and all of its
          feature <br />
          entitlements to this company.
        </>
      }
      buttonText="Go to plans"
      onCreate={onCreate}
      disabled={disabled}
    />
  );
};
