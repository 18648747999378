import { ErrorBoundaryFallback } from "@ui/ErrorBoundaryFallback";
import { Navigate, RouteObject } from "react-router-dom";
import {
  FeatureFlagView,
  FeaturesView,
  FeatureView,
  FlagsView,
} from "./components";
import {
  FeatureCompaniesTab,
  FeatureEntitlementsTab,
  FeatureEventsTab,
  FeatureFlagTab,
  FeatureUsersTab,
  FlagChecksTab,
  FlagTargetingTab,
} from "./components/tabs";
import { FeatureSubview, FlagSubview } from "./types";

export const featureRoutes: RouteObject[] = [
  {
    path: "features",
    element: <FeaturesView />,
    errorElement: <ErrorBoundaryFallback />,
  },
  {
    path: "features/:id/:subview?",
    element: <FeatureView />,
    errorElement: <ErrorBoundaryFallback />,
    children: [
      {
        index: true,
        element: <Navigate to={FeatureSubview.Entitlements} replace />,
      },
      {
        path: FeatureSubview.Entitlements,
        element: <FeatureEntitlementsTab />,
      },
      {
        path: FeatureSubview.Flag,
        element: <FeatureFlagTab />,
      },
      {
        path: FeatureSubview.Usage,
        element: <FeatureEventsTab />,
      },
      {
        path: FeatureSubview.Companies,
        element: <FeatureCompaniesTab />,
      },
      {
        path: FeatureSubview.Users,
        element: <FeatureUsersTab />,
      },
    ],
  },
];

export const flagRoutes: RouteObject[] = [
  {
    path: "flags",
    element: <FlagsView />,
    errorElement: <ErrorBoundaryFallback />,
  },
  {
    path: "flags/:id/:subview?",
    element: <FeatureFlagView />,
    errorElement: <ErrorBoundaryFallback />,
    children: [
      { index: true, element: <Navigate to={FlagSubview.Targeting} replace /> },
      {
        path: FlagSubview.Targeting,
        element: <FlagTargetingTab />,
      },
      {
        path: FlagSubview.Checks,
        element: <FlagChecksTab />,
      },
    ],
  },
];
