import { ErrorBoundaryFallback } from "@ui/ErrorBoundaryFallback";
import { RouteObject } from "react-router-dom";
import { ComponentView, ComponentsView } from "./components";

export const componentRoutes: RouteObject[] = [
  {
    path: "components",
    element: <ComponentsView />,
    errorElement: <ErrorBoundaryFallback />,
  },
  {
    path: "components/:id",
    element: <ComponentView />,
    errorElement: <ErrorBoundaryFallback />,
  },
];
