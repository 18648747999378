import { BillingSubscriptionDiscountView } from "@models/api";
import { Icon } from "@ui/Icon";
import { formatCurrency } from "@utils/strings";

export type DiscountBlockProps = {
  discounts: BillingSubscriptionDiscountView[];
};

export const DiscountBlock = ({ discounts }: DiscountBlockProps) => {
  const offType = (discount: BillingSubscriptionDiscountView) => {
    if (discount.amountOff === 0 && discount.percentOff! > 0) {
      return (
        <div className={"flex flex-row items-center"}>
          {" "}
          <Icon className="text-4xl leading-none " name={"percent"} />{" "}
          {discount.percentOff!}% OFF
        </div>
      );
    }

    return (
      <div className={"flex flex-row items-center"}>
        {formatCurrency(discount.amountOff!, discount.currency!)} OFF
      </div>
    );
  };

  if (!discounts || discounts.length === 0) {
    return null;
  }

  return (
    <div>
      {discounts.map((discount: BillingSubscriptionDiscountView) => (
        <div className="space-y-8 pb-16">
          <p className="text-3xl">Discount</p>
          <div className="bg-white shadow-lg border flex flex-row  justify-between border-gray-400/15 rounded-lg">
            <div className="px-8 py-8 flex flex-row gap-4 items-center">
              <div>{offType(discount)}</div>
              {!!discount.customerFacingCode && (
                <div className={"border-2 rounded border-blue-100 px-2"}>
                  <div>{discount.customerFacingCode}</div>
                </div>
              )}
            </div>

            <div className={"px-8 py-8 flex flex-row gap-4"}>
              <p>
                Duration: <strong>{discount.duration}</strong>
              </p>
              <a
                className={"text-blue-600"}
                target={"_blank"}
                href={`https://dashboard.stripe.com/test/coupons/${discount.couponId}`}
              >
                See in Stripe
              </a>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
