import { CreateOrUpdateConditionRequestBodyMetricPeriodEnum } from "@models/api";

export const MetricPeriodDisplay = {
  [CreateOrUpdateConditionRequestBodyMetricPeriodEnum.AllTime]: "all time",
  [CreateOrUpdateConditionRequestBodyMetricPeriodEnum.Billing]:
    "per billing period",
  [CreateOrUpdateConditionRequestBodyMetricPeriodEnum.CurrentDay]: "per day",
  [CreateOrUpdateConditionRequestBodyMetricPeriodEnum.CurrentMonth]:
    "per month",
  [CreateOrUpdateConditionRequestBodyMetricPeriodEnum.CurrentWeek]: "per week",
};

export const MetricPeriodDisplayShortHand = {
  [CreateOrUpdateConditionRequestBodyMetricPeriodEnum.AllTime]: "/all time",
  [CreateOrUpdateConditionRequestBodyMetricPeriodEnum.Billing]:
    "/billing period",
  [CreateOrUpdateConditionRequestBodyMetricPeriodEnum.CurrentDay]: "/day",
  [CreateOrUpdateConditionRequestBodyMetricPeriodEnum.CurrentMonth]: "/mo",
  [CreateOrUpdateConditionRequestBodyMetricPeriodEnum.CurrentWeek]: "/week",
};

export enum PlanRoutePaths {
  Plans = "plans",
  AddOns = "plan-add-ons",
  Configuration = "plan-configuration",
}

export const PriceIntervalDisplayName: Record<string, string> = {
  day: "/day",
  week: "/week",
  month: "/mo",
  year: "/year",
};
