import { Alert } from "@components/ui/Alert";
import { FormikControl } from "@forms/FormikControl";
import { FormikSelect } from "@forms/FormikSelect";
import { PlansConfigurationViewFormValues } from "@modules/plans/components/PlansConfigurationView";
import { Elevate } from "@ui/Elevate";
import { PlanLabel } from "@ui/PlanLabel";
import { useFormikContext } from "formik";

const selectOptions = [
  {
    label: "Require payment method to start trial",
    value: true,
  },
  {
    label: "Don't require payment method to start trial",
    value: false,
  },
];

export const TrialsConfigurationElevate = () => {
  const { values } = useFormikContext<PlansConfigurationViewFormValues>();

  return (
    <Elevate className="flex flex-col">
      <div className="flex flex-row space-x-16">
        <div className="flex flex-col space-y-4 max-w-[220px]">
          <div className="text-xl">Trials</div>
          <div className="flex flex-col space-y-4 text-gray-600 text-sm">
            <div>
              Define default trial behavior. Only companies subscribing to any
              paid plan for the first time get access to a trial.
            </div>
            <div>
              Enable trial for individual plans . Add and edit trials for
              individual companies.
            </div>
          </div>
        </div>
        <div className="flex flex-col space-y-8 w-full">
          <div className="flex-1">
            <FormikSelect
              label="Payment Method"
              name="trialPaymentMethodRequired"
              options={selectOptions}
              selectedOption={selectOptions.find(
                (c) => c.value === values.trialPaymentMethodRequired,
              )}
              selectClassName="z-[1]"
            />

            <Alert
              style="gray"
              size="xs"
              className="w-full space-y-6 pt-12 -mt-8 "
            >
              <div className="flex flex-col space-y-2">
                {!values.trialPaymentMethodRequired && (
                  <>
                    {values.defaultPlan && (
                      <div className="flex">
                        <span className="mr-2">
                          At end of trial, company will be downgraded to the
                          default plan
                        </span>
                        <span className="inline-flex before:mr-1 after:ml-1 before:content-['('] after:content-[')']">
                          <PlanLabel
                            plan={values.defaultPlan}
                            font="normal"
                            className="text-base -ml-2"
                          />
                        </span>
                      </div>
                    )}
                    {!values.defaultPlan && (
                      <div>
                        At end of trial, company will be removed from the plan.
                      </div>
                    )}
                  </>
                )}
                {values.trialPaymentMethodRequired && (
                  <div>
                    At end of trial, the subscription will start and the company
                    will be billed.
                  </div>
                )}
              </div>
            </Alert>
          </div>
          <div className="flex-1">
            <FormikControl
              control="input"
              name="trialDays"
              type="number"
              label="Default Trial Period in Days"
              placeholder="Trial Period in Days"
            />
          </div>
        </div>
      </div>
    </Elevate>
  );
};
