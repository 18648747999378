import { TableLoader } from "@components/loaders/TableLoader";
import { Alert } from "@components/ui/Alert";
import { LabeledTooltip } from "@components/ui/LabeledTooltip";
import { SearchBar } from "@components/ui/SearchBar";
import { useNavigateEnvironment } from "@hooks/useNavigateEnvironment";
import { TablePaginationResult } from "@hooks/useTablePagination";
import { CompanyDetailResponseData } from "@models/api";
import { AudienceRequestBody } from "@models/api";
import { SubscriptionPriceDisplay } from "@modules/companies/components/SubscriptionPriceDisplay";
import { ColumnDef } from "@tanstack/react-table";
import { Table } from "@ui/Table";
import { timeAgo } from "@utils/date";
import { useMemo } from "react";
import { PreviewSearchPortal } from "../PlanAudiencePreview";

interface CompaniesPreviewTableProps {
  onSearch?: any;
  searchPlaceHolder?: string;
  tableData: TablePaginationResult<
    CompanyDetailResponseData,
    Pick<AudienceRequestBody, "limit" | "offset" | "q"> & {
      conditions: string;
      conditionGroups: string;
    }
  >;
}

export const CompaniesPreviewTable = ({
  onSearch,
  searchPlaceHolder,
  tableData,
}: CompaniesPreviewTableProps) => {
  const navigate = useNavigateEnvironment();

  const {
    countQuery,
    listQuery,
    pageCount,
    pageIndex,
    pageSize,
    setPagination,
  } = tableData;

  const columns = useMemo<ColumnDef<CompanyDetailResponseData>[]>(
    () => [
      {
        id: "name",
        header: "Name",
        accessorKey: "name",
        cell: (cellInfo) => {
          return <>{cellInfo.row.original.name}</>;
        },
      },

      {
        id: "subscription",
        header: "Subscription",
        accessorKey: "subscription",
        cell: function Cell(cellInfo) {
          const subscription = cellInfo.row.original?.billingSubscription;
          return (
            <div>
              <SubscriptionPriceDisplay subscription={subscription} />
            </div>
          );
        },
      },
      {
        id: "updatedAt",
        header: () => (
          <span className="relative flex flex-row space-x-1 items-center">
            <span> Last seen </span>
            <span className="!tracking-[0] !leading-none !normal-case !font-display">
              <LabeledTooltip
                description="This is the date a track or identify event associated with a company was last received by Schematic."
                className="!bg-transparent"
                placement="bottom-left"
                size="lg"
              />
            </span>
          </span>
        ),
        accessorKey: "lastSeenAt",
        size: 200,
        cell: (cellInfo) => {
          const { lastSeenAt } = cellInfo.row.original;
          return <>{lastSeenAt ? timeAgo(lastSeenAt) : ""}</>;
        },
      },
    ],
    [],
  );

  const onRowClick = (row: CompanyDetailResponseData) =>
    navigate(`companies/${row.id}`);

  if (listQuery.error) throw listQuery.error;
  if (countQuery.error) throw countQuery.error;

  const loading =
    listQuery.isLoading || countQuery.isLoading || !listQuery.data;

  const noCompaniesPreview = countQuery?.data?.count === 0;

  const renderTable = () => {
    switch (true) {
      case loading:
        return <TableLoader />;
      case noCompaniesPreview:
        return (
          <Alert size="md" style="gray" className="text-center mt-6">
            <div className="flex flex-row justify-between w-full flex-1 relative z-10 text-gray">
              <div className="flex flex-col text-left items-start space-y-2">
                <div className="text-[25px] space-x-2 font-body flex items-start justify-center ">
                  <span className="leading-none text-3xl">👥</span>
                  <span>No companies match this ruleset</span>
                </div>
                <div className="text-lg leading-6 text-gray-600">
                  Add or edit rules to begin defining this plan’s audience
                </div>
              </div>
            </div>
          </Alert>
        );
      default:
        return (
          listQuery?.data && (
            <Table
              className="mb-8"
              columns={columns}
              data={listQuery.data}
              pageCount={pageCount}
              pageIndex={pageIndex}
              pageSize={pageSize}
              setPagination={setPagination}
              onRowClick={onRowClick}
            />
          )
        );
    }
  };

  return (
    <>
      <PreviewSearchPortal>
        {!loading && (
          <div className="flex space-x-2">
            <SearchBar
              size="sm"
              placeholder={`Find ${searchPlaceHolder}`}
              onChange={onSearch}
            />
          </div>
        )}
      </PreviewSearchPortal>

      {renderTable()}
    </>
  );
};
