import { FetchError } from "@data/index";
import { init as initFullStory } from "@fullstory/browser";
import { router } from "@routes/routes";
import * as Sentry from "@sentry/react";
import React from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider } from "react-router-dom";

import "./styles/globals.css";

if (import.meta.env.VITE_SENTRY_DSN) {
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    environment: import.meta.env.VITE_ENVIRONMENT || "local",

    beforeSend(event, hint) {
      if ((hint.originalException as Error)?.name === "FetchError") {
        const exc = hint.originalException as FetchError;
        const fetchUrl = exc.url;
        const fetchResponseCode = exc.responseCode;
        const fetchRequestId = exc.requestId;
        event.extra = {
          ...event.extra,
          fetchRequestId,
          fetchResponseCode,
          fetchUrl,
        };
      }
      return event;
    },
  });
}

if (import.meta.env.VITE_FULLSTORY_ORG) {
  initFullStory({ orgId: import.meta.env.VITE_FULLSTORY_ORG });
}

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <RouterProvider router={router} />,
);
