import { TableLoader } from "@components/loaders/TableLoader";
import useTablePagination from "@hooks/useTablePagination";
import { Feature } from "@models/feature";
import { FeatureCompany } from "@models/usage";
import { CompanySubview } from "@modules/companies/types";
import {
  EntitlementUsageCell,
  FeaturesTableEmptyFilterState,
} from "@modules/features";
import {
  countFeatureCompanies,
  listFeatureCompanies,
  FeatureUsageFilter,
} from "@modules/features/queries/usage";
import { ColumnDef } from "@tanstack/react-table";
import { Logo } from "@ui/Logo";
import { PillGroup } from "@ui/PillGroup";
import { Table } from "@ui/Table";
import { TableHeader } from "@ui/TableHeader";
import { timeAgo } from "@utils/date";

import pluralize from "pluralize";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import { FeatureCompaniesBlankState } from "../blank-states/FeatureCompaniesBlankState";

export interface FeatureCompaniesTableProps {
  feature: Feature;
}

export const FeatureCompaniesTable = ({
  feature,
}: FeatureCompaniesTableProps) => {
  const featureId = feature.id;
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [filter, setFilter] = useState<FeatureUsageFilter>({});
  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  useEffect(() => {
    setFilter(searchTerm === "" ? {} : { q: searchTerm });
  }, [searchTerm]);

  const columns = useMemo<ColumnDef<FeatureCompany>[]>(
    () => [
      {
        id: "name",
        header: "Name",
        accessorKey: "name",
        cell: (cellInfo) => {
          const company = cellInfo.row.original.company;
          if (!company) return <></>;

          const lastUsedAt = company.lastSeenAt
            ? timeAgo(company.lastSeenAt)
            : "";

          return (
            <div className="flex space-x-2 items-center">
              <Logo src={company.logoUrl} size="sm" alt={company.name} />
              <div>{company.name}</div>
              <div className="leading-none text-gray-400">{lastUsedAt}</div>
            </div>
          );
        },
      },
      {
        id: "usage",
        header: "Usage",
        accessorKey: "usage",
        cell: (cellInfo) => {
          return <EntitlementUsageCell featureUsage={cellInfo.row.original} />;
        },
      },
      {
        id: "plans",
        header: "Plans",
        accessorKey: "plans",
        cell: (cellInfo) => {
          const { company } = cellInfo.row.original;
          if (!company) return <></>;

          return <PillGroup items={company.plans} />;
        },
      },
    ],
    [feature],
  );

  const getHeaderText = (count: number) => {
    return pluralize("Company", count, true);
  };

  const {
    countQuery,
    headerText,
    listQuery,
    pageCount,
    pageIndex,
    pageSize,
    setPagination,
  } = useTablePagination<FeatureCompany, FeatureUsageFilter>(
    ["feature-companies", featureId, searchTerm],
    listFeatureCompanies(featureId),
    countFeatureCompanies(featureId),
    filter,
    getHeaderText,
  );

  const onRowClick = (row: FeatureCompany) => {
    if (!row.company) return;

    navigate(
      `../../companies/${row.company.id}/${CompanySubview.Entitlements}`,
    );
  };

  if (listQuery.error) throw listQuery.error;
  if (countQuery.error) throw countQuery.error;

  const noCompaniesCreated = countQuery?.data?.count === 0;
  const loading =
    listQuery.isLoading || countQuery.isLoading || !listQuery.data;

  const renderTable = () => {
    switch (true) {
      case noCompaniesCreated:
        return <FeatureCompaniesBlankState feature={feature} />;
      case loading:
        return <TableLoader />;
      case listQuery.data?.length === 0:
        return <FeaturesTableEmptyFilterState />;
      default:
        return (
          listQuery?.data && (
            <Table
              columns={columns}
              data={listQuery.data}
              onRowClick={onRowClick}
              pageCount={pageCount}
              pageIndex={pageIndex}
              pageSize={pageSize}
              setPagination={setPagination}
            />
          )
        );
    }
  };

  return (
    <>
      {!noCompaniesCreated && (
        <TableHeader
          headerText={headerText === "Companies" ? "" : headerText}
          onSearch={handleSearch}
          searchPlaceholder={!noCompaniesCreated ? "Search companies" : ""}
        />
      )}
      {renderTable()}
    </>
  );
};
