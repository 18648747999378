import { CompanyDetailResponseData } from "@models/api";
import { PlanType } from "@models/plan";
import {
  CompanyDetails,
  CompanyFeaturesTable,
  CompanyPlansTable,
} from "@modules/companies";
import { CompanyOverridesTable } from "@modules/features";
import { useOutletContext } from "react-router-dom";

export const CompanyEntitlementsTab = () => {
  const { company } = useOutletContext<{
    company: CompanyDetailResponseData;
  }>();

  return (
    <div className="space-y-8 pb-16">
      <CompanyDetails company={company} />
      <CompanyOverridesTable company={company} />
      <CompanyPlansTable companyId={company.id} planType={PlanType.Plan} />
      <CompanyPlansTable companyId={company.id} planType={PlanType.AddOn} />
      <CompanyFeaturesTable company={company} />
    </div>
  );
};
