import SchematicOverlayLoader from "@components/loaders/SchematicOverlayLoader";
import { errorMessage } from "@data/index";
import { FormikControl } from "@forms/FormikControl";
import { FormikStep, FormikStepper } from "@forms/FormikStepper";
import { useContextQuery } from "@hooks/useContextQuery";
import { useNavigateEnvironment } from "@hooks/useNavigateEnvironment";
import {
  type BillingPriceResponseData,
  BillingPriceView,
  BillingProductResponseData,
  CreateEntityTraitDefinitionRequestBodyEntityTypeEnum,
  CreateEntityTraitDefinitionRequestBodyTraitTypeEnum,
  CreatePlanEntitlementRequestBodyValueTypeEnum,
} from "@models/api";
import { PlanEntitlement } from "@models/entitlement";
import { Feature } from "@models/feature";
import { Plan, PlanIcon, PlanReq, PlanType } from "@models/plan";
import {
  createPlan,
  createPlanEntitlement,
  deletePlanEntitlement,
  listPlanEntitlements,
  updatePlan,
  updatePlanBillingProduct,
  updatePlanEntitlement,
} from "@modules/plans";
import { PlanRoutePaths } from "@modules/plans/consts";
import { createEntityTraitDefinition } from "@modules/settings/queries/entityTraits";
import { useSchematicFlag } from "@schematichq/schematic-react";
import { useQueryClient } from "@tanstack/react-query";
import { Alert } from "@ui/Alert";
import { randomDiamond } from "@ui/Diamond/consts";
import { FormColumn, FormHeader, FormRow } from "@ui/FormParts";
import { LabeledTooltip } from "@ui/LabeledTooltip";
import { Overlay, OverlayModal } from "@ui/Overlay";
import { OverlayFormAlert } from "@ui/OverlayFormAlert";
import { DropdownIcons } from "@ui/SelectIcons";

import { titlecase } from "@utils/strings";
import { FormikHelpers, FormikProps } from "formik";
import { useState } from "react";
import * as Yup from "yup";
import { PlanEditBillingStep } from "./PlanEditBillingStep";
import { PlanEditTrialStep } from "./PlanEditTrialStep";

export interface PlanEditOverlayProps {
  onClose: () => void;
  plan?: Plan;
  initialStep?: number;
  type: PlanType;
  isFree?: boolean;
}

export enum UsageBasedPriceBehavior {
  PayAsYouGo = "pay_as_you_go",
  PayInAdvance = "pay_in_advance",
}

export type UsageBasedEntitlements = {
  feature: Feature;
  meteredMonthlyPrice?: BillingPriceView;
  meteredYearlyPrice?: BillingPriceView;
  priceBehavior: UsageBasedPriceBehavior;
};

type PlanValues = PlanReq & {
  billingProduct: BillingProductResponseData;
  billingProductId: string;
  isTrialable: boolean;
  monthlyPrice: BillingPriceResponseData;
  monthlyPriceId: string;
  yearlyPrice: BillingPriceResponseData;
  yearlyPriceId: string;
  trialDays: number;
  isFree: boolean;
  usageBased: boolean;
  // todo:: move to enums
  usageBasedPriceBehavior: "pay_as_you_go" | "pay_in_advance";
  usageBasedEntitlements: UsageBasedEntitlements[];
};

const defineStepValidationSchema = Yup.object({
  name: Yup.string().required("Must provide a name"),
  description: Yup.string(),
  icon: Yup.string(),
});

const billingStepValidationSchema = Yup.object().shape(
  {
    isFree: Yup.boolean(),
    usageBased: Yup.boolean(),
    billingProductId: Yup.string(),
    usageBasedEntitlements: Yup.array<UsageBasedEntitlements>(),
    monthlyPriceId: Yup.string().when(
      ["billingProductId", "yearlyPriceId", "isFree"],
      ([bp, yp, isFree]) => {
        if (isFree) {
          return Yup.string().nullable();
        }
        return bp && !yp
          ? Yup.string().required("Either monthly or yearly price is required")
          : Yup.string().nullable();
      },
    ),
    yearlyPriceId: Yup.string().when(
      ["billingProductId", "monthlyPriceId", "isFree"],
      ([bp, mp, isFree]) => {
        if (isFree) {
          return Yup.string().nullable();
        }
        return bp && !mp
          ? Yup.string().required("Either monthly or yearly price is required")
          : Yup.string().nullable();
      },
    ),
  },
  [["monthlyPriceId", "yearlyPriceId"]],
);

export const PlanEditOverlay = ({
  onClose,
  plan,
  initialStep = 0,
  type,
}: PlanEditOverlayProps) => {
  const navigate = useNavigateEnvironment();
  const queryClient = useQueryClient();
  const billingFlag = useSchematicFlag("billing", { fallback: true });
  const trialFlag = useSchematicFlag("trials", { fallback: false });

  const verb = plan?.id ? "Edit" : "Create";
  const noun = type === PlanType.Plan ? "plan" : "add on";

  const [apiError, setApiError] = useState<string | undefined>();
  const [loading, setLoading] = useState<boolean>(false);
  const [closeAlert, setCloseAlert] = useState(false);
  const [dirty, setDirty] = useState(false);

  const { data: existingUsageBaseEntitlements } = useContextQuery({
    queryKey: ["list_usage_based_plan_entitlements_for_billing_products"],
    queryFn: () => {
      if (!plan?.id) {
        return Promise.resolve([]);
      }

      return listPlanEntitlements({
        planId: plan?.id,
        withMeteredProducts: true,
      });
    },
  });

  const initialValues = {
    description: plan?.description || "",
    name: plan?.name || "",
    usageBased: false,
    id: plan?.id,
    usageBasedEntitlements: [],
    isFree: plan?.isFree,
    usageBasedPriceBehavior: "pay_as_you_go",
    planType: (plan?.planType || type) as PlanType,
    icon: (plan?.icon || randomDiamond()) as PlanIcon,
    isTrialable: plan?.isTrialable,
    billingProduct: plan?.billingProduct,
    billingProductId: plan?.billingProduct?.productId,
    monthlyPrice: plan?.monthlyPrice,
    monthlyPriceId: plan?.monthlyPrice?.id,
    yearlyPrice: plan?.yearlyPrice,
    yearlyPriceId: plan?.yearlyPrice?.id,
    trialDays: plan?.trialDays,
  } as PlanValues;

  const onSubmit = async (
    {
      billingProduct,
      billingProductId,
      isTrialable,
      monthlyPrice,
      monthlyPriceId,
      isFree,
      yearlyPrice,
      yearlyPriceId,
      usageBasedPriceBehavior,
      usageBasedEntitlements,
      usageBased,
      trialDays,
      ...req
    }: PlanValues,
    helpers: FormikHelpers<PlanValues>,
  ) => {
    setLoading(true);

    const saveFn = plan?.id
      ? (values: PlanReq) => updatePlan(plan.id, values)
      : createPlan;

    const onSuccessFn = plan?.id
      ? onClose
      : (createdPlan: Plan) =>
          navigate(
            `${
              type === PlanType.Plan
                ? PlanRoutePaths.Plans
                : PlanRoutePaths.AddOns
            }/${createdPlan.id}`,
          );

    try {
      const plan = await saveFn(req);

      await updatePlanBillingProduct(plan.id, {
        billingProductId,
        monthlyPriceId,
        yearlyPriceId,
        isTrialable: isTrialable,
        trialDays: trialDays,
        isFreePlan: isFree,
      });

      const existingUsageBaseMap = new Map<string, PlanEntitlement>();
      const ubeToDelete: PlanEntitlement[] = [];

      existingUsageBaseEntitlements?.map((eube) => {
        existingUsageBaseMap.set(eube.featureId, eube);
        if (
          !usageBasedEntitlements.find(
            (ube) => ube.feature.id === eube.featureId,
          )
        ) {
          ubeToDelete.push(eube);
        }
      });

      const ubeToCreate: UsageBasedEntitlements[] = [];
      const ubeToUpdate: PlanEntitlement[] = [];

      usageBasedEntitlements.forEach((ube) => {
        const eube = existingUsageBaseMap.get(ube.feature.id);
        if (!eube) {
          ubeToCreate.push(ube);
          return;
        }

        if (!eube.meteredYearlyPrice && !eube.meteredYearlyPrice) {
          return;
        }

        const existingPrice = eube.meteredYearlyPrice
          ? eube.meteredYearlyPrice
          : eube.meteredMonthlyPrice;
        const newPricePrice = ube.meteredYearlyPrice
          ? ube.meteredYearlyPrice
          : ube.meteredMonthlyPrice;

        if (
          eube.featureId !== ube.feature.id ||
          newPricePrice?.id !== existingPrice?.id
        ) {
          if (existingPrice) {
            existingPrice.id = newPricePrice!.id;
            eube.featureId = ube.feature.id;
          }

          ubeToUpdate.push(eube);
          return;
        }
      });

      console.log("Ent to delete", ubeToDelete);
      console.log("Ent to update", ubeToUpdate);
      console.log("Ent ube to create", ubeToCreate);

      const entitlements = ubeToCreate.map(async (ube) => {
        if (
          ube.priceBehavior !== UsageBasedPriceBehavior.PayAsYouGo &&
          ube.priceBehavior !== UsageBasedPriceBehavior.PayInAdvance
        ) {
          throw new Error("Invalid usage based price behavior");
        }

        const valueType =
          ube.priceBehavior === UsageBasedPriceBehavior.PayAsYouGo
            ? CreatePlanEntitlementRequestBodyValueTypeEnum.Unlimited
            : CreatePlanEntitlementRequestBodyValueTypeEnum.Trait;

        let valueNumeric: number | undefined = 0;
        let valueTraitID: string | undefined = undefined;
        if (ube.priceBehavior === UsageBasedPriceBehavior.PayAsYouGo) {
          valueNumeric = undefined;
        }

        if (ube.priceBehavior === UsageBasedPriceBehavior.PayInAdvance) {
          valueNumeric = 0;
          if (!ube.feature.trait) {
            throw new Error("Invalid usage based feature trait is required");
          }

          let traitLimitSlug = ube.feature.name
            .toLowerCase()
            .replace(/ /g, "-")
            .replace(/[^\w-]+/g, "");

          traitLimitSlug += "-schematic-system-trait";

          // create trait for planEntitlements to handle feature limits
          // using comparison traits
          const createdEntityTrait = await createEntityTraitDefinition({
            displayName: traitLimitSlug,
            entityType:
              CreateEntityTraitDefinitionRequestBodyEntityTypeEnum.Company,
            hierarchy: [traitLimitSlug],
            traitType:
              CreateEntityTraitDefinitionRequestBodyTraitTypeEnum.Number,
          });

          valueTraitID = createdEntityTrait.id;
        }

        return createPlanEntitlement({
          planId: plan.id,
          yearlyMeteredPriceId: ube.meteredYearlyPrice?.id,
          monthlyMeteredPriceId: ube.meteredMonthlyPrice?.id,
          featureId: ube.feature.id,
          priceBehavior: ube.priceBehavior,
          valueNumeric: valueNumeric,
          valueType: valueType,
          valueTraitId: valueTraitID,
          metricPeriod: "billing",
        });
      });

      const entitlementsDelete = ubeToDelete.map((ube) => {
        return deletePlanEntitlement(ube.id);
      });

      const entitlementsUpdate = ubeToUpdate.map(async (ube) => {
        if (
          ube.priceBehavior !== UsageBasedPriceBehavior.PayAsYouGo &&
          ube.priceBehavior !== UsageBasedPriceBehavior.PayInAdvance
        ) {
          throw new Error("Invalid usage based price behavior");
        }

        const valueType =
          ube.priceBehavior === UsageBasedPriceBehavior.PayAsYouGo
            ? CreatePlanEntitlementRequestBodyValueTypeEnum.Unlimited
            : CreatePlanEntitlementRequestBodyValueTypeEnum.Trait;

        let valueNumeric: number | undefined = 0;
        let valueTraitID: string | undefined = undefined;
        if (ube.priceBehavior === UsageBasedPriceBehavior.PayAsYouGo) {
          valueNumeric = undefined;
        }

        if (ube.priceBehavior === UsageBasedPriceBehavior.PayInAdvance) {
          valueNumeric = 0;
          if (!ube.feature || !ube.feature.trait) {
            throw new Error("Invalid usage based feature trait is required");
          }

          let traitLimitSlug = ube.feature.name
            .toLowerCase()
            .replace(/ /g, "-")
            .replace(/[^\w-]+/g, "");

          traitLimitSlug += "-schematic-system-trait";

          // create trait for planEntitlements to handle feature limits
          // using comparison traits
          const createdEntityTrait = await createEntityTraitDefinition({
            displayName: traitLimitSlug,
            entityType:
              CreateEntityTraitDefinitionRequestBodyEntityTypeEnum.Company,
            hierarchy: [traitLimitSlug],
            traitType:
              CreateEntityTraitDefinitionRequestBodyTraitTypeEnum.Number,
          });

          valueTraitID = createdEntityTrait.id;
        }

        return updatePlanEntitlement(ube.id, {
          meteredYearlyPriceId: ube.meteredYearlyPrice?.id,
          meteredMonthlyPriceId: ube.meteredMonthlyPrice?.id,
          valueNumeric: valueNumeric,
          valueType: valueType,
          valueTraitId: valueTraitID,
          metricPeriod: "billing",
        });
      });

      await Promise.all([
        ...entitlements,
        ...entitlementsDelete,
        ...entitlementsUpdate,
      ]);

      await queryClient.invalidateQueries();
      setApiError(undefined);
      onSuccessFn(plan);
      setLoading(false);
    } catch (err) {
      setApiError(errorMessage(err));
      setLoading(false);
      helpers.setSubmitting(false);
    }
  };

  const handleClose = () => (dirty ? setCloseAlert(true) : onClose());

  return (
    <Overlay
      onClose={handleClose}
      className="flex items-center justify-center py-24"
    >
      {loading && <SchematicOverlayLoader />}
      {closeAlert && (
        <OverlayFormAlert setDirtyForm={setCloseAlert} onClose={onClose} />
      )}
      <OverlayModal size="xl">
        <FormikStepper
          className="flex-1 w-full"
          onSubmit={onSubmit}
          onClose={handleClose}
          innerRef={(formikActions: FormikProps<PlanValues>) => {
            formikActions && setDirty(formikActions.dirty);
          }}
          dirty={dirty}
          editMode={!!plan?.id}
          initialValues={initialValues}
          initialStep={initialStep}
          expand
        >
          <FormikStep
            label="Define"
            validationSchema={defineStepValidationSchema}
          >
            <LabeledTooltip
              label="All Environments"
              description={`${titlecase(noun)} exist in all environments`}
              className="top-10 right-16"
              position="absolute"
              placement="bottom-center"
            />

            <FormHeader
              label={`${verb} ${noun}`}
              title={`Define ${titlecase(noun)}`}
              description={
                type === PlanType.Plan
                  ? "A company can only have one base plan."
                  : "A company can have multiple add ons. Add ons can only have boolean entitlements."
              }
            />

            <FormColumn>
              <FormRow>
                <DropdownIcons
                  name="icon"
                  label="Icon"
                  sets={{ diamonds: true }}
                />
                <div className="flex-1">
                  <FormikControl
                    control="input"
                    name="name"
                    type="text"
                    label="Name"
                    placeholder="Enter name"
                    description={`A human-friendly name for your ${noun}.`}
                  />
                </div>
              </FormRow>

              <FormikControl
                control="input"
                name="description"
                type="text"
                label="Description"
                placeholder="Enter description"
                description="Optional"
              />
              {apiError && (
                <div className="px-2">
                  <Alert size="xs" style="red">
                    <div className="flex items-center justify-center space-x-2">
                      <div className="text-base font-body ">
                        <span className="font-semibold">Uh-oh!</span> {apiError}
                      </div>
                    </div>
                  </Alert>
                </div>
              )}
            </FormColumn>
          </FormikStep>

          {billingFlag && (
            <FormikStep
              label="Billing"
              validationSchema={billingStepValidationSchema}
            >
              <PlanEditBillingStep
                noun={noun}
                verb={verb}
                apiError={apiError}
                planId={plan?.id}
              />
            </FormikStep>
          )}
          {billingFlag && trialFlag && noun == "plan" && (
            <FormikStep label="Trial">
              <PlanEditTrialStep verb={verb} />
            </FormikStep>
          )}
        </FormikStepper>
      </OverlayModal>
    </Overlay>
  );
};
