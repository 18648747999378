import React from "react";

interface WrapperProps {
  children: React.ReactNode;
}

const Wrapper = ({ children }: WrapperProps) => {
  return (
    <div className="h-[calc(100vh-81px)] max-h-full overflow-y-auto pt-12 pb-12 px-12">
      <div className="max-w-[1300px] mx-auto">
        <div className="flex flex-col relative h-full">{children}</div>
      </div>
    </div>
  );
};

interface ViewWrapperProps extends WrapperProps {
  useStrictMode?: boolean;
}

export const ViewWrapper = ({
  useStrictMode = true,
  ...props
}: ViewWrapperProps) => {
  if (import.meta.env.DEV && useStrictMode) {
    return (
      <React.StrictMode>
        <Wrapper {...props} />
      </React.StrictMode>
    );
  }

  return <Wrapper {...props} />;
};
