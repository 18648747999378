import { get } from "@data/index";
import {
  BillingProductDetailResponseData,
  CountBillingProductsRequest,
  CountBillingProductsResponse,
  CountCustomersResponse,
  ListBillingProductsRequest,
} from "@models/api";

export const listProducts = async (
  flt?: ListBillingProductsRequest,
): Promise<BillingProductDetailResponseData[]> => {
  return get<BillingProductDetailResponseData[], ListBillingProductsRequest>(
    `billing/products`,
    flt,
  );
};

export const countProducts = async (
  flt?: CountBillingProductsRequest,
): Promise<CountCustomersResponse> => {
  return get<CountBillingProductsResponse, CountBillingProductsRequest>(
    `billing/products/count`,
    flt,
  );
};
