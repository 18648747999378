import { BlankStateOnCreate } from "@ui/PageBlankState";
import { TableBlankState } from "@ui/TableBlankState";

export const PlanEntitlementsBlankState = ({
  onCreate,
  disabled,
}: BlankStateOnCreate) => {
  return (
    <TableBlankState
      title="📦 No plan entitlements yet"
      description={
        <>
          Add a feature to this plan to create an entitlement. All companies and
          <br />
          users in this plan's audience will get access to those features.
        </>
      }
      buttonText="Add feature"
      onCreate={onCreate}
      disabled={disabled}
    />
  );
};
