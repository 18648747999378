import { Button } from "../Button";

interface TablePaginationProps {
  table: any;
}

export const TablePagination = ({ table }: TablePaginationProps) => {
  return (
    <div className="table-pagination px-6 py-4 w-full  justify-between flex items-center overflow-hidden max-w-full">
      <div className="space-x-2">
        <Button
          size="sm"
          type="button"
          disabled={!table.getCanPreviousPage()}
          onClick={() => table.previousPage()}
        >
          Previous
        </Button>
        <Button
          size="sm"
          type="button"
          disabled={!table.getCanNextPage()}
          onClick={() => table.nextPage()}
        >
          Next
        </Button>
      </div>
      <div className="pr-2 text-sm">
        Page {table.getState().pagination.pageIndex + 1} of{" "}
        {table.getPageCount()}
      </div>
    </div>
  );
};
