import { useEditor, useNode, type UserComponent } from "@craftjs/core";
import { listFeatures } from "@data/features";
import { useCurrentEnvironment } from "@hooks/useCurrentEnvironment";
import type { FontStyle, ThemeSettings } from "@modules/components/types";
import {
  useEmbed,
  Element,
  MeteredFeatures as EmbedMeteredFeatures,
  type MeteredFeaturesProps,
} from "@schematichq/schematic-components";
import { arrayMove } from "@utils/helpers";
import { titlecase } from "@utils/strings";
import { useEffect, useMemo, useState } from "react";
import { type OnChangeValue } from "react-select";
import * as Settings from "../../controls/Sidebar/Settings";
import {
  Checkbox,
  Select,
  SortableMultiSelect,
  SortableMultiValue,
  SortableMultiValueLabel,
  type SelectOption,
} from "../../ui";

export const MeteredFeatures: UserComponent = () => {
  const {
    connectors: { connect, drag },
    props,
  } = useNode((node) => ({
    props: node.data.props as MeteredFeaturesProps,
  }));

  const { data, settings } = useEmbed();

  const { environment } = useCurrentEnvironment();
  const environmentId = environment?.id;

  const numericFeatures = (data.featureUsage?.features || []).filter(
    ({ feature }) =>
      feature?.featureType === "event" || feature?.featureType === "trait",
  );
  const hasNumericFeatures = numericFeatures.length > 0;
  const hasVisibleFeatures =
    numericFeatures.filter(
      ({ feature }) =>
        feature?.id &&
        (!props.visibleFeatures || props.visibleFeatures.includes(feature.id)),
    ).length > 0;

  if (!hasNumericFeatures || !hasVisibleFeatures) {
    return (
      <Element
        ref={(ref) => connect(drag(ref!))}
        className="relative z-[2] cursor-pointer"
      >
        <div className="flex flex-col justify-center">
          <h1
            className="leading-none mb-4"
            style={{
              fontFamily: settings.theme.typography.heading2.fontFamily,
              fontSize: settings.theme.typography.heading2.fontSize,
              fontWeight: settings.theme.typography.heading2.fontWeight,
              color: settings.theme.typography.heading2.color,
            }}
          >
            No features {hasNumericFeatures ? "selected" : "available"}
          </h1>

          {!hasNumericFeatures && (
            <p
              className="mb-8"
              style={{
                fontFamily: settings.theme.typography.text.fontFamily,
                fontSize: settings.theme.typography.text.fontSize,
                fontWeight: settings.theme.typography.text.fontWeight,
                color: settings.theme.typography.text.color,
              }}
            >
              Create{" "}
              <a
                className="hover:underline"
                href={`/${environmentId}/features`}
                target="_blank"
                style={{
                  fontFamily: settings.theme.typography.link.fontFamily,
                  fontSize: settings.theme.typography.link.fontSize,
                  fontWeight: settings.theme.typography.link.fontWeight,
                  color: settings.theme.typography.link.color,
                }}
              >
                features
              </a>{" "}
              and add them to{" "}
              <a
                className="hover:underline"
                href={`/${environmentId}/plans`}
                target="_blank"
                style={{
                  fontFamily: settings.theme.typography.link.fontFamily,
                  fontSize: settings.theme.typography.link.fontSize,
                  fontWeight: settings.theme.typography.link.fontWeight,
                  color: settings.theme.typography.link.color,
                }}
              >
                plans
              </a>
              .
            </p>
          )}

          <p
            className="text-sm text-[#808080]"
            style={{
              fontFamily: settings.theme.typography.text.fontFamily,
              fontWeight: settings.theme.typography.text.fontWeight,
            }}
          >
            This placeholder will not show to the end-user.
          </p>
        </div>
      </Element>
    );
  }

  return (
    <EmbedMeteredFeatures
      ref={(ref) => connect(drag(ref!))}
      className="relative z-[2] cursor-pointer"
      {...props}
    />
  );
};

const MeteredFeaturesSettings = () => {
  const { theme } = useEditor((state) => {
    return {
      theme: state.nodes.ROOT.data.props.settings.theme as ThemeSettings,
    };
  });

  const {
    actions: { setProp },
    nodeProps,
  } = useNode((node) => ({
    nodeProps: node.data.props as MeteredFeaturesProps,
  }));

  const [featureOptions, setFeatureOptions] = useState<SelectOption[]>([]);

  useEffect(() => {
    listFeatures().then((features) => {
      const options = features.reduce((acc: SelectOption[], feature) => {
        if (
          feature?.featureType === "event" ||
          feature?.featureType === "trait"
        ) {
          acc.push({ label: feature.name, value: feature.id });
        }

        return acc;
      }, []);
      setFeatureOptions([{ label: "Select All", value: "all" }, ...options]);

      if (typeof nodeProps.visibleFeatures === "undefined") {
        setProp((props: MeteredFeaturesProps) => {
          props.visibleFeatures = options.map((feature) => feature.value);
        });
      }
    });
  }, [nodeProps.visibleFeatures, setProp]);

  const typographyOptions: { value: FontStyle; label: string }[] = useMemo(
    () => [
      ...Object.keys(theme.typography).map((key) => ({
        value: key as FontStyle,
        label: titlecase(key),
      })),
    ],
    [theme.typography],
  );

  return (
    <Settings.Root title="🎛️ Metered Features" category="Entitlements">
      <Settings.Section>
        <div className="pb-2 space-y-1">
          <div>Features Displayed</div>
          <div className="text-[0.8125rem] text-[#939393]">
            Show in order below
          </div>
        </div>

        <Settings.Input>
          <SortableMultiSelect
            className="w-full min-h-12"
            useDragHandle
            axis="xy"
            onSortEnd={({ oldIndex, newIndex }) => {
              setProp((props: MeteredFeaturesProps) => {
                props.visibleFeatures = arrayMove(
                  nodeProps.visibleFeatures || [],
                  oldIndex,
                  newIndex,
                );
              });
            }}
            distance={4}
            getHelperDimensions={({ node }) => node.getBoundingClientRect()}
            isMulti
            options={featureOptions}
            value={(nodeProps.visibleFeatures || []).reduce(
              (acc: SelectOption[], id) => {
                const option = featureOptions.find((opt) => opt.value === id);
                if (option) {
                  acc.push(option);
                }

                return acc;
              },
              [],
            )}
            onChange={(selectedOptions: OnChangeValue<SelectOption, true>) => {
              const isAllSelected = selectedOptions.some(
                (opt) => opt.value === "all",
              );

              setProp((props: MeteredFeaturesProps) => {
                props.visibleFeatures = (
                  isAllSelected
                    ? featureOptions.filter((opt) => opt.value !== "all")
                    : selectedOptions
                ).map((option) => option.value);
              });
            }}
            components={{
              MultiValue: SortableMultiValue,
              MultiValueLabel: SortableMultiValueLabel,
            }}
            closeMenuOnSelect={false}
          />
        </Settings.Input>
      </Settings.Section>

      <Settings.Section>
        <Settings.Header
          isVisible={nodeProps.isVisible}
          onVibibilityChange={() => {
            setProp((props: MeteredFeaturesProps) => {
              const updated = !props.isVisible;
              props.isVisible = updated;
            });
          }}
        >
          Feature Meter
        </Settings.Header>

        <Settings.Input label="Title style">
          <Select
            className="w-36"
            isMulti={false}
            options={typographyOptions}
            rawValue={nodeProps.header.fontStyle}
            value={typographyOptions.find(
              (opt) => opt.value === nodeProps.header.fontStyle,
            )}
            onChange={(option) => {
              if (!option) return;
              setProp((props: MeteredFeaturesProps) => {
                props.header.fontStyle = option.value;
              });
            }}
          />
        </Settings.Input>

        <Settings.Input label="Show description">
          <Checkbox
            checked={nodeProps.description.isVisible}
            onChange={() => {
              setProp((props: MeteredFeaturesProps) => {
                const updated = !props.description.isVisible;
                props.description.isVisible = updated;
              });
            }}
          />
        </Settings.Input>

        <Settings.Input label="Description text">
          <Select
            className="w-36"
            isMulti={false}
            options={typographyOptions}
            rawValue={nodeProps.description.fontStyle}
            value={typographyOptions.find(
              (opt) => opt.value === nodeProps.description.fontStyle,
            )}
            onChange={(option) => {
              if (!option) return;
              setProp((props: MeteredFeaturesProps) => {
                props.description.fontStyle = option.value;
              });
            }}
          />
        </Settings.Input>

        <Settings.Input label="Show icon">
          <Checkbox
            checked={nodeProps.icon.isVisible}
            onChange={() => {
              setProp((props: MeteredFeaturesProps) => {
                const updated = !props.icon.isVisible;
                props.icon.isVisible = updated;
              });
            }}
          />
        </Settings.Input>

        <Settings.Input label="Show usage">
          <Checkbox
            checked={nodeProps.usage.isVisible}
            onChange={() => {
              setProp((props: MeteredFeaturesProps) => {
                const updated = !props.usage.isVisible;
                props.usage.isVisible = updated;
              });
            }}
          />
        </Settings.Input>

        <Settings.Input label="Usage text">
          <Select
            className="w-36"
            isMulti={false}
            options={typographyOptions}
            rawValue={nodeProps.usage.fontStyle}
            value={typographyOptions.find(
              (opt) => opt.value === nodeProps.usage.fontStyle,
            )}
            onChange={(option) => {
              if (!option) return;
              setProp((props: MeteredFeaturesProps) => {
                props.usage.fontStyle = option.value;
              });
            }}
          />
        </Settings.Input>

        <Settings.Input label="Show limit">
          <Checkbox
            checked={nodeProps.allocation.isVisible}
            onChange={() => {
              setProp((props: MeteredFeaturesProps) => {
                const updated = !props.allocation.isVisible;
                props.allocation.isVisible = updated;
              });
            }}
          />
        </Settings.Input>

        <Settings.Input label="Limit text">
          <Select
            className="w-36"
            isMulti={false}
            options={typographyOptions}
            rawValue={nodeProps.allocation.fontStyle}
            value={typographyOptions.find(
              (opt) => opt.value === nodeProps.allocation.fontStyle,
            )}
            onChange={(option) => {
              if (!option) return;
              setProp((props: MeteredFeaturesProps) => {
                props.allocation.fontStyle = option.value;
              });
            }}
          />
        </Settings.Input>
      </Settings.Section>
    </Settings.Root>
  );
};

MeteredFeatures.craft = {
  displayName: "Metered Features",
  props: {
    isVisible: true,
    header: {
      fontStyle: "heading2",
    },
    description: {
      isVisible: true,
      fontStyle: "text",
    },
    icon: {
      isVisible: true,
    },
    usage: {
      isVisible: true,
      fontStyle: "heading5",
    },
    allocation: {
      isVisible: true,
      fontStyle: "heading6",
    },
    visibleFeatures: undefined,
  } satisfies MeteredFeaturesProps,
  related: {
    settings: MeteredFeaturesSettings,
  },
};
