import {
  BillingPriceResponseData,
  BillingProductResponseData,
  ListBillingProductsParams,
  ListProductPricesParams,
  SearchBillingPricesParams,
} from "@models/api";
import { BillingPriceDetailResponseData } from "@models/api/models/BillingPriceDetailResponseData";
import { get } from "./index";

export const listBillingProducts = async (
  flt?: ListBillingProductsParams,
): Promise<BillingProductResponseData[]> => {
  return get<BillingProductResponseData[], ListBillingProductsParams>(
    "billing/products",
    flt,
  );
};

export const listBillingProductPrices = async (
  flt: ListProductPricesParams & { billingProductId: string },
): Promise<BillingPriceResponseData[]> => {
  const { billingProductId, ...req } = flt;
  return get<BillingPriceResponseData[], ListProductPricesParams>(
    `billing/products/${billingProductId}/prices`,
    req,
  );
};

export const listBillingPrices = async (
  flt: SearchBillingPricesParams,
): Promise<BillingPriceDetailResponseData[]> => {
  return get<BillingPriceDetailResponseData[], SearchBillingPricesParams>(
    `billing/price`,
    flt,
  );
};
