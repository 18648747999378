import { PlanEntitlement } from "@models/entitlement";
import { ClipCopy } from "@ui/ClipCopy";
import { Icon } from "@ui/Icon";
import { formatCurrency } from "@utils/strings";
import { ReactNode } from "react";

interface HeaderBlockProps {
  title?: string;
  label: string | ReactNode;
}

const HeaderBlock = ({ title, label }: HeaderBlockProps) => {
  return (
    <div className="flex flex-col">
      <div className="pr-8">
        {title && (
          <span className="text-sm text-black font-medium leading-none inline-block mb-2">
            {title}
          </span>
        )}

        <div className="text-gray-400">{label}</div>
      </div>
    </div>
  );
};

export const BillingUsageBasedEntitlementCard = ({
  entitlement,
}: {
  entitlement: PlanEntitlement;
}) => {
  const meteredPrice =
    entitlement.meteredYearlyPrice ?? entitlement.meteredMonthlyPrice!;

  const getPriceBehaviorLabel = (): string => {
    switch (entitlement.priceBehavior) {
      case "pay_in_advance":
        return "Pay In Advance";
      case "pay_as_you_go":
        return "Pay as You Go";
      default:
        return "";
    }
  };

  return (
    <div className="bg-white shadow-[0_1px_15px_0px_rgba(16,24,40,0.07)] rounded-lg">
      {/*Header*/}
      <div className="relative flex justify-between items-center p-4">
        <Icon
          name="stripe"
          className="absolute top-0 right-0 w-6 h-6 text-blue-400 border rounded-full text-center inline-table m-2"
        />

        <HeaderBlock
          title={meteredPrice?.productName}
          label={
            <div className="flex">
              <Icon name="stripe" />
              <ClipCopy data={meteredPrice.priceExternalId!} />
            </div>
          }
        />

        <HeaderBlock
          title={`Price per ${meteredPrice.interval}`}
          label={
            <>
              <span className="block text-center">
                {formatCurrency(meteredPrice.price, meteredPrice.currency)} per
                unit
              </span>
            </>
          }
        />

        {meteredPrice.meterId && (
          <HeaderBlock
            title="MeterID"
            label={
              <a
                href={`https://dashboard.stripe.com/meters/${meteredPrice.meterId}`}
                target="_blank"
                className="text-blue-400 hover:opacity-80 space-x-2"
              >
                <span>See in Stripe</span>
                <Icon name="folder" />
              </a>
            }
          />
        )}

        <HeaderBlock
          title="Price behavior"
          label={
            <>
              <span className="block text-center">
                {getPriceBehaviorLabel()}
              </span>
            </>
          }
        />

        <HeaderBlock
          label={
            <a
              href={`https://dashboard.stripe.com/prices/${meteredPrice.priceExternalId}`}
              target="_blank"
              className="text-blue-400 hover:opacity-80 space-x-2"
            >
              <span>See in Stripe</span>
              <Icon name="folder" />
            </a>
          }
        />
      </div>
      {/*  Body*/}
      <div className="bg-gray-100 px-8 py-4">
        <table className="table-compact w-full">
          <thead>
            <tr>
              <th className="w-full">Prices</th>
              <th>Currency</th>
              <th>Type</th>
            </tr>
          </thead>
          <tbody>
            {entitlement.meteredYearlyPrice && (
              <tr
                key={entitlement.meteredYearlyPrice.priceExternalId}
                className="border-b border-gray-300"
              >
                <td>
                  {formatCurrency(
                    entitlement.meteredYearlyPrice.price,
                    entitlement.meteredYearlyPrice.currency,
                  )}{" "}
                  per {entitlement.meteredYearlyPrice.interval}
                </td>
                <td className="uppercase text-gray-400">
                  {entitlement.meteredYearlyPrice.currency}
                </td>
                <td className="text-gray-400">
                  {entitlement.meteredYearlyPrice.interval
                    ? "Recurring"
                    : "One-time"}
                </td>
              </tr>
            )}
            {entitlement.meteredMonthlyPrice && (
              <tr
                key={entitlement.meteredMonthlyPrice.priceExternalId}
                className="border-b border-gray-300"
              >
                <td>
                  {formatCurrency(
                    entitlement.meteredMonthlyPrice.price,
                    entitlement.meteredMonthlyPrice.currency,
                  )}{" "}
                  per {entitlement.meteredMonthlyPrice.interval}
                </td>
                <td className="uppercase text-gray-400">
                  {entitlement.meteredMonthlyPrice.currency}
                </td>
                <td className="text-gray-400">
                  {entitlement.meteredMonthlyPrice.interval
                    ? "Recurring"
                    : "One-time"}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};
