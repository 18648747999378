import { useUser } from "@clerk/clerk-react";
import { EnvironmentOverlay } from "@components/overlays/EnvironmentOverlay";
import { environmentPillColors } from "@components/overlays/types";
import { EnvironmentTypeShort } from "@consts/environments";
import { useResponsiveness } from "@hooks/useResponsivness";
import { Account, AccountEnvironment } from "@models/account";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import { Button } from "@ui/Button";
import { Icon } from "@ui/Icon";
import { Logo } from "@ui/Logo";
import { Pill, PillStyleTypes } from "@ui/Pill";

import { useEffect, useState } from "react";
import { createPortal } from "react-dom";

interface DropdownEnvironmentProps {
  account: Account;
  handleEnvironmentChange: (environment: AccountEnvironment) => void;
  selectedEnvironment: AccountEnvironment;
  environmentScoped?: boolean;
}

export const DropdownEnvironment = ({
  account,
  handleEnvironmentChange,
  selectedEnvironment,
  environmentScoped = true,
}: DropdownEnvironmentProps) => {
  const { isLarge } = useResponsiveness();
  const { user } = useUser();
  const [logoUrl, setLogoUrl] = useState<string | undefined>(undefined);
  const [envOverlay, setEnvOverlay] = useState(false);
  const [curEnvironment, setCurEnvironment] =
    useState<AccountEnvironment>(selectedEnvironment);

  useEffect(() => {
    setCurEnvironment(selectedEnvironment);
  }, [selectedEnvironment]);

  const handleOverlayOpen = () => {
    setEnvOverlay(true);
  };

  const handleOverlayClose = () => {
    setEnvOverlay(false);
  };

  useEffect(() => {
    if (
      user &&
      user.organizationMemberships.length > 0 &&
      user.organizationMemberships[0].organization.imageUrl
    ) {
      setLogoUrl(user.organizationMemberships[0].organization.imageUrl);
    }
  }, [user]);

  return environmentScoped ? (
    <div className="min-w-[224px]">
      <div className="h-11 w-11 flex items-center justify-center">
        <Logo
          size={!isLarge ? "sm" : "lg"}
          src={logoUrl}
          alt={account.name}
          className="w-full h-full"
        />
      </div>
    </div>
  ) : (
    <>
      <DropdownMenu.Root>
        <DropdownMenu.Trigger
          asChild
          className="rounded-[10px] flex items-center cursor-pointer"
        >
          <button aria-label="Select environment" className="">
            <div className="h-11 w-11 flex items-center justify-center">
              <Logo
                size={!isLarge ? "sm" : "lg"}
                src={logoUrl}
                alt={account.name}
                className="w-full h-full"
              />
            </div>
            <span className="font-body flex flex-col items-start ml-2 mr-3">
              <span className="text-sm lg:text-base leading-none font-medium mr-2">
                {account.name}
              </span>
              <div className="space-x-2 flex items-center">
                <div className="text-sm text-gray-400/80">
                  {curEnvironment.name}
                </div>
                <Pill
                  className="ml-0 !p-0.5 !px-1.5 !text-xs"
                  color={
                    environmentPillColors[
                      curEnvironment.environmentType
                    ] as PillStyleTypes
                  }
                >
                  {EnvironmentTypeShort[curEnvironment.environmentType]}
                </Pill>
              </div>
            </span>
            <Icon name="collapse-empty" className="text-gray-400/50 text-2xl" />
          </button>
        </DropdownMenu.Trigger>

        <DropdownMenu.Portal>
          <DropdownMenu.Content
            side="bottom"
            align="start"
            className="bg-white pt-4 pb-4 shadow-md rounded-lg w-full mt-2 flex flex-col space-y-2 min-w-[340px] cursor-pointer relative"
          >
            <div className=" ">
              {account.environments.map((environment: AccountEnvironment) => (
                <DropdownMenu.Item
                  className="flex justify-between px-4 py-2.5 bg-white hover:bg-gray-100 !outline-none transition-all"
                  key={environment.id}
                  onSelect={() => {
                    handleEnvironmentChange(environment);
                  }}
                >
                  <div className="flex items-center space-x-2">
                    <div className="font-medium leading-none mr-4">
                      {environment.name}
                    </div>
                  </div>
                  <div className="flex items-center">
                    <Pill
                      color={
                        environmentPillColors[
                          environment.environmentType
                        ] as PillStyleTypes
                      }
                    >
                      {EnvironmentTypeShort[environment.environmentType]}
                    </Pill>
                  </div>
                </DropdownMenu.Item>
              ))}
            </div>
            <div className="pt-4 px-4">
              <DropdownMenu.Item className="w-full flex text-center">
                <Button onClick={handleOverlayOpen} className="w-full">
                  Manage Environments
                </Button>
              </DropdownMenu.Item>
            </div>
          </DropdownMenu.Content>
        </DropdownMenu.Portal>
      </DropdownMenu.Root>

      {envOverlay &&
        createPortal(
          <EnvironmentOverlay
            currentEnvironment={curEnvironment}
            setCurrentEnvironment={setCurEnvironment}
            handleEnvironmentChange={handleEnvironmentChange}
            environments={account.environments}
            onClose={handleOverlayClose}
          />,
          document.body!,
        )}
    </>
  );
};
