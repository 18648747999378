/* tslint:disable */
/* eslint-disable */
/**
 * Schematic API
 * Schematic API
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from "../runtime";
/**
 *
 * @export
 * @interface CreatePlanEntitlementRequestBody
 */
export interface CreatePlanEntitlementRequestBody {
  /**
   *
   * @type {string}
   * @memberof CreatePlanEntitlementRequestBody
   */
  featureId: string;
  /**
   *
   * @type {string}
   * @memberof CreatePlanEntitlementRequestBody
   */
  meteredMonthlyPriceId?: string;
  /**
   *
   * @type {string}
   * @memberof CreatePlanEntitlementRequestBody
   */
  meteredYearlyPriceId?: string;
  /**
   *
   * @type {string}
   * @memberof CreatePlanEntitlementRequestBody
   */
  metricPeriod?: CreatePlanEntitlementRequestBodyMetricPeriodEnum;
  /**
   *
   * @type {string}
   * @memberof CreatePlanEntitlementRequestBody
   */
  metricPeriodMonthReset?: CreatePlanEntitlementRequestBodyMetricPeriodMonthResetEnum;
  /**
   *
   * @type {string}
   * @memberof CreatePlanEntitlementRequestBody
   */
  monthlyMeteredPriceId?: string;
  /**
   *
   * @type {string}
   * @memberof CreatePlanEntitlementRequestBody
   */
  planId: string;
  /**
   *
   * @type {string}
   * @memberof CreatePlanEntitlementRequestBody
   */
  priceBehavior?: string;
  /**
   *
   * @type {boolean}
   * @memberof CreatePlanEntitlementRequestBody
   */
  valueBool?: boolean;
  /**
   *
   * @type {number}
   * @memberof CreatePlanEntitlementRequestBody
   */
  valueNumeric?: number;
  /**
   *
   * @type {string}
   * @memberof CreatePlanEntitlementRequestBody
   */
  valueTraitId?: string;
  /**
   *
   * @type {string}
   * @memberof CreatePlanEntitlementRequestBody
   */
  valueType: CreatePlanEntitlementRequestBodyValueTypeEnum;
  /**
   *
   * @type {string}
   * @memberof CreatePlanEntitlementRequestBody
   */
  yearlyMeteredPriceId?: string;
}

/**
 * @export
 */
export const CreatePlanEntitlementRequestBodyMetricPeriodEnum = {
  AllTime: "all_time",
  Billing: "billing",
  CurrentMonth: "current_month",
  CurrentWeek: "current_week",
  CurrentDay: "current_day",
} as const;
export type CreatePlanEntitlementRequestBodyMetricPeriodEnum =
  (typeof CreatePlanEntitlementRequestBodyMetricPeriodEnum)[keyof typeof CreatePlanEntitlementRequestBodyMetricPeriodEnum];

/**
 * @export
 */
export const CreatePlanEntitlementRequestBodyMetricPeriodMonthResetEnum = {
  FirstOfMonth: "first_of_month",
  BillingCycle: "billing_cycle",
} as const;
export type CreatePlanEntitlementRequestBodyMetricPeriodMonthResetEnum =
  (typeof CreatePlanEntitlementRequestBodyMetricPeriodMonthResetEnum)[keyof typeof CreatePlanEntitlementRequestBodyMetricPeriodMonthResetEnum];

/**
 * @export
 */
export const CreatePlanEntitlementRequestBodyValueTypeEnum = {
  Boolean: "boolean",
  Numeric: "numeric",
  Trait: "trait",
  Unlimited: "unlimited",
} as const;
export type CreatePlanEntitlementRequestBodyValueTypeEnum =
  (typeof CreatePlanEntitlementRequestBodyValueTypeEnum)[keyof typeof CreatePlanEntitlementRequestBodyValueTypeEnum];

/**
 * Check if a given object implements the CreatePlanEntitlementRequestBody interface.
 */
export function instanceOfCreatePlanEntitlementRequestBody(
  value: object,
): value is CreatePlanEntitlementRequestBody {
  if (!("featureId" in value) || value["featureId"] === undefined) return false;
  if (!("planId" in value) || value["planId"] === undefined) return false;
  if (!("valueType" in value) || value["valueType"] === undefined) return false;
  return true;
}

export function CreatePlanEntitlementRequestBodyFromJSON(
  json: any,
): CreatePlanEntitlementRequestBody {
  return CreatePlanEntitlementRequestBodyFromJSONTyped(json, false);
}

export function CreatePlanEntitlementRequestBodyFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): CreatePlanEntitlementRequestBody {
  if (json == null) {
    return json;
  }
  return {
    featureId: json["feature_id"],
    meteredMonthlyPriceId:
      json["metered_monthly_price_id"] == null
        ? undefined
        : json["metered_monthly_price_id"],
    meteredYearlyPriceId:
      json["metered_yearly_price_id"] == null
        ? undefined
        : json["metered_yearly_price_id"],
    metricPeriod:
      json["metric_period"] == null ? undefined : json["metric_period"],
    metricPeriodMonthReset:
      json["metric_period_month_reset"] == null
        ? undefined
        : json["metric_period_month_reset"],
    monthlyMeteredPriceId:
      json["monthly_metered_price_id"] == null
        ? undefined
        : json["monthly_metered_price_id"],
    planId: json["plan_id"],
    priceBehavior:
      json["price_behavior"] == null ? undefined : json["price_behavior"],
    valueBool: json["value_bool"] == null ? undefined : json["value_bool"],
    valueNumeric:
      json["value_numeric"] == null ? undefined : json["value_numeric"],
    valueTraitId:
      json["value_trait_id"] == null ? undefined : json["value_trait_id"],
    valueType: json["value_type"],
    yearlyMeteredPriceId:
      json["yearly_metered_price_id"] == null
        ? undefined
        : json["yearly_metered_price_id"],
  };
}

export function CreatePlanEntitlementRequestBodyToJSON(
  value?: CreatePlanEntitlementRequestBody | null,
): any {
  if (value == null) {
    return value;
  }
  return {
    feature_id: value["featureId"],
    metered_monthly_price_id: value["meteredMonthlyPriceId"],
    metered_yearly_price_id: value["meteredYearlyPriceId"],
    metric_period: value["metricPeriod"],
    metric_period_month_reset: value["metricPeriodMonthReset"],
    monthly_metered_price_id: value["monthlyMeteredPriceId"],
    plan_id: value["planId"],
    price_behavior: value["priceBehavior"],
    value_bool: value["valueBool"],
    value_numeric: value["valueNumeric"],
    value_trait_id: value["valueTraitId"],
    value_type: value["valueType"],
    yearly_metered_price_id: value["yearlyMeteredPriceId"],
  };
}
