import { useOrganization } from "@clerk/clerk-react";
import type { OrganizationMembershipResource } from "@clerk/types";
import { useRole } from "@hooks/useRole";
import { PaginationState } from "@hooks/useTablePagination";
import { AccountEnvironment } from "@models/account";
import { ClerkUserPermission, ClerkUserRole } from "@models/clerkUser";
import { ColumnDef } from "@tanstack/react-table";
import { DropdownDots } from "@ui/DropdownDots";
import { Table } from "@ui/Table";
import { User } from "@ui/User";
import React, { useEffect, useMemo, useState } from "react";
import { TeammateServerData } from "../overlays/TeammateOverlay";

type TeamTableProps = {
  environments: AccountEnvironment[];
  onEdit: (teammate: TeammateServerData) => void;
  onDelete: (organizationMembership: OrganizationMembership) => void;
};

export type OrganizationMembership = OrganizationMembershipResource & {
  publicMetadata: {
    email?: string;
    role?: ClerkUserRole;
    permissions?: Record<string, ClerkUserPermission[]>;
  };
};

export const TeamTable = ({
  environments,
  onEdit,
  onDelete,
}: TeamTableProps) => {
  const { isLoaded, memberships } = useOrganization({
    memberships: true,
  });
  const teammateEditAllowed = useRole(ClerkUserRole.Admin);

  const columns = useMemo<ColumnDef<OrganizationMembership>[]>(
    () => [
      {
        id: "name",
        header: "Name",
        accessorKey: "name",
        size: 140,
        cell: (cellInfo) => {
          const { publicUserData, publicMetadata } = cellInfo.row.original;
          const firstName =
            (publicMetadata.firstName as string) || publicUserData.firstName;
          const lastName =
            (publicMetadata.lastName as string) || publicUserData.lastName;

          let displayName = publicUserData.userId;
          if (firstName && lastName) {
            displayName = `${firstName} ${lastName}`;
          } else if (firstName) {
            displayName = firstName;
          } else if (lastName) {
            displayName = lastName;
          }

          return (
            <div className="flex items-center">
              <User image={publicUserData.imageUrl} name={displayName} />
            </div>
          );
        },
      },
      {
        id: "email",
        header: "Email",
        accessorKey: "email",
        size: 200,
        cell: (cellInfo) => {
          const { publicUserData } = cellInfo.row.original;
          return <span>{publicUserData.identifier}</span>;
        },
      },
      {
        id: "permissions",
        header: "Permissions",
        accessorKey: "permissions",
        size: 200,
        cell: (cellInfo) => {
          const { role, publicMetadata } = cellInfo.row.original;
          if (role === ClerkUserRole.Admin) {
            return <span>Admin</span>;
          }

          const existingEnvironmentsIds = environments.map(({ id }) => id);

          const numActive = publicMetadata.permissions
            ? Object.entries(publicMetadata.permissions)
                .filter(([key]) => existingEnvironmentsIds.includes(key))
                .flatMap(([, perms]) => perms).length
            : 0;
          const numAll =
            environments.length * Object.values(ClerkUserPermission).length;

          return (
            <span>
              {numActive} of {numAll}
            </span>
          );
        },
      },
      {
        id: "actions",
        header: "",
        accessorKey: "actions",
        maxSize: 75,
        cell: (cellInfo) => {
          const { publicUserData, publicMetadata, role } =
            cellInfo.row.original;
          const teammate = {
            userId: publicUserData.userId,
            email: publicMetadata.email || publicUserData.identifier,
            firstName:
              (publicMetadata.firstName as string) ||
              publicUserData.firstName ||
              undefined,
            lastName:
              (publicMetadata.lastName as string) ||
              publicUserData.lastName ||
              undefined,
            role: (role as ClerkUserRole) || publicMetadata.role,
            permissions: publicMetadata.permissions || {},
          };

          return (
            <div className="flex flex-row items-end justify-end">
              <DropdownDots
                links={[
                  {
                    label: "Edit",
                    disabled: !teammateEditAllowed,
                    onClick: () => {
                      onEdit(teammate);
                    },
                  },
                  {
                    label: "Remove",
                    disabled: !teammateEditAllowed,
                    onClick: () => {
                      onDelete(cellInfo.row.original);
                    },
                  },
                ]}
              />
            </div>
          );
        },
      },
    ],
    [environments, onDelete, onEdit, teammateEditAllowed],
  );

  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  useEffect(() => {
    memberships?.fetchPage?.(pagination.pageIndex + 1);
  }, [memberships, pagination]);

  if (!isLoaded) {
    return <>Loading</>;
  }

  if (!memberships?.data) {
    return null;
  }

  return (
    <div className="w-full">
      <Table
        columns={columns}
        data={memberships.data}
        pageCount={memberships.pageCount}
        pageIndex={pagination.pageIndex}
        pageSize={pagination.pageSize}
        setPagination={setPagination}
      />
    </div>
  );
};
