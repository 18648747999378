import { TableLoader } from "@components/loaders/TableLoader";
import useTablePagination from "@hooks/useTablePagination";
import {
  EntityKeyDefinitionResponseData,
  ListEntityKeyDefinitionsParams,
} from "@models/api";
import { EntityType } from "@models/entityKey";
import { ColumnDef } from "@tanstack/react-table";
import { Pill } from "@ui/Pill";
import { Table } from "@ui/Table";
import { useMemo, useState } from "react";
import * as api from "../../queries/entityKeys";

type EntityKeyDefinitionsTableProps = {
  entityType: EntityType;
};

export const EntityKeyDefinitionsTable = ({
  entityType,
}: EntityKeyDefinitionsTableProps) => {
  const [filter] = useState<ListEntityKeyDefinitionsParams>({ entityType });
  const columns = useMemo<ColumnDef<EntityKeyDefinitionResponseData>[]>(
    () => [
      {
        id: "keyDefinition",
        header: "Key",
        accessorKey: "keyDefinition",
        cell: (cellInfo) => {
          const key = cellInfo.row.original;
          return <Pill>{key.key}</Pill>;
        },
      },
    ],
    [],
  );

  const { listQuery, pageCount, pageIndex, pageSize, setPagination } =
    useTablePagination<
      EntityKeyDefinitionResponseData,
      ListEntityKeyDefinitionsParams
    >(
      ["entityKeyDefinitions"],
      api.listEntityKeyDefinitions,
      api.countEntityKeyDefinitions,
      filter,
    );

  if (listQuery.error) throw listQuery.error;

  if (listQuery.isLoading) return <TableLoader rows={4} />;

  if (!listQuery.data) return null;

  return (
    <div className="w-full">
      <Table
        columns={columns}
        data={listQuery.data}
        pageCount={pageCount}
        pageIndex={pageIndex}
        pageSize={pageSize}
        setPagination={setPagination}
      />
    </div>
  );
};
