import {
  QuickstartGetStarted,
  QuickstartHowDoYouBill,
  QuickstartIndexRoutePage,
} from "@modules/quickstart/components";
import { QuickstartView } from "@modules/quickstart/components/QuickstartView";
import { QuickstartPage } from "@modules/quickstart/consts";
import { ErrorBoundaryFallback } from "@ui/ErrorBoundaryFallback";
import { RouteObject } from "react-router-dom";

export const quickstartRoutes: RouteObject[] = [
  {
    path: "quickstart",
    element: <QuickstartView />,
    errorElement: <ErrorBoundaryFallback />,
    children: [
      {
        index: true,
        element: <QuickstartIndexRoutePage />,
      },
      {
        path: QuickstartPage.HowDoYouBill,
        element: <QuickstartHowDoYouBill />,
      },
      {
        path: QuickstartPage.GetStarted,
        element: <QuickstartGetStarted />,
      },
    ],
  },
];
