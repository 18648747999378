import {
  CreateOrUpdateConditionRequestBodyConditionTypeEnum,
  CreateOrUpdateConditionRequestBodyOperatorEnum,
  CreateOrUpdateRuleRequestBodyRuleTypeEnum,
  RuleConditionDetailResponseData,
  RuleConditionGroupDetailResponseData,
  RuleDetailResponseData,
} from "@models/api";
import { v4 as uuidv4 } from "uuid";

export const newCondition = (): RuleConditionDetailResponseData => {
  return {
    conditionType: CreateOrUpdateConditionRequestBodyConditionTypeEnum.Company,
    createdAt: new Date(),
    environmentId: "",
    id: "",
    metricValue: 0,
    operator: CreateOrUpdateConditionRequestBodyOperatorEnum.Eq,
    resourceIds: [],
    resources: [],
    ruleId: "",
    traitValue: "",
    updatedAt: new Date(),
  };
};

export const newConditionGroup = (
  existingCondition: RuleConditionDetailResponseData,
): RuleConditionGroupDetailResponseData & { formikId: string } => {
  return {
    environmentId: "",
    id: "",
    formikId: uuidv4(),
    ruleId: "",
    createdAt: new Date(),
    updatedAt: new Date(),
    conditions: [existingCondition],
  };
};

export const prepareRules = (
  rules: RuleDetailResponseData[],
  type: CreateOrUpdateRuleRequestBodyRuleTypeEnum,
) => filterRulesByType(rules, type).map(parseRule);

const filterRulesByType = (
  rules: RuleDetailResponseData[],
  type: CreateOrUpdateRuleRequestBodyRuleTypeEnum,
) => rules.filter((r) => r.ruleType == type);

export const parseRule = ({
  conditions,
  conditionGroups,
  ...rest
}: RuleDetailResponseData) => ({
  ...rest,
  conditionGroups: [
    ...conditions.map((condition) => newConditionGroup(condition)),
    ...conditionGroups,
  ],
});
