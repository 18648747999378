import { updateFlagRules } from "@data/flags";
import { useCurrentEnvironment } from "@hooks/useCurrentEnvironment";
import { usePermission } from "@hooks/usePermission";
import {
  CreateOrUpdateRuleRequestBodyRuleTypeEnum,
  RuleDetailResponseData,
  UpdateFlagRulesRequestBody,
} from "@models/api";
import { ClerkUserPermission } from "@models/clerkUser";
import { FeatureFlag } from "@models/feature";
import { ConvertToFeatureCta } from "@modules/features/components/tabs/FeatureFlagTargetingTab/ConvertToFeatureCta";
import {
  featureConversion,
  FeatureFlagWithRuleGroups,
  newRule,
  pageNotificationChanges,
} from "@modules/features/components/tabs/FeatureFlagTargetingTab/utils";
import { useQueryClient } from "@tanstack/react-query";
import { CollapsableRule } from "@ui/CollapsableRule";
import { PageNotification } from "@ui/PageNotification/PageNotification";
import { PermissionButton } from "@ui/PermissionButton";
import { RuleBlock, RuleBlockDefault } from "@ui/RuleBlock";
import { RuleConditionGroupValidationSchema } from "@ui/RuleBlock/RuleConditionGroupBlock";
import { prepareRules } from "@ui/RuleBlock/utils";
import { Toast } from "@ui/Toast";
import {
  conditionReqForConditionResponse,
  conditionGroupReqForConditionGroupResponse,
} from "@utils/rules";

import { ArrayHelpers, FieldArray, Form, Formik, FormikErrors } from "formik";
import { ReactNode, useEffect, useState } from "react";
import * as Yup from "yup";
import { AssociatedFeature } from "./AssociatedFeature";
import { FeatureOverlay } from "../../overlays/FeatureOverlay";

interface FeatureFlagTargetingTabProps {
  flag: FeatureFlag;
  showFeature?: boolean;
}

export type ApiResponse<T> = {
  success: boolean;
  errors: string[];
  response: T | null;
};

const apiResponseInitialState = {
  success: false,
  errors: [],
  response: null,
};

export type RuleDetailResponseDataWithoutConditions = Omit<
  RuleDetailResponseData,
  "conditions"
>;

const FeatureFlagTargetingValidationSchema = Yup.object().shape({
  standardRules: Yup.array().of(
    Yup.object().shape({
      conditionGroups: Yup.array().of(RuleConditionGroupValidationSchema),
    }),
  ),
});

const parseInitialValues = (flag: FeatureFlag) => {
  return {
    ...flag,
    companyOverrideRules: prepareRules(
      flag.rules,
      CreateOrUpdateRuleRequestBodyRuleTypeEnum.CompanyOverride,
    ),
    planEntitlementRules: prepareRules(
      flag.rules,
      CreateOrUpdateRuleRequestBodyRuleTypeEnum.PlanEntitlement,
    ),
    standardRules: prepareRules(
      flag.rules,
      CreateOrUpdateRuleRequestBodyRuleTypeEnum.Standard,
    ),
    defaultRule: flag.rules.find(
      (r) => r.ruleType == CreateOrUpdateRuleRequestBodyRuleTypeEnum.Default,
    ) || {
      createdAt: new Date(),
      environmentId: "",
      flagId: flag.id,
      id: "",
      name: "Default Rule",
      priority: 0,
      ruleType: CreateOrUpdateRuleRequestBodyRuleTypeEnum.Default,
      updatedAt: new Date(),
      value: flag.defaultValue,
      conditions: [],
      conditionGroups: [],
    },
  };
};

export const FeatureFlagTargetingTab = ({
  flag,
  showFeature = false,
}: FeatureFlagTargetingTabProps) => {
  const queryClient = useQueryClient();
  const [toastOpen, setToastOpen] = useState<boolean>(false);
  const [currentFlag, setCurrentFlag] = useState<FeatureFlag>(flag);
  const [hideBlankState, setHideBlankState] = useState(false);
  const [apiResponse, setApiResponse] = useState<ApiResponse<FeatureFlag>>(
    apiResponseInitialState,
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [convertToFeatureOverlay, setConvertToFeatureOverlay] =
    useState<boolean>(false);
  const [initialValues, setInitialValues] = useState<FeatureFlagWithRuleGroups>(
    parseInitialValues(flag),
  );
  const rulesEditAllowed = usePermission(ClerkUserPermission.flag_rules_edit);

  // Reset apiResponse when on success
  useEffect(() => {
    if (apiResponse.success) {
      setApiResponse(apiResponseInitialState);
    }
  }, [apiResponse]);

  const { environment } = useCurrentEnvironment();

  const onSubmit = async (values: FeatureFlagWithRuleGroups) => {
    setLoading(true);

    const { defaultRule, standardRules } = values;
    const rules = [
      defaultRule,

      // ensure standard rules are prioritized in the order they appear at the time of submission
      ...standardRules.map((rule, idx) => {
        return {
          ...rule,
          priority: idx,
        };
      }),
    ];

    const rulesReq: UpdateFlagRulesRequestBody = {
      rules: rules.map((rule: RuleDetailResponseDataWithoutConditions) => {
        const { conditionGroups, id, name, priority, ruleType, value } = rule;

        return {
          conditions: conditionGroups
            .filter((conditionGroup) => conditionGroup.conditions.length === 1)
            .flatMap((conditionGroups) => conditionGroups.conditions)
            .map(conditionReqForConditionResponse),
          conditionGroups: conditionGroups
            .filter((conditionGroup) => conditionGroup.conditions.length > 1)
            .map(conditionGroupReqForConditionGroupResponse),
          id,
          name,
          priority,
          ruleType: ruleType as CreateOrUpdateRuleRequestBodyRuleTypeEnum,
          value,
        };
      }),
    };

    try {
      const { rules } = await updateFlagRules(currentFlag.id, rulesReq);
      await queryClient.invalidateQueries();

      const updatedFlag = { ...flag, rules };

      setApiResponse({
        success: true,
        errors: [],
        response: updatedFlag,
      });
      setCurrentFlag(updatedFlag);
      setInitialValues(parseInitialValues(updatedFlag));
      setToastOpen(true);
      setLoading(false);

      return updatedFlag;
    } catch (error: any) {
      const updatedErrors = [...apiResponse.errors, error.responseCode];
      setApiResponse({ ...apiResponse, errors: updatedErrors });
      setLoading(false);

      return error;
    }
  };

  return (
    <div className="space-y-12 pb-12">
      <Toast title="Changes saved" open={toastOpen} setOpen={setToastOpen} />
      <div className="text-3xl mb-6 leading-none">Targeting</div>

      <div className="space-y-6">
        <div className="space-y-6">
          {!currentFlag.featureId && (
            <ConvertToFeatureCta
              onConvert={() => setConvertToFeatureOverlay(true)}
            />
          )}

          {showFeature && currentFlag.feature && (
            <AssociatedFeature flag={currentFlag} />
          )}
        </div>

        <div className="space-y-8">
          <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={FeatureFlagTargetingValidationSchema}
          >
            {({
              values,
              dirty,
              resetForm,
              setFieldValue,
              submitForm,
              isValid,
              errors,
            }) => {
              // Reset form to default state
              const onCancel = () => {
                resetForm();
              };

              // Trigger double sticky css class when rules form is dirty
              dirty
                ? document.body.classList.add("double-sticky")
                : document.body.classList.remove("double-sticky");

              const changes = pageNotificationChanges(initialValues, values);
              const standardRuleErrors = (
                typeof errors.standardRules === "string"
                  ? [errors.standardRules]
                  : errors.standardRules || []
              ).filter(Boolean) as FormikErrors<UpdateFlagRulesRequestBody>[];

              return (
                <Form>
                  {dirty && (
                    <PageNotification
                      changes={changes}
                      onCancel={onCancel}
                      onSubmit={submitForm}
                      environment={environment}
                      apiResponse={apiResponse}
                      onResponse={(response) => {
                        response && setCurrentFlag(response);
                        resetForm();
                      }}
                      loading={loading}
                      errors={standardRuleErrors}
                      disabled={!isValid}
                    >
                      <div className="space-y-3 text-base font-body">
                        {changes.map((change: ReactNode, index: number) => (
                          <div key={index}>{change}</div>
                        ))}
                      </div>
                    </PageNotification>
                  )}

                  <div className="space-y-10 mb-8">
                    {values.companyOverrideRules.length > 0 && (
                      <CollapsableRule
                        number={1}
                        title="Company Overrides"
                        ruleCount={values.companyOverrideRules.length}
                      >
                        <FieldArray
                          name="companyOverrideRules"
                          render={() => {
                            return (
                              <div className="space-y-12 mb-8">
                                {values.companyOverrideRules.map(
                                  (
                                    rule: RuleDetailResponseDataWithoutConditions,
                                    index: number,
                                  ) => {
                                    return (
                                      <RuleBlock
                                        field={`companyOverrideRules.${index}`}
                                        key={rule.id}
                                        readonly={true}
                                        conditionGroups={rule.conditionGroups}
                                        ruleValue={rule.value}
                                        setFieldValue={setFieldValue}
                                      />
                                    );
                                  },
                                )}
                              </div>
                            );
                          }}
                        />
                      </CollapsableRule>
                    )}

                    {values.planEntitlementRules.length > 0 && (
                      <CollapsableRule
                        number={values.companyOverrideRules.length > 0 ? 2 : 1}
                        ruleCount={values.planEntitlementRules.length}
                        title="Plan Entitlements"
                      >
                        <FieldArray
                          name="planEntitlementRules"
                          render={() => {
                            return (
                              <div className="space-y-12 mb-8">
                                {values.planEntitlementRules.map(
                                  (
                                    rule: RuleDetailResponseDataWithoutConditions,
                                    index: number,
                                  ) => {
                                    return (
                                      <RuleBlock
                                        field={`planEntitlementRules.${index}`}
                                        key={rule.id}
                                        readonly={true}
                                        conditionGroups={rule.conditionGroups}
                                        ruleValue={rule.value}
                                        setFieldValue={setFieldValue}
                                      />
                                    );
                                  },
                                )}
                              </div>
                            );
                          }}
                        />
                      </CollapsableRule>
                    )}
                  </div>

                  {values.standardRules.length > 0 && (
                    <div className="text-3xl mb-8">Rules</div>
                  )}

                  <FieldArray
                    name="standardRules"
                    render={(arrayHelpers: ArrayHelpers) => {
                      const { remove, push } = arrayHelpers;
                      const handleCreate = () => push(newRule(values));

                      return (
                        <div className="space-y-12 mb-8">
                          {values.standardRules.map(
                            (
                              rule: RuleDetailResponseDataWithoutConditions & {
                                formikId?: string;
                              },
                              index: number,
                            ) => {
                              const handleRemove = () => {
                                remove(index);
                              };

                              return (
                                <RuleBlock
                                  field={`standardRules.${index}`}
                                  key={rule.id || rule.formikId}
                                  readonly={!rulesEditAllowed}
                                  onRemove={handleRemove}
                                  conditionGroups={rule.conditionGroups}
                                  ruleValue={rule.value}
                                  setFieldValue={setFieldValue}
                                />
                              );
                            },
                          )}
                          {values.standardRules.length <= 0 &&
                          !currentFlag.defaultValue &&
                          currentFlag.featureId &&
                          !hideBlankState ? (
                            <div className="rounded-lg w-full relative py-8 pr-8 pl-12 bg-white shadow-[0_1px_15px_0px_rgba(16,24,40,0.07)]">
                              <div className="bg-gray-300 text-blue-400 w-[52px] h-[52px] rounded-full flex items-center justify-center text-sm font-bold font-body tracking-widest absolute left-0 translate-x-[-50%] top-[50%] translate-y-[-50%]">
                                IF
                              </div>
                              <div className="flex justify-between items-center">
                                <div className="text-lg font-medium">
                                  Add first targeting rule
                                </div>
                                <div>
                                  <PermissionButton
                                    type="button"
                                    color="blue"
                                    disabled={!rulesEditAllowed}
                                    onClick={() => {
                                      handleCreate();
                                      setHideBlankState(true);
                                    }}
                                  >
                                    Add new rule
                                  </PermissionButton>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <PermissionButton
                              type="button"
                              color="blue"
                              disabled={!rulesEditAllowed}
                              onClick={handleCreate}
                            >
                              Add new rule
                            </PermissionButton>
                          )}
                        </div>
                      );
                    }}
                  />

                  <RuleBlockDefault
                    rule={values.defaultRule}
                    disabled={!rulesEditAllowed}
                  />
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
      {convertToFeatureOverlay && (
        <FeatureOverlay
          feature={featureConversion(flag)}
          onClose={() => setConvertToFeatureOverlay(false)}
        />
      )}
    </div>
  );
};
