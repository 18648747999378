import { Switch } from "@components/ui/Switch";
import {
  listBillingProductPrices,
  listBillingProducts,
  listBillingPrices,
} from "@data/billing";
import { FormikAsyncSelect } from "@forms/FormikAsyncSelect";
import { FormikSelect } from "@forms/FormikSelect";
import { useContextQuery } from "@hooks/useContextQuery";
import { useCurrentEnvironment } from "@hooks/useCurrentEnvironment";
import {
  BillingPriceResponseData,
  BillingProductResponseData,
} from "@models/api";
import { BillingPriceDetailResponseData } from "@models/api/models/BillingPriceDetailResponseData";
import { Plan } from "@models/plan";
import { PriceOptionLabel } from "@modules/plans/components/overlays/PlanEditOverlay/PriceOptionLabel";
import UsageBasedEntitlementsBlock from "@modules/plans/components/overlays/PlanEditOverlay/UsageBasedEntitlements";
import { getAudience, listPlanEntitlements } from "@modules/plans/queries";
import { EnvironmentCell } from "@modules/settings/components/EnvironmentCell";
import { useSchematicFlag } from "@schematichq/schematic-react";
import { Alert } from "@ui/Alert";
import { BillingPriceOptionLabel } from "@ui/BillingPriceOptionLabel";
import { BillingProductOptionLabel } from "@ui/BillingProductOptionLabel";
import { FormColumn, FormHeader } from "@ui/FormParts";
import { Icon } from "@ui/Icon";
import { useFormikContext } from "formik";
import { ReactNode, useEffect, useState } from "react";
import { Link } from "react-router-dom";

type PlanEditBillingStepProps = {
  verb: string;
  apiError?: string;
  planId?: string;
  noun: string;
};

type BillingProductOption = {
  name: string;
  value: string;
  label: ReactNode;
  resource: BillingProductResponseData;
};

type PriceOption = {
  name: string;
  value: string;
  label: ReactNode;
  resource: BillingPriceResponseData;
};

type FreePriceOption = {
  name: string;
  value: string;
  label: ReactNode;
  resource: BillingPriceDetailResponseData;
};

type PlanType = {
  value: boolean;
  label: string;
};

export const PlanEditBillingStep = ({
  verb,
  apiError,
  planId,
  noun,
}: PlanEditBillingStepProps) => {
  const [hasBaseCharge, setHasBaseCharge] = useState(true);
  const [hasUsageCharge, setHasUsageCharge] = useState(false);
  const [billingType, setBillingType] = useState<boolean | null>(null);

  const {
    setFieldValue,
    values: { billingProduct, isFree, id, monthlyPrice, yearlyPrice },
  } = useFormikContext<Plan>();

  const usageBasedPrices = useSchematicFlag("billing.usagebased", {
    fallback: false,
  });

  useEffect(() => {
    if (usageBasedPrices) {
      setHasBaseCharge(!!billingProduct);
    }
    if (id) {
      setFieldValue("isFree", isFree);
      setBillingType(isFree);
    }
  }, [billingProduct]);

  const { data: entitlements } = useContextQuery({
    queryKey: [`list_plan_entitlement`, id],
    queryFn: () =>
      listPlanEntitlements({
        planId: id,
      }),
    retry: false,
  });

  useEffect(() => {
    if (entitlements && entitlements.length && id) {
      const hasUsageBasedEntitlements = entitlements.filter(
        (e) => e.meteredYearlyPrice || e.meteredMonthlyPrice,
      );

      if (hasUsageBasedEntitlements.length > 0) {
        setHasUsageCharge(true);
      }
    }
  }, [entitlements]);

  const { environment } = useCurrentEnvironment();

  const { data: audienceData, isLoading: audienceIsLoading } = useContextQuery({
    queryKey: ["plan", id, "audience"],
    queryFn: async () => {
      if (!id) return null;

      try {
        return await getAudience(id);
      } catch (error: any) {
        if (error.responseCode === 404) {
          return false;
        }

        throw error;
      }
    },
    retry: (failureCount, error: any) => {
      if (error.responseCode === 404) {
        return false;
      }

      return failureCount < 3;
    },
    enabled: !!planId,
  });

  const [audienceWillChange, setAudienceWillChange] = useState(false);

  const getBillingProductValue = () => {
    return (
      billingProduct && {
        label: <BillingProductOptionLabel product={billingProduct} />,
        name: billingProduct?.name,
        resource: billingProduct,
        value: billingProduct.productId,
      }
    );
  };

  useEffect(() => {
    if (audienceIsLoading || !audienceData) {
      setAudienceWillChange(false);
      return;
    }

    if (billingProduct) {
      setAudienceWillChange(
        audienceData.conditionGroups.length > 0 ||
          audienceData.conditions.length != 1 ||
          audienceData.conditions[0].conditionType != "billing_product" ||
          audienceData.conditions[0].resourceIds.length != 1 ||
          audienceData.conditions[0].resourceIds[0] !=
            billingProduct.productId ||
          audienceData.conditions[0].operator != "eq",
      );

      /* if (
        (monthlyPrice && monthlyPrice?.price > 0) ||
        (yearlyPrice && yearlyPrice?.price > 0)
      ) {
        setFieldValue("planType", "paid");
      } */
    } else {
      setAudienceWillChange(
        audienceData.conditionGroups.length > 0 ||
          audienceData.conditions.length > 0,
      );
    }
  }, [
    audienceData,
    audienceIsLoading,
    billingProduct,
    /* monthlyPrice,
    yearlyPrice,
    setFieldValue, */
  ]);

  const planTypeOptions = [
    { value: true, label: "Free" },
    { value: false, label: "Paid" },
  ];

  return (
    <>
      <FormHeader
        label={`${verb} plan`}
        title="Set pricing"
        description="Monetize your offering with flexible pricing options"
      />

      <FormColumn>
        <Alert size="xs" style="yellow" className=" flex space-x-2 px-4">
          <div className="leading-5">
            This {noun}'s audience will be synchronized with a billing product
            on this environment only.
          </div>

          {environment && (
            <EnvironmentCell
              environment={environment}
              className="flex justify-center text-nowrap text-sm"
            />
          )}
        </Alert>
        {audienceWillChange && (
          <Alert size="xs" style="yellow" className="flex">
            <div className="mr-3">
              <Icon
                name="exclamation-rounded-filled"
                className="text-2xl leading-none text-yellow-300"
              />
            </div>

            <div>
              Changing the billing product will overwrite the currently
              configured audience for this {noun}.
            </div>
          </Alert>
        )}

        {usageBasedPrices && (
          <div className="flex gap-6">
            <FormikSelect
              className="w-auto"
              isClearable={false}
              isMulti={false}
              isSearchable={false}
              options={planTypeOptions}
              label="Type"
              name="type"
              placeholder="Select plan type"
              onChange={async (option: PlanType) => {
                setBillingType(option.value);
                await setFieldValue("isFree", option.value);
              }}
              selectedOption={() => {
                return planTypeOptions.find((o) => o.value == isFree);
              }}
            />
          </div>
        )}

        {!usageBasedPrices && (
          <div>
            <div className="flex justify-between">
              <div>
                <Icon
                  name="stripe"
                  className="w-8 h-8 text-blue-400 text-xl border rounded-full text-center inline-table m-2 ml-0"
                />

                <span className="font-medium text-xl">
                  Sync with Stripe Product
                </span>
              </div>
            </div>
            <div>
              Companies that have this product in Stripe will belong to this{" "}
              {noun} in Schematic.{" "}
              <Link
                to="https://docs.schematichq.com/integrations/stripe"
                target="_blank"
                className="text-blue-400"
              >
                Learn more.
              </Link>
            </div>
          </div>
        )}

        {billingType !== null && isFree && (
          <FormikAsyncSelect
            className="flex-1"
            defaultOptions
            label="Stripe free Stripe Product (Optional)"
            loadOptions={listBillingPrices}
            loadOptionsMappers={{
              requestFilter: {
                price: 0,
                limit: 10,
              },
              mapperFunction: (price): PriceOption => {
                return {
                  name: price.productName,
                  value: price.id,
                  label: <BillingPriceOptionLabel price={price} />,
                  resource: price,
                };
              },
            }}
            name="billingProductId"
            placeholder="Type to select Stripe product..."
            onChange={async (option: FreePriceOption) => {
              console.log("Selected free price option", option);
              if (option?.resource) {
                if (option.resource.interval === "month") {
                  await setFieldValue("monthlyPriceId", option.resource.id);
                } else if (option.resource.interval === "year") {
                  await setFieldValue("yearlyPriceId", option.resource.id);
                }

                await setFieldValue(
                  "billingProductId",
                  option.resource.productId,
                );
              } else {
                await setFieldValue("monthlyPrice", null);
                await setFieldValue("yearlyPrice", null);
              }

              // setFieldValue("billingProduct", option.resource || null);
            }}
            selectedOption={getBillingProductValue()}
          />
        )}

        {billingType !== null && !isFree && (
          <section className="space-y-2">
            <div className="flex gap-6 items-start">
              {usageBasedPrices && (
                <div className="mt-1">
                  <Switch
                    name="has-base-charge"
                    checked={hasBaseCharge}
                    onCheckedChange={() => {
                      setHasBaseCharge((prev) => !prev);
                    }}
                  />
                </div>
              )}

              <div className="grow">
                <header className="flex gap-3 mb-2">
                  <svg
                    className="text-[#ADADAD]"
                    width={29}
                    height={29}
                    viewBox="0 0 29 29"
                  >
                    <path
                      d="M27.8973 20.3296L23.7796 18.7924L15.8919 21.7208C15.4569 21.8658 14.9641 21.9813 14.4997 21.9813C14.0069 21.9813 13.5424 21.8941 13.1074 21.7208L5.21971 18.7924L1.102 20.3296C0.60923 20.504 0.60923 21.1996 1.102 21.4024L13.8324 26.1001C14.2674 26.2451 14.7319 26.2451 15.1669 26.1001L27.8973 21.4024C28.3901 21.1996 28.3901 20.5029 27.8973 20.3296Z"
                      fill="currentColor"
                    />
                    <path
                      d="M27.8973 13.9777L23.7796 12.4405L15.8919 15.3405C15.4569 15.5149 14.9641 15.601 14.4997 15.601C14.0069 15.601 13.5424 15.5138 13.1074 15.3405L5.21971 12.4405L1.102 13.9777C0.60923 14.1522 0.60923 14.8477 1.102 15.0505L13.8324 19.7201C14.2674 19.8945 14.7319 19.8945 15.1669 19.7201L27.8973 15.0224C28.3901 14.8479 28.3901 14.1522 27.8973 13.9777Z"
                      fill="currentColor"
                    />
                    <path
                      d="M1.102 8.67045L13.8324 13.3682C14.0352 13.4554 14.2674 13.4837 14.4997 13.4837C14.7319 13.4837 14.9641 13.4543 15.1669 13.3682L27.8973 8.67045C28.3901 8.496 28.3901 7.77099 27.8973 7.59768L15.1669 2.89997C14.9641 2.81275 14.7319 2.78442 14.4997 2.78442C14.2674 2.78442 14.0352 2.81388 13.8324 2.89997L1.102 7.59768C0.60923 7.77213 0.60923 8.49715 1.102 8.67045ZM14.4997 4.95932L23.142 8.14932L14.4997 11.3393L5.85742 8.14932L14.4997 4.95932Z"
                      fill="currentColor"
                    />
                  </svg>
                  <h1 className="font-body text-2xl font-medium leading-none">
                    Base charge
                  </h1>
                </header>

                <p className="font-body mb-5">
                  Charge companies a recurring fixed fee for this plan
                </p>

                {hasBaseCharge && (
                  <div className="space-y-5 mb-4">
                    <FormikAsyncSelect
                      className="flex-1"
                      defaultOptions
                      label="Stripe product for base charge"
                      loadOptions={listBillingProducts}
                      loadOptionsMappers={{
                        requestFilter: {
                          limit: 10,
                          priceUsageType: "licensed",
                          withZeroPrice: false,
                          withPricesOnly: true,
                        },
                        mapperFunction: (
                          product: BillingProductResponseData,
                        ): BillingProductOption => {
                          return {
                            name: product.name,
                            value: product.productId,
                            label: (
                              <BillingProductOptionLabel product={product} />
                            ),
                            resource: product,
                          };
                        },
                      }}
                      name="billingProductId"
                      placeholder="Type to select Stripe product..."
                      onChange={(option?: BillingProductOption) => {
                        setFieldValue("monthlyPrice", null);
                        setFieldValue("yearlyPrice", null);
                        setFieldValue("billingProduct", option?.resource);
                      }}
                      selectedOption={getBillingProductValue()}
                    />

                    {billingProduct && !isFree && (
                      <div className="flex space-x-4">
                        <FormikAsyncSelect
                          key={`monthly-price-${billingProduct.productId}`}
                          className="flex-1"
                          defaultOptions
                          label="Monthly base charge"
                          description="At least one price required"
                          loadOptions={listBillingProductPrices}
                          loadOptionsMappers={{
                            requestFilter: {
                              billingProductId: billingProduct.productId,
                              limit: 10,
                            },
                            resultsFilter: (price) =>
                              price.interval === "month",
                            mapperFunction: (
                              price: BillingPriceResponseData,
                            ): PriceOption => ({
                              name: price.price?.toString(),
                              value: price.id,
                              label: <PriceOptionLabel price={price} />,
                              resource: price,
                            }),
                          }}
                          name="monthlyPriceId"
                          placeholder="Select price"
                          onChange={async (option: PriceOption) => {
                            await setFieldValue(
                              "monthlyPrice",
                              option?.resource || null,
                            );
                          }}
                          selectedOption={
                            monthlyPrice && {
                              name: monthlyPrice.price?.toString(),
                              value: monthlyPrice.id,
                              label: <PriceOptionLabel price={monthlyPrice} />,
                              resource: monthlyPrice,
                            }
                          }
                        />

                        <FormikAsyncSelect
                          key={`yearly-price-${billingProduct.productId}`}
                          className="flex-1"
                          defaultOptions
                          label="Annual base charge"
                          description="At least one price required"
                          loadOptions={listBillingProductPrices}
                          loadOptionsMappers={{
                            requestFilter: {
                              billingProductId: billingProduct.productId,
                              limit: 10,
                            },
                            resultsFilter: (price) => price.interval === "year",
                            mapperFunction: (
                              price: BillingPriceResponseData,
                            ): PriceOption => ({
                              name: price.price?.toString(),
                              value: price.id,
                              label: <PriceOptionLabel price={price} />,
                              resource: price,
                            }),
                          }}
                          name="yearlyPriceId"
                          placeholder="Select price"
                          onChange={async (option: PriceOption) => {
                            await setFieldValue(
                              "yearlyPrice",
                              option?.resource || null,
                            );
                          }}
                          selectedOption={
                            yearlyPrice && {
                              name: yearlyPrice.price?.toString(),
                              value: yearlyPrice.id,
                              label: <PriceOptionLabel price={yearlyPrice} />,
                              resource: yearlyPrice,
                            }
                          }
                        />
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
            {usageBasedPrices && (
              <div className="flex gap-4 items-start">
                <div className="mt-2.5">
                  <Switch
                    name="has-usage-charge"
                    checked={hasUsageCharge}
                    onCheckedChange={() => {
                      setHasUsageCharge((prev) => !prev);
                    }}
                  />
                </div>

                <div className="grow">
                  <header className="flex gap-1.5 mb-1">
                    <svg
                      className="text-[#ADADAD]"
                      width={43}
                      height={43}
                      viewBox="0 0 43 43"
                    >
                      <path
                        d="M34.3996 27.52C34.3996 27.132 34.2249 26.7659 33.9259 26.5206C33.6253 26.2754 33.2305 26.178 32.8509 26.2552C32.2395 26.3947 31.8078 26.9439 31.8196 27.5721V30.96C31.8196 31.0743 31.7743 31.1834 31.6936 31.2641C31.613 31.3447 31.5038 31.39 31.3896 31.39H26.6596V27.5721C26.6714 26.9439 26.2397 26.3947 25.6283 26.2552C25.2487 26.178 24.854 26.2754 24.5533 26.5206C24.2543 26.7659 24.0796 27.1321 24.0796 27.52V31.39H18.9196V27.5721C18.9314 26.9439 18.4997 26.3947 17.8883 26.2552C17.5087 26.178 17.114 26.2754 16.8133 26.5206C16.5143 26.7659 16.3396 27.1321 16.3396 27.52V31.39H11.6096C11.3728 31.39 11.1796 31.1969 11.1796 30.96V27.5721C11.1914 26.9439 10.7597 26.3946 10.1483 26.2552C9.76869 26.178 9.37395 26.2754 9.0733 26.5206C8.77431 26.7659 8.59961 27.132 8.59961 27.52V30.96C8.59961 32.6229 9.94671 33.97 11.6096 33.97H31.3896C33.0525 33.97 34.3996 32.6229 34.3996 30.96V27.52Z"
                        fill="currentColor"
                      />
                      <path
                        d="M10.7493 20.7643L15.3585 16.1384C15.6675 15.8427 16.1546 15.8427 16.4637 16.1384L20.411 20.0856C21.7262 21.3991 23.8577 21.3991 25.1711 20.0856L31.8193 13.4322V17.9859C31.8025 18.6713 32.303 19.2574 32.9799 19.3499C33.3428 19.3818 33.7022 19.2608 33.9693 19.0139C34.2414 18.772 34.3976 18.4243 34.3993 18.0598V12.0398C34.3993 11.242 34.0818 10.476 33.5174 9.91167C32.9531 9.3473 32.1871 9.02983 31.3893 9.02983H25.4432C24.7579 9.01303 24.1717 9.51358 24.0793 10.1905C24.0424 10.555 24.1616 10.9178 24.4085 11.1882C24.6538 11.4586 25.0031 11.6115 25.3693 11.6098H29.997L23.3406 18.2615C23.0315 18.5571 22.5444 18.5571 22.2354 18.2615L18.3217 14.3478V14.3495C18.0294 14.0471 17.6834 13.8019 17.3021 13.6272C16.8637 13.4324 16.39 13.3316 15.9096 13.3299C15.0177 13.3282 14.1611 13.6826 13.5312 14.3142L8.90528 18.9199C8.40136 19.4239 8.40136 20.2402 8.90528 20.7424C9.41255 21.2497 10.2302 21.2598 10.7493 20.7643Z"
                        fill="currentColor"
                      />
                    </svg>

                    <h1 className="font-body text-2xl font-medium leading-none mt-2">
                      Usage-based charge
                    </h1>
                  </header>

                  <p className="font-body mb-5 ml-1.5">
                    Charge companies based on their usage of features within
                    this plan
                  </p>

                  {hasUsageCharge && (
                    <UsageBasedEntitlementsBlock
                      planId={planId}
                      onEntitlementsChange={async (e) => {
                        console.log("new plan entitlements", e);
                        await setFieldValue("usageBasedEntitlements", e);
                      }}
                    />
                  )}
                </div>
              </div>
            )}
          </section>
        )}

        {apiError && (
          <div className="px-2">
            <Alert size="xs" style="red">
              <div className="flex items-center justify-center space-x-2">
                <div className="text-base font-body ">
                  <span className="font-semibold">Uh-oh!</span> {apiError}
                </div>
              </div>
            </Alert>
          </div>
        )}
      </FormColumn>
    </>
  );
};
