import { useLocalStorage } from "@hooks/useLocalStorage";
import { QuickstartPage } from "@modules/quickstart/consts";
import { Navigate } from "react-router-dom";

export const QuickstartIndexRoutePage = () => {
  const [howDoYouBillViewed] = useLocalStorage(
    "quickstart.howDoYouBillViewed",
    false,
  );

  const to = howDoYouBillViewed
    ? QuickstartPage.GetStarted
    : QuickstartPage.HowDoYouBill;

  return <Navigate to={to} replace />;
};
