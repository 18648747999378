import {
  RuleConditionGroupDetailResponseData,
  CreateOrUpdateConditionRequestBodyConditionTypeEnum,
} from "@models/api";
import { EntityType } from "@models/entityTrait";
import { Icon } from "@ui/Icon";
import { RuleConditionGroupBlock } from "@ui/RuleBlock/RuleConditionGroupBlock";
import { newCondition, newConditionGroup } from "@ui/RuleBlock/utils";
import cx from "classnames";
import { ArrayHelpers, FieldArray } from "formik";

import BooleanSelect from "./BooleanSelect";
import { ConditionLogicalOperator } from "./consts";
import { RuleBlockHeader } from "./RuleBlockHeader";

export interface RuleBlockProps {
  onRemove?: () => void;
  conditionGroups: (RuleConditionGroupDetailResponseData & {
    formikId?: string;
  })[];
  ruleValue: boolean;
  showValue?: boolean;
  showHeader?: boolean;
  field: string;
  permittedConditionTypes?: CreateOrUpdateConditionRequestBodyConditionTypeEnum[];
  permittedTraitEntityType?: EntityType;
  readonly?: boolean;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  setFieldValue?: (field: string, value: any) => void;
}

export const RuleBlock = ({
  onRemove,
  readonly = false,
  conditionGroups,
  ruleValue,
  showValue = true,
  showHeader = true,
  field,
  permittedConditionTypes,
  permittedTraitEntityType,
  setFieldValue,
}: RuleBlockProps) => {
  const styles = cx(
    "rounded-lg w-full relative space-y-6 pb-8 pt-6",
    !readonly && "bg-white shadow-[0_1px_15px_0px_rgba(16,24,40,0.07)]",
    readonly && "border bg-[#FBFBFB] border-[#D9D9D9]",
  );

  const onAddAndCondition =
    (arrayHelpers: ArrayHelpers, index: number) => () => {
      arrayHelpers.insert(index + 1, newConditionGroup(newCondition()));
    };

  const onRemoveConditionGroup =
    (arrayHelpers: ArrayHelpers, index: number) => () => {
      arrayHelpers.remove(index);

      // Remove whole RuleBlock if deleting last condition group
      if (conditionGroups.length === 1) {
        onRemove?.();
      }
    };

  return (
    <div className={styles} key={field}>
      {!readonly && showHeader && (
        <RuleBlockHeader
          onRemove={onRemove}
          field={field}
          readonly={readonly}
        />
      )}

      <FieldArray
        name={`${field}.conditionGroups`}
        render={(arrayHelpers: ArrayHelpers) => (
          <div className="space-y-5">
            {conditionGroups.map((group, groupIndex) => {
              const logicalOperator =
                groupIndex === 0
                  ? ConditionLogicalOperator.IF
                  : ConditionLogicalOperator.AND;

              return (
                <RuleConditionGroupBlock
                  key={group.id || group.formikId}
                  conditions={group.conditions}
                  logicalOperator={logicalOperator}
                  readonly={readonly}
                  field={`${field}.conditionGroups.${groupIndex}`}
                  setFieldValue={setFieldValue}
                  onAddAndCondition={onAddAndCondition(
                    arrayHelpers,
                    groupIndex,
                  )}
                  onRemove={onRemoveConditionGroup(arrayHelpers, groupIndex)}
                  permittedConditionTypes={permittedConditionTypes}
                  permittedTraitEntityType={permittedTraitEntityType}
                  ruleConditionGroupLength={conditionGroups.length}
                />
              );
            })}
          </div>
        )}
      />

      {showValue && (
        <div className="pr-8 pl-16">
          <div className="flex space-x-2 items-center">
            <div className="flex flex-col mr-6">
              <Icon
                name="arrow-curved"
                className="text-4xl text-gray-300 leading-none"
              />
              <span className="uppercase tracking-wide font-bold text-sm text-blue-400 leading-none">
                Serve
              </span>
            </div>

            <BooleanSelect
              disabled={readonly}
              label="Value"
              name={`${field}.value`}
              selected={ruleValue}
            />
          </div>
        </div>
      )}
    </div>
  );
};
