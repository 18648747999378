import { Diamond, DiamondStyleTypes } from "@ui/Diamond";
import { diamondsList } from "@ui/Diamond/consts";
import { Icon } from "@ui/Icon";
import { IconNameTypes } from "@ui/Icon/consts";
import { useField } from "formik";
import { ReactNode, useMemo, useState } from "react";
import Select, {
  components,
  createFilter,
  DropdownIndicatorProps,
  OptionProps,
} from "react-select";
import * as unicodeEmojis from "unicode-emoji";

import iconsList from "../../icons/files/icons.json";

type IconSetsType = "icons" | "diamonds" | "emojis";

type IconSets = {
  [key in IconSetsType]?: boolean;
};

type OptionValue = {
  label: string;
  value: string;
};

export interface IconsSelectProps {
  label?: string | ReactNode;
  name: string;
  sets: IconSets;
}

const IconsSelectDropdown = ({
  children,
  isOpen,
  target,
  onClose,
}: {
  children?: ReactNode;
  isOpen: boolean;
  target: ReactNode;
  onClose: () => void;
}) => (
  <div className="relative select-icons">
    {target}
    {isOpen && (
      <>
        <Menu>{children}</Menu>
        <Blanket onClick={onClose} />
      </>
    )}
  </div>
);

const Menu = (props: JSX.IntrinsicElements["div"]) => (
  <div className="bg-white rounded shadow-lg mt-2 absolute z-20" {...props} />
);

const Blanket = (props: JSX.IntrinsicElements["div"]) => (
  <div className="fixed inset-0 z-10" {...props} />
);

const CustomOption = ({
  children,
  ...props
}: OptionProps<OptionValue, boolean>) => {
  const { onMouseMove, onMouseOver, ...rest } = props.innerProps;
  const newProps = { ...props, innerProps: rest };

  return <components.Option {...newProps}>{children}</components.Option>;
};

const CustomDropdownIndicator = (
  props: DropdownIndicatorProps<OptionValue, any>,
) => {
  return (
    <components.DropdownIndicator {...props}>
      <Icon
        name="search"
        className="text-2xl leading-none mr-2 text-gray-400/60"
      />
    </components.DropdownIndicator>
  );
};

export const DropdownIcons = ({
  sets = {
    icons: false,
    diamonds: false,
    emojis: false,
  },
  name,
  label,
}: IconsSelectProps) => {
  const [field, , helpers] = useField(name);
  const [isOpen, setIsOpen] = useState(false);

  const unicodeEmojisList = useMemo(() => unicodeEmojis.getEmojis(), []);
  const emojisList = useMemo(
    () => unicodeEmojisList.map((e) => e.emoji),
    [unicodeEmojisList],
  );

  const placeholder = useMemo(() => {
    const activeSets = [];
    if (sets?.icons) activeSets.push("icons");
    if (sets?.diamonds) activeSets.push("diamonds");
    if (sets?.emojis) activeSets.push("emojis");
    return `Search ${activeSets.join(", ")}...`;
  }, [sets]);

  const options = useMemo(() => {
    const icons = sets?.icons
      ? Object.keys(iconsList).map((icon) => ({
          label: icon,
          value: icon,
        }))
      : [];
    const diamonds = sets?.diamonds
      ? Object.keys(diamondsList).map((diamond) => ({
          label: diamond,
          value: diamond,
        }))
      : [];
    const emojis = sets?.emojis
      ? unicodeEmojisList.map((emoji) => ({
          label: emoji.keywords.join(", "),
          value: emoji.emoji,
        }))
      : [];

    const combinedOptions = [
      ...(sets?.icons ? [{ label: "Icons", options: icons }] : []),
      ...(sets?.diamonds ? [{ label: "Diamonds", options: diamonds }] : []),
      ...(sets?.emojis ? [{ label: "Emojis", options: emojis }] : []),
    ];

    return combinedOptions.length > 0
      ? combinedOptions
      : [
          { label: "Icons", options: icons },
          { label: "Diamonds", options: diamonds },
          { label: "Emojis", options: emojis },
        ];
  }, [sets, unicodeEmojisList]);

  const formatOptionLabel = (value: OptionValue) => {
    const isOptionDiamond =
      diamondsList[value.value as keyof typeof diamondsList];
    const isOptionIcon = iconsList[value.value as keyof typeof iconsList];
    const isOptionEmoji = emojisList.includes(value.value);

    return (
      <div className="inline-flex items-center justify-center w-full h-full">
        {isOptionDiamond && (
          <span title={`diamond, ${value.label}`}>
            <Diamond style={value.value as DiamondStyleTypes} size="sm" />
          </span>
        )}
        {isOptionIcon && (
          <span title={`icon, ${value.label}`}>
            <Icon
              name={value.value as IconNameTypes}
              className="text-2xl text-gray-400"
            />
          </span>
        )}
        {isOptionEmoji && (
          <span title={`emoji, ${value.label}`}>{value.value}</span>
        )}
      </div>
    );
  };

  const isTargetDiamond =
    diamondsList[field.value as keyof typeof diamondsList];
  const isTargetIcon = iconsList[field.value as keyof typeof iconsList];
  const isTargetEmoji = emojisList.includes(field.value);

  return (
    <IconsSelectDropdown
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      target={
        <div className="flex flex-col">
          {label && (
            <label htmlFor={name} className="label-md">
              {label}
            </label>
          )}
          <div
            onClick={() => setIsOpen((prev) => !prev)}
            className="flex w-11 h-11 items-center justify-center rounded-full overflow-hidden border border-blue-100"
          >
            {isTargetIcon && (
              <Icon
                name={field.value as IconNameTypes}
                className="text-2xl text-black"
              />
            )}
            {isTargetDiamond && (
              <Diamond size="sm" style={field.value as DiamondStyleTypes} />
            )}
            {isTargetEmoji && <span>{field.value}</span>}
          </div>
        </div>
      }
    >
      <Select
        formatOptionLabel={formatOptionLabel}
        components={{
          Option: CustomOption,
          IndicatorSeparator: () => null,
          DropdownIndicator: CustomDropdownIndicator,
        }}
        controlShouldRenderValue={false}
        backspaceRemovesValue={false}
        hideSelectedOptions={false}
        isClearable={false}
        onChange={(value) => {
          value ? helpers.setValue(value.value) : helpers.setValue(null);
          setIsOpen(false);
        }}
        filterOption={createFilter({ ignoreAccents: false })}
        isSearchable
        menuIsOpen
        isMulti={false}
        options={options}
        classNamePrefix="select-icons"
        placeholder={placeholder}
        value={field.value && { label: field.value, value: field.value }}
      />
    </IconsSelectDropdown>
  );
};
