import { FormikAsyncSelect } from "@forms/FormikAsyncSelect";
import { FormikSelect } from "@forms/FormikSelect";
import { FormikSelectSearch } from "@forms/FormikSelectSearch";
import { FormikDatePicker } from "@ui/Datepicker";
import { ControlledInput, Input } from "@ui/Input";
import { TextArea } from "@ui/TextArea";

type controlTypes =
  | "input"
  | "controlled-input"
  | "textarea"
  | "select"
  | "select-search"
  | "creatable-async-select"
  | "radio"
  | "checkbox"
  | "date";

interface FormikControlProps {
  control: controlTypes;
  name: string;
  onChange?: (option: any) => void;
  [key: string]: unknown;
}

export const FormikControl = ({
  control,
  onChange,
  ...rest
}: FormikControlProps) => {
  // Probably needs another case for switch...
  switch (control) {
    case "input":
      return <Input {...rest} />;
    case "controlled-input":
      return (
        <ControlledInput value={rest.value} onChange={onChange!} {...rest} />
      );
    case "textarea":
      return <TextArea {...rest} />;
    case "select":
      return <FormikSelect {...rest} />;
    case "select-search":
      return <FormikSelectSearch onChange={onChange!} {...rest} />;
    case "creatable-async-select":
      return <FormikAsyncSelect creatable={true} {...rest} />;
    case "radio":
      return <>Radio</>; // TODO
    case "checkbox":
      return <>Checkbox</>; // TODO
    case "date":
      return <FormikDatePicker {...rest} />;
    default:
      return null;
  }
};
