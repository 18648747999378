import { TableLoader } from "@components/loaders/TableLoader";
import { usePermission } from "@hooks/usePermission";
import useTablePagination from "@hooks/useTablePagination";
import { ListPlanEntitlementsParams } from "@models/api";
import { ClerkUserPermission } from "@models/clerkUser";
import { PlanEntitlement } from "@models/entitlement";
import { ListFilter } from "@models/req";
import { EntitlementCell } from "@modules/features";
import { PlanEntitlementsTableBlankState } from "@modules/features/components/tables/PlanEntitlementsTableBlankState";
import { PlanEntitlementDeleteOverlay } from "@modules/plans";
import {
  countPlanEntitlements,
  deletePlanEntitlement,
  listPlanEntitlements,
} from "@modules/plans/queries";
import { PlanSubview } from "@modules/plans/types";
import { useQueryClient } from "@tanstack/react-query";
import { ColumnDef } from "@tanstack/react-table";
import { Alert } from "@ui/Alert";
import { ButtonProps } from "@ui/Button";
import { Diamond, DiamondStyleTypes } from "@ui/Diamond";
import { DropdownDots } from "@ui/DropdownDots";
import { PermissionButton } from "@ui/PermissionButton";
import { Table } from "@ui/Table";
import { TableHeader } from "@ui/TableHeader";

import pluralize from "pluralize";
import { useEffect, useMemo, useState } from "react";
import { createPortal } from "react-dom";
import { useNavigate } from "react-router-dom";

export interface PlanEntitlementsTableProps {
  featureId: string;
  openOverlay: (planEntitlement?: PlanEntitlement) => void;
}

export const PlanEntitlementsTable = ({
  featureId,
  openOverlay,
}: PlanEntitlementsTableProps) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [filter, setFilter] = useState<ListPlanEntitlementsParams>({
    featureId,
  });
  const [loading, setLoading] = useState(false);
  const [deleteEntitlement, setDeleteEntitlement] = useState<
    PlanEntitlement | undefined
  >();
  const [successAlert, setSuccessAlert] = useState("");
  const planEntitlementEditAllowed = usePermission(
    ClerkUserPermission.plan_entitlements_edit,
  );

  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const columns = useMemo<ColumnDef<PlanEntitlement>[]>(
    () => [
      {
        id: "plan",
        header: "Plan",
        accessorKey: "plan",
        cell: (cellInfo) => {
          const plan = cellInfo.row.original.plan;
          if (!plan) return <></>;
          return (
            <div className="flex flex-row items-center">
              <Diamond style={plan.icon as DiamondStyleTypes} />
              <div className="flex flex-col ml-4">
                <div
                  title={plan.name}
                  className="font-medium text-[18px] !leading-none truncate-md"
                >
                  {plan.name}
                </div>
                <div className="leading-none text-sm text-gray-400">
                  {plan.id}
                </div>
              </div>
            </div>
          );
        },
      },
      {
        id: "entitlement",
        header: "Entitlement",
        accessorKey: "value",
        cell: (cellInfo) => {
          const entitlement = cellInfo.row.original;
          return <EntitlementCell entitlement={entitlement} />;
        },
      },
      {
        id: "companies",
        header: "Companies",
        accessorKey: "companies",
        cell: (cellInfo) => {
          const plan = cellInfo.row.original.plan;
          if (!plan) return <></>;

          return (
            <div className="flex flex-row items-center">
              {plan.companyCount}
            </div>
          );
        },
      },
      {
        id: "actions",
        header: "",
        accessorKey: "actions",
        cell: (cellInfo) => {
          const entitlement = cellInfo.row.original;
          return (
            <div className="flex flex-row items-end justify-end">
              <DropdownDots
                links={[
                  {
                    label: "Delete",
                    onClick: () => {
                      setSuccessAlert("");
                      setDeleteEntitlement(entitlement);
                    },
                    disabled: !planEntitlementEditAllowed,
                  },
                  {
                    label: "Edit",
                    onClick: () => {
                      setSuccessAlert("");
                      openOverlay(entitlement);
                    },
                    disabled: !planEntitlementEditAllowed,
                  },
                  {
                    label: "View plan",
                    onClick: () => {
                      navigate(
                        `../../plans/${entitlement.planId}/${PlanSubview.Entitlements}`,
                      );
                    },
                  },
                ]}
              />
            </div>
          );
        },
      },
    ],
    [navigate, openOverlay, planEntitlementEditAllowed],
  );

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const onDelete = async () => {
    if (!deleteEntitlement) return;

    setLoading(true);

    try {
      await deletePlanEntitlement(deleteEntitlement.id);

      await queryClient.invalidateQueries();
      setSuccessAlert("Entitlement deleted successfully");

      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(error);
      throw error;
    }
  };

  useEffect(() => {
    setFilter({ featureId, ...(searchTerm ? { q: searchTerm } : {}) });
  }, [featureId, searchTerm]);

  const getHeaderText = (count: number) => {
    return pluralize("Plan Entitlements", count, true);
  };

  const {
    countQuery,
    headerText,
    listQuery,
    pageCount,
    pageIndex,
    pageSize,
    setPagination,
  } = useTablePagination<
    PlanEntitlement,
    ListPlanEntitlementsParams & ListFilter
  >(
    ["plan-entitlements"],
    listPlanEntitlements,
    countPlanEntitlements,
    filter,
    getHeaderText,
  );

  const onRowClick = (row: PlanEntitlement) => {
    openOverlay(row);
  };

  const tableButtons: ButtonProps[] = [
    {
      children: <>Add plan entitlement</>,
      color: "blue",
      onClick: () => openOverlay(),
    },
  ];

  if (listQuery.error) throw listQuery.error;
  if (countQuery.error) throw countQuery.error;

  const noPlanEntitlements = countQuery?.data?.count === 0;
  const isLoading =
    loading || listQuery.isLoading || countQuery.isLoading || !listQuery.data;

  const renderPlanEntitlementsTable = () => {
    if (noPlanEntitlements) {
      return (
        <PlanEntitlementsTableBlankState
          onCreate={openOverlay}
          disabled={!planEntitlementEditAllowed}
        />
      );
    }

    if (isLoading) {
      return <TableLoader />;
    }

    return (
      listQuery?.data && (
        <Table
          columns={columns}
          data={listQuery.data}
          onRowClick={planEntitlementEditAllowed ? onRowClick : undefined}
          pageCount={pageCount}
          pageIndex={pageIndex}
          pageSize={pageSize}
          setPagination={setPagination}
          className="mt-8"
        />
      )
    );
  };

  return (
    <div>
      {successAlert && (
        <div className="mb-4">
          <Alert style="green" size="xs" className="flex">
            {successAlert}
          </Alert>
        </div>
      )}

      {!isLoading && !noPlanEntitlements && (
        <TableHeader
          headerText={headerText}
          searchPlaceholder={"Find plan entitlement"}
          onSearch={handleSearch}
          className="pt-8 !pb-0"
        />
      )}

      {renderPlanEntitlementsTable()}

      {!noPlanEntitlements && (
        <div className="mt-6 flex flex-row space-x-4">
          {tableButtons.map((b, index) => (
            <PermissionButton
              key={index}
              color={b.color}
              onClick={b.onClick}
              disabled={!planEntitlementEditAllowed}
            >
              {b.children}
            </PermissionButton>
          ))}
        </div>
      )}

      {deleteEntitlement &&
        createPortal(
          <PlanEntitlementDeleteOverlay
            entitlement={deleteEntitlement}
            onClose={() => setDeleteEntitlement(undefined)}
            onDelete={onDelete}
          />,
          document.body,
        )}
    </div>
  );
};
