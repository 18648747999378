import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import { Icon } from "@ui/Icon";
import cx from "classnames";
import { ReactNode } from "react";

type DropdownLinkProps = {
  label: ReactNode | string;
  disabled?: boolean;
  onClick: () => void;
};

interface DropdownDotsProps {
  links: DropdownLinkProps[];
  size?: "sm" | "md";
  labelSize?: "sm" | "md";
  color?: "blue" | "gray"; // Colors here represent the hover color ( button is white by default )
}

export const DropdownDots = ({
  links,
  size = "sm",
  labelSize = "md",
  color = "blue",
}: DropdownDotsProps) => {
  const dotsColor = {
    blue: "hover:text-white hover:bg-blue-400 text-black bg-white border-gray-300",
    gray: "hover:text-black hover:bg-gray-300 text-black bg-white border-gray-300",
  };

  const dotsSize = {
    sm: "px-[3px]",
    md: "px-[6px] h-[41px] items-center justify-center flex",
  };

  const labelMap = {
    sm: "text-base leading-none py-0.5",
    md: "text-lg mr-4",
  };

  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger
        asChild
        className="rounded-[10px] flex items-center cursor-pointer"
      >
        <button aria-label="Select action" className="">
          <Icon
            name="dots-vertical"
            className={cx(
              "text-2xl rounded-md border  shadow-sm",
              dotsSize[size],
              dotsColor[color],
            )}
          />
        </button>
      </DropdownMenu.Trigger>

      <DropdownMenu.Portal>
        <DropdownMenu.Content
          side="bottom"
          align="end"
          className="bg-white px-6 py-4 shadow-md rounded-lg w-full mt-2 flex flex-col space-y-2 min-w-[200px] cursor-pointer !z-40"
        >
          <div className="space-y-4">
            {links.map((link, index) => {
              return (
                <DropdownMenu.Item
                  className="outline-none flex text-sm justify-between hover:opacity-70 data-[disabled]:pointer-events-none data-[disabled]:opacity-10"
                  key={index}
                  disabled={link.disabled}
                  onSelect={(event) => {
                    event.stopPropagation();
                    link.onClick();
                  }}
                  onClick={(event) => {
                    event.stopPropagation();
                  }}
                >
                  <div className="flex items-center space-x-2">
                    <div className={labelMap[labelSize]}>{link.label}</div>
                  </div>
                </DropdownMenu.Item>
              );
            })}
          </div>
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
};
