import { BillingSubscriptionView } from "@models/api";

export const SubscriptionPriceDisplay = ({
  subscription,
}: {
  subscription?: BillingSubscriptionView;
}) => {
  const priceFormatter = (value: number): string => {
    const currency =
      subscription?.products[0]?.currency?.toUpperCase() || "USD";
    const priceInterval = subscription?.interval;
    const formatted = new Intl.NumberFormat("en-US", {
      style: "currency",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      currency,
    }).format(value / 100);

    return `${formatted}/${priceInterval}`;
  };

  const price = subscription?.totalPrice
    ? priceFormatter(subscription?.totalPrice)
    : "n/a";

  return <div className="text-sm">{price}</div>;
};
