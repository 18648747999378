import { BlankStateOnCreate, PageBlankState } from "@ui/PageBlankState";

export const AddOnsBlankState = ({
  onCreate,
  disabled,
}: BlankStateOnCreate) => {
  return (
    <PageBlankState
      title="💼 Create your first add on!"
      description={
        <>
          Companies can have as many add ons as they want. <br />
          Tie an add on to a billing product to track and target based on
          subscription.
        </>
      }
      buttons={
        onCreate && [
          {
            color: "blue",
            children: <>Create add on</>,
            disabled,
            onClick: onCreate,
          },
        ]
      }
      type="static"
      className="py-28"
    />
  );
};
