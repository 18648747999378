import {
  CreateOrUpdateConditionGroupRequestBody,
  CreateOrUpdateConditionRequestBody,
  CreateOrUpdateConditionRequestBodyConditionTypeEnum,
  CreateOrUpdateConditionRequestBodyMetricPeriodEnum,
  CreateOrUpdateConditionRequestBodyMetricPeriodMonthResetEnum,
  CreateOrUpdateConditionRequestBodyOperatorEnum,
  RuleConditionDetailResponseData,
  RuleConditionGroupDetailResponseData,
} from "@models/api";

export const conditionReqForConditionResponse = (
  req: RuleConditionDetailResponseData,
): CreateOrUpdateConditionRequestBody => {
  const {
    conditionType,
    metricPeriod,
    metricPeriodMonthReset,
    operator,
    traitValue,
    ...rest
  } = req;
  return {
    ...rest,
    conditionType:
      conditionType as CreateOrUpdateConditionRequestBodyConditionTypeEnum,
    metricPeriod: metricPeriod as
      | CreateOrUpdateConditionRequestBodyMetricPeriodEnum
      | undefined,
    metricPeriodMonthReset: metricPeriodMonthReset as
      | CreateOrUpdateConditionRequestBodyMetricPeriodMonthResetEnum
      | undefined,
    operator: operator as CreateOrUpdateConditionRequestBodyOperatorEnum,
    traitValue: mapTraitValue(traitValue),
  };
};

export const conditionGroupReqForConditionGroupResponse = (
  req: RuleConditionGroupDetailResponseData,
): CreateOrUpdateConditionGroupRequestBody => {
  const { conditions, ...rest } = req;
  return {
    ...rest,
    conditions: conditions.map(conditionReqForConditionResponse),
  };
};

const mapTraitValue = (value?: string | boolean) => {
  if (typeof value === "boolean") {
    return value ? "true" : "false";
  }

  return value;
};
