import {
  ListFlagsParams,
  RulesDetailResponseData,
  UpdateFlagRulesRequestBody,
} from "@models/api";
import { Count } from "@models/count";
import { Delete } from "@models/delete";
import { FeatureFlag, FeatureFlagReq } from "@models/feature";
import { deleteReq, get, post, put } from "./index";

export const countFeatureFlags = async (
  flt?: ListFlagsParams,
): Promise<Count> => {
  return get<Count, ListFlagsParams>("flags/count", flt);
};

export const createFeatureFlag = async (
  req: FeatureFlagReq,
): Promise<FeatureFlag> => {
  return post<FeatureFlag, FeatureFlagReq>("flags", req);
};

export const deleteFeatureFlag = async (id: string): Promise<Delete> => {
  return deleteReq(`flags/${id}`);
};

export const getFeatureFlag = async (id: string): Promise<FeatureFlag> => {
  return get<FeatureFlag>(`flags/${id}`);
};

export const listFeatureFlags = async (
  flt?: ListFlagsParams,
): Promise<FeatureFlag[]> => {
  return get<FeatureFlag[], ListFlagsParams>("flags", flt);
};

export const updateFeatureFlag = async (
  id: string,
  req: FeatureFlagReq,
): Promise<FeatureFlag> => {
  return put<FeatureFlag, FeatureFlagReq>(`flags/${id}`, req);
};

export const updateFlagRules = async (
  id: string,
  req: UpdateFlagRulesRequestBody,
): Promise<RulesDetailResponseData> => {
  return put<FeatureFlag, UpdateFlagRulesRequestBody>(`flags/${id}/rules`, req);
};
