import { ButtonGroup } from "@components/ui/ButtonGroup";
import { Pill } from "@components/ui/Pill";
import { ButtonStyleTypes } from "@ui/Button";

type SelectMatchingCriteriaProps = {
  onSelected: (criteria: string) => void;
};

export const SelectMatchingCriteria = ({
  onSelected,
}: SelectMatchingCriteriaProps) => {
  const actionButtonsMetaObjectMatch = [
    {
      children: "Continue",
      color: "blue" as ButtonStyleTypes,
      onClick: () => {
        onSelected("billing_meta_object");
      },
    },
  ];

  const actionButtonsManualUpsert = [
    {
      children: "Continue",
      color: "blue" as ButtonStyleTypes,
      onClick: () => {
        onSelected("manual_upsert");
      },
    },
  ];

  const choices = [
    {
      title: "Use an ID stored in Stripe Customer metadata",
      description: (
        <>
          A unique identifier you already store in Stripe Customer metadata that
          is also used in your application, e.g. {` `}
          <span>
            <Pill
              text="code"
              type="tag"
              color="gray"
            >{`"metadata": {"company_id": "6573"}`}</Pill>
          </span>
        </>
      ),
      buttons: actionButtonsMetaObjectMatch,
    },
    {
      title: "Use the Customer ID Stripe generates",
      description: (
        <>
          The customer ID that Stripe automatically generates e.g.{" "}
          <Pill
            text="code"
            type="tag"
            color="gray"
          >{`"customer_id": "cus_NffrFeUfNV2Hib"`}</Pill>
        </>
      ),
      buttons: actionButtonsManualUpsert,
    },
  ];
  return (
    <div>
      <div className="mb-8 space-y-2">
        <div className="text-2xl">Set up Stripe integration</div>

        <div>
          Choose one of the options below. Schematic will create or update
          Companies based on your selection, and keep data synchronized. For
          instance, if you already have a Company in Schematic with a key that
          corresponds to the given unique ID, we will simply map data to that
          Company from Stripe; otherwise, we'll create a new Company in
          Schematic.
        </div>
      </div>

      <div className="flex flex-col space-y-4">
        {choices.map((choice: any, index: number) => {
          return (
            <div
              className="rounded-lg shadow-lg p-8 flex justify-start items-center space-x-8"
              key={index}
            >
              <div className="flex flex-col space-y-2 flex-1">
                <div className="text-[21px] text-black">{choice.title}</div>
                {choice.description && (
                  <div className="text-sm">{choice.description}</div>
                )}
              </div>
              <div>
                <ButtonGroup buttons={choice.buttons} />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
