import {
  useSchematicIsPending,
  useSchematicFlag,
} from "@schematichq/schematic-react";
import { Navigate } from "react-router-dom";

export const IndexRoutePage = () => {
  const isPending = useSchematicIsPending();
  const showQuickstart = useSchematicFlag("onboarding", {
    fallback: false,
  });
  if (isPending) {
    return null;
  }

  const to = showQuickstart ? "quickstart" : "features";

  return <Navigate to={to} replace />;
};
