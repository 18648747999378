import { SinglePageLoader } from "@components/loaders/SinglePageLoader";
import { useContextQuery } from "@hooks/useContextQuery";
import { useNavigateEnvironment } from "@hooks/useNavigateEnvironment";
import { countCompanies } from "@modules/companies/queries";
import { countComponents } from "@modules/components/queries";
import { countPlanEntitlements } from "@modules/plans";
import { PlanRoutePaths } from "@modules/plans/consts";
import {
  QuickstartCard,
  QuickStartCardData,
} from "@modules/quickstart/components/QuickstartCard";
import { Navigate } from "react-router-dom";

export const QuickstartGetStarted = () => {
  const navigate = useNavigateEnvironment();

  const { data: companies, isLoading: isCompaniesLoading } = useContextQuery({
    queryKey: [`companies`, `count`],
    queryFn: () => countCompanies(),
    retry: false,
  });

  const { data: planEntitlements, isLoading: isPlanEntitlementsLoading } =
    useContextQuery({
      queryKey: [`plan-entitlements`, `count`],
      queryFn: () => countPlanEntitlements(),
      retry: false,
    });

  const { data: components, isLoading: isComponentsLoading } = useContextQuery({
    queryKey: [`components`, `count`],
    queryFn: () => countComponents(),
    retry: false,
  });

  if (isPlanEntitlementsLoading || isComponentsLoading || isCompaniesLoading) {
    return <SinglePageLoader />;
  }

  if (
    (companies?.count ?? 0) > 0 &&
    (components?.count ?? 0) > 0 &&
    (planEntitlements?.count ?? 0) > 0
  ) {
    return <Navigate to="../../features" replace />;
  }

  const cardsInfo: QuickStartCardData[] = [
    {
      title: "Set up plans, features, and limits",
      bodyText: "Model your business’s pricing and packaging in Schematic",
      imageUrl: "/quickstart/plan-cards.png",
      altText: "Plan cards",
      buttonText: "Continue",
      onClick: () => {
        navigate(`${PlanRoutePaths.Plans}`);
      },
      completed: planEntitlements && planEntitlements.count > 0,
    },
    {
      title: "Build your customer portal in minutes",
      bodyText:
        "Drag and drop portal builder to unlock upgrades and transparency.",
      imageUrl: "/quickstart/component.png",
      altText: "Component view",
      buttonText: "Continue",
      onClick: () => {
        navigate("components");
      },
      completed: components && components.count > 0,
    },
    {
      title: "Install Schematic",
      bodyText: "Integrate the API, add companies, send events, set up flags.",
      imageUrl: "/quickstart/code.png",
      altText: "Schematic integration code",
      buttonText: "Go to Docs",
      buttonColor: "white",
      onClick: () => {
        window.open("https://docs.schematichq.com/quickstart", "_blank");
      },
      completed: companies && companies.count > 0,
    },
  ];

  return (
    <div className="space-y-16 relative">
      <div className="absolute w-full h-full">
        <img
          className="opacity-10 w-[1000px] ml-[30%] mt-[-27%]"
          src="/quickstart/schematic-background.png"
          alt=""
        />
      </div>
      <div>
        <div className="flex flex-col items-center space-y-2 mt-8">
          <h1 className="text-3xl font-medium font-display">
            Get started with Schematic
          </h1>
          <h5 className="text-base font-body">Where do you want to start?</h5>
        </div>
      </div>
      <div className="relative flex space-x-4 justify-center">
        {cardsInfo.map((card) => (
          <QuickstartCard key={card.title} data={card} />
        ))}
      </div>
    </div>
  );
};
