import { Error } from "@ui/Error";
import { Select } from "@ui/Select";

import cx from "classnames";
import { useField } from "formik";

export interface FormikSelectProps {
  label?: string;
  name: string;
  options?: any;
  placeholder?: string;
  className?: string;
  selectClassName?: string;
  selectedOption?: any;
  disabled?: boolean;
  [key: string]: unknown;
}

export const FormikSelect = ({
  label,
  name,
  placeholder,
  options,
  className,
  selectClassName,
  selectedOption,
  disabled = false,
  ...rest
}: FormikSelectProps) => {
  const [, meta, helpers] = useField(name);
  const { value, error, touched } = meta;

  const defaultValue = (options: any, value: any) => {
    return options ? options.find((o: any) => o.value === value) : "";
  };

  return (
    <div className={cx("w-full", className)}>
      <Select
        name={name}
        label={label}
        value={defaultValue(options, value)}
        onChange={(value) => helpers.setValue(value?.value)}
        options={options}
        placeholder={placeholder}
        onBlur={() => helpers.setTouched(true)}
        selectedOption={selectedOption}
        disabled={disabled}
        className={selectClassName}
        isClearable
        {...rest}
      />
      {error && touched && <Error>{error}</Error>}
    </div>
  );
};
