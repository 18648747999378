import cx from "classnames";

import { IconNameTypes, IconProps } from "./consts";
import iconsList from "../../icons/files/icons.json";

export const Icon = ({ name, className }: IconProps) => {
  const styles = cx(`i i-${name}`, className);
  return <i className={styles} />;
};

interface IconRoundProps {
  name: IconNameTypes | string;
  style?: "outline" | "filled";
  size?: "xs" | "sm" | "md" | "lg";
  className?: string;
}

export const IconRound = ({
  name,
  style = "filled",
  size = "md",
  className,
}: IconRoundProps) => {
  const styleMap = {
    filled: "bg-gray-200 border-gray-200",
    outline: "bg-transparent border-gray-300",
  };

  const textMap = {
    xs: "text-2xl",
    sm: "text-3xl",
    md: "text-4xl",
    lg: "",
  };

  const sizeMap = {
    xs: "w-[30px] h-[30px]",
    sm: "w-[40px] h-[40px]",
    md: "w-[50px] h-[50px]",
    lg: "",
  };

  const styles = cx(
    "rounded-full flex items-center justify-center border",
    styleMap[style],
    sizeMap[size],
    className,
  );

  const iconNamesSet = new Set<IconNameTypes>(
    Object.keys(iconsList) as IconNameTypes[],
  );

  const isIconName = (value: string): value is IconNameTypes => {
    return iconNamesSet.has(value as IconNameTypes);
  };

  return (
    <div className={styles}>
      {isIconName(name) ? <Icon name={name} className={textMap[size]} /> : name}
    </div>
  );
};
