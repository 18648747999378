import { EntityTraitLabel } from "@components/ui/RuleBlock/EntityTraitLabel";
import { FormikAsyncSelect } from "@forms/FormikAsyncSelect";
import { FormikControl } from "@forms/FormikControl";
import {
  CreateOrUpdateConditionRequestBodyMetricPeriodEnum,
  CreateOrUpdateConditionRequestBodyMetricPeriodMonthResetEnum,
  EntityTraitDefinitionResponseData,
} from "@models/api";
import { EntitlementValueType } from "@models/entitlement";
import { EntityTraitType, EntityType } from "@models/entityTrait";
import {
  ConditionMetricPeriodDisplay,
  ConditionMetricPeriodMonthResetDisplay,
  ConditionMetricPeriods,
  ConditionMetricPeriodMonthResets,
  FeatureType,
} from "@models/feature";
import { FeatureTypeCell } from "@modules/features/components/FeatureTypeCell";
import { MetricPeriodDisplay } from "@modules/plans/consts";
import { listEntityTraitDefinitions } from "@modules/settings/queries/entityTraits";
import { useSchematicFlag } from "@schematichq/schematic-react";
import { Alert } from "@ui/Alert";
import { FormColumn, FormRow } from "@ui/FormParts";
import { IconRound } from "@ui/Icon";
import { IconNameTypes } from "@ui/Icon/consts";
import { Switch } from "@ui/Switch";
import { FormikErrors } from "formik";
import pluralize from "pluralize";
import { FormValues } from "./PlanEntitlementEditOverlay";

type EntitlementFeatureBlockProps = {
  values: any;
  name?: string;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean,
  ) => Promise<void | FormikErrors<any>>;
};

export const EntitlementFeatureBlock = ({
  values,
  name = "",
  setFieldValue,
}: EntitlementFeatureBlockProps) => {
  const stripeIntegrationFlag = useSchematicFlag("stripe-integration-flag", {
    fallback: true,
  });

  const metricPeriodOptions = ConditionMetricPeriods.filter((period) => {
    if (period === CreateOrUpdateConditionRequestBodyMetricPeriodEnum.Billing) {
      return stripeIntegrationFlag;
    }
    return true;
  }).map((period) => {
    return {
      value: period,
      label: ConditionMetricPeriodDisplay[period],
    };
  });

  const metricPeriodMonthResetOptions = ConditionMetricPeriodMonthResets.filter(
    (period) => {
      if (
        period ===
        CreateOrUpdateConditionRequestBodyMetricPeriodMonthResetEnum.BillingCycle
      ) {
        return stripeIntegrationFlag;
      }
      return true;
    },
  ).map((period) => {
    return {
      value: period,
      label: ConditionMetricPeriodMonthResetDisplay[period],
    };
  });

  const loadOptionsMappers = {
    requestFilter: {
      entityType: EntityType.Company,
      traitType: EntityTraitType.Number,
    },
    mapperFunction: (trait: EntityTraitDefinitionResponseData) => ({
      value: trait,
      label: <EntityTraitLabel entityTrait={trait} />,
    }),
    resultsFilter: (trait: EntityTraitDefinitionResponseData) => !!trait.id,
  };

  const valueToText = (values: FormValues) => {
    if (!values.feature) return "";

    if (values.valueType === EntitlementValueType.Numeric) {
      return (
        <span>
          <span className="font-bold">
            {(values.valueNumeric ?? 0).toLocaleString()}{" "}
            {pluralize(values.feature.name, values.valueNumeric ?? 0)}
          </span>{" "}
          {values.metricPeriod &&
            MetricPeriodDisplay[
              values.metricPeriod as CreateOrUpdateConditionRequestBodyMetricPeriodEnum
            ]}
        </span>
      );
    }

    if (values.valueType === EntitlementValueType.Unlimited) {
      return (
        <span className="font-bold">
          Unlimited {pluralize(values.feature.name)}
        </span>
      );
    }

    if (
      values.valueType === EntitlementValueType.Boolean &&
      !values.valueBool
    ) {
      return (
        <span className="font-bold">No {pluralize(values.feature.name)}</span>
      );
    }

    return <span className="font-bold">{pluralize(values.feature.name)}</span>;
  };

  return (
    <>
      {values.feature && values.plan && (
        <>
          <div className="h-px w-full bg-gray-300 my-12" />

          <FormRow className="px-12 justify-between items-center">
            <div className="flex flex-row items-center font-bold text-gray-500">
              <IconRound
                name={values.feature.icon as IconNameTypes}
                size="xs"
              />
              <div className="flex flex-col ml-3 space-y-1">
                <div className="leading-none font-medium">
                  {values.feature.name}
                </div>
              </div>
            </div>
            <FeatureTypeCell featureType={values.feature.featureType} />
          </FormRow>

          <FormColumn className="px-12 space-y-4 mt-6">
            <div>
              {values.feature.featureType === FeatureType.Boolean && (
                <FormRow>
                  <div className="min-w-[25%] flex items-center">
                    <Switch
                      name={`${name}valueBool`}
                      label={values.valueBool ? "On" : "Off"}
                      labelPlacement="right"
                      checked={!!values.valueBool}
                      onCheckedChange={async (checked) => {
                        await setFieldValue(`${name}valueBool`, checked);
                      }}
                    />
                  </div>

                  <Alert size="xs" style="yellow">
                    {values.plan.companyCount} Companies in{" "}
                    <span className="font-bold">{values.plan.name}</span> Plan
                    get access to {valueToText(values)}
                  </Alert>
                </FormRow>
              )}

              {values.feature.featureType === FeatureType.Trait && (
                <FormRow>
                  <FormikControl
                    control="select"
                    label="Type"
                    name={`${name}valueType`}
                    options={[
                      {
                        label: "Numerical",
                        value: EntitlementValueType.Numeric,
                      },
                      {
                        label: "No limit",
                        value: EntitlementValueType.Unlimited,
                      },
                      {
                        label: "Trait",
                        value: EntitlementValueType.Trait,
                      },
                    ]}
                  />
                  {values.valueType === EntitlementValueType.Numeric && (
                    <FormikControl
                      control="input"
                      label="Value"
                      name={`${name}valueNumeric`}
                      type="number"
                    />
                  )}
                  {values.valueType === EntitlementValueType.Trait && (
                    <FormikAsyncSelect
                      label="Trait"
                      name={`${name}trait`}
                      defaultOptions
                      loadOptions={listEntityTraitDefinitions}
                      loadOptionsMappers={loadOptionsMappers}
                      selectedOption={
                        values.trait && {
                          value: values.trait.id,
                          label: (
                            <EntityTraitLabel entityTrait={values.trait} />
                          ),
                        }
                      }
                      onChange={async (option) => {
                        await setFieldValue(
                          `${name}valueTraitId`,
                          option?.value.id,
                        );
                      }}
                    />
                  )}
                </FormRow>
              )}

              {values.feature.featureType === FeatureType.Event && (
                <>
                  <div className="flex flex-row flex-grow space-x-6">
                    <div className="min-w-fit flex-1">
                      <FormikControl
                        control="select"
                        label="Type"
                        name={`${name}valueType`}
                        className="min-w-fit"
                        options={[
                          {
                            label: "Numerical",
                            value: EntitlementValueType.Numeric,
                          },
                          {
                            label: "No limit",
                            value: EntitlementValueType.Unlimited,
                          },
                          {
                            label: "Trait",
                            value: EntitlementValueType.Trait,
                          },
                        ]}
                      />
                    </div>
                    {values.valueType === EntitlementValueType.Numeric && (
                      <div className="min-w-[92px] max-w-[112px]">
                        <FormikControl
                          control="input"
                          label="Value"
                          name={`${name}valueNumeric`}
                          type="number"
                          className="max-w-[10]"
                        />
                      </div>
                    )}
                    {values.valueType === EntitlementValueType.Trait && (
                      <div className="min-w-fit flex-1 max-w-[50%]">
                        <FormikAsyncSelect
                          label="Trait"
                          name={`${name}trait`}
                          defaultOptions
                          loadOptions={listEntityTraitDefinitions}
                          loadOptionsMappers={loadOptionsMappers}
                          selectedOption={
                            values.trait && {
                              value: values.trait,
                              label: (
                                <EntityTraitLabel entityTrait={values.trait} />
                              ),
                            }
                          }
                          onChange={async (option) => {
                            await setFieldValue(
                              `${name}valueTraitId`,
                              option?.value.id,
                            );
                          }}
                        />
                      </div>
                    )}
                  </div>

                  {values.valueType !== EntitlementValueType.Unlimited && (
                    <div className="flex flex-col mt-6 space-y-6">
                      <FormRow>
                        <div className="text-xl">Define reset</div>
                      </FormRow>
                      <FormRow>
                        <div>When does this entitlement reset</div>
                      </FormRow>
                      <FormRow>
                        <FormikControl
                          control="select"
                          name={`${name}metricPeriod`}
                          label="Period"
                          options={metricPeriodOptions}
                        />

                        {values.metricPeriod ===
                          CreateOrUpdateConditionRequestBodyMetricPeriodEnum.CurrentMonth && (
                          <FormikControl
                            control="select"
                            name="metricPeriodMonthReset"
                            label="Date"
                            options={metricPeriodMonthResetOptions}
                          />
                        )}
                      </FormRow>
                    </div>
                  )}
                </>
              )}
            </div>

            {values.feature.featureType !== FeatureType.Boolean && (
              <Alert size="xs" style="yellow">
                {values.plan.companyCount} Companies in{" "}
                <span className="font-bold">{values.plan.name}</span> Plan get
                access to {valueToText(values)}
              </Alert>
            )}
          </FormColumn>
        </>
      )}
    </>
  );
};
