import { usePermission } from "@hooks/usePermission";
import { ClerkUserPermission } from "@models/clerkUser";
import { Feature } from "@models/feature";
import { PlanEntitlementEditOverlay } from "@modules/plans";
import { TableBlankState } from "@ui/TableBlankState";
import { useState } from "react";

interface FeatureCompaniesBlankStateProps {
  feature: Feature;
}

export const FeatureCompaniesBlankState = ({
  feature,
}: FeatureCompaniesBlankStateProps) => {
  const [planEntitlementOverlay, setPlanEntitlementOverlay] = useState(false);
  const onPlanEntitlementOverlayClose = () => {
    setPlanEntitlementOverlay(false);
  };

  const planEntitlementEditAllowed = usePermission(
    ClerkUserPermission.plan_entitlements_edit,
  );

  return (
    <>
      <TableBlankState
        title="⛔️ No companies have access yet"
        description={
          <>
            Create plan entitlements or company overrides to begin
            <br />
            giving companies access to this features.
          </>
        }
        buttonText="Add entitlement"
        onCreate={() => setPlanEntitlementOverlay(true)}
        disabled={!planEntitlementEditAllowed}
      />

      {planEntitlementOverlay && (
        <PlanEntitlementEditOverlay
          onClose={onPlanEntitlementOverlayClose}
          feature={feature}
        />
      )}
    </>
  );
};
