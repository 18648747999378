import { type EmbedContextProps } from "@schematichq/schematic-components";

export enum ViewportName {
  Desktop = "desktop",
  Tablet = "tablet",
  Mobile = "mobile",
}

export type ViewportNameType = `${ViewportName}`;

export type FontStyle =
  keyof EmbedContextProps["settings"]["theme"]["typography"];
export type ThemeSettings = EmbedContextProps["settings"]["theme"];
