import { TableLoader } from "@components/loaders/TableLoader";
import { Pill } from "@components/ui/Pill";
import { useNavigateEnvironment } from "@hooks/useNavigateEnvironment";
import useTablePagination from "@hooks/useTablePagination";
import { CompanyDetailResponseData } from "@models/api";
import { FeatureUsage } from "@models/usage";
import { EntitlementUsageCell } from "@modules/features";
import { CompanyPlanEntitlementsTableBlankState } from "@modules/features/components/tables/PlanEntitlementsTableBlankState";
import {
  countFeatureUsage,
  listFeatureUsage,
  FeatureUsageFilter,
} from "@modules/features/queries/usage";
import { ColumnDef } from "@tanstack/react-table";
import { IconRound } from "@ui/Icon";
import { IconNameTypes } from "@ui/Icon/consts";
import { Table } from "@ui/Table";
import { TableHeader } from "@ui/TableHeader";
import pluralize from "pluralize";
import React, { useEffect, useState } from "react";
import { CompanyEntitlementCell } from "../CompanyEntitlementCell";

export interface CompanyFeaturesTableProps {
  company: CompanyDetailResponseData;
}

export const CompanyFeaturesTable = ({
  company,
}: CompanyFeaturesTableProps) => {
  const navigate = useNavigateEnvironment();

  const [searchTerm, setSearchTerm] = useState("");
  const [filter, setFilter] = useState<FeatureUsageFilter>({});

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  useEffect(() => {
    setFilter({
      q: searchTerm === "" ? undefined : searchTerm,
    });
  }, [searchTerm]);

  const columns: ColumnDef<FeatureUsage>[] = [
    {
      id: "feature",
      header: "Feature",
      accessorKey: "feature",
      cell: (cellInfo) => {
        const feature = cellInfo.row.original.feature;
        if (!feature) return <></>;

        return (
          <div className="flex flex-row items-center">
            <IconRound
              size="sm"
              name={feature.icon as IconNameTypes | string}
            />
            <div className="flex flex-col ml-3 space-y-1">
              <div className="leading-none font-medium">{feature.name}</div>
              <div className="leading-none text-gray-400">{feature.id}</div>
            </div>
          </div>
        );
      },
    },
    {
      id: "entitlement",
      header: "Entitlement",
      accessorKey: "entitlement",
      cell: (cellInfo) => {
        return <CompanyEntitlementCell usage={cellInfo.row.original} />;
      },
    },
    {
      id: "plan",
      header: "plan",
      cell: (cellInfo) => {
        const entitlementType = cellInfo.row.original.entitlementType;
        if (entitlementType == "company_override") {
          return (
            <Pill
              color="blue"
              text="normal"
              type="rounded"
              className="truncate-md"
            >
              Override
            </Pill>
          );
        }

        const planName = cellInfo.row.original.plan?.name;
        if (!planName) return <></>;

        return (
          <Pill text="normal" type="rounded" className="truncate-md">
            {planName}
          </Pill>
        );
      },
    },
    {
      id: "usage",
      header: "Usage",
      cell: (cellInfo) => {
        return <EntitlementUsageCell featureUsage={cellInfo.row.original} />;
      },
    },
  ];

  const getHeaderText = (count: number) => {
    return pluralize("Feature", count, true);
  };

  const {
    countQuery,
    headerText,
    listQuery,
    pageCount,
    pageIndex,
    pageSize,
    setPagination,
  } = useTablePagination<FeatureUsage, FeatureUsageFilter>(
    ["feature-usage", company.id, searchTerm],
    listFeatureUsage(company.id),
    countFeatureUsage(company.id),
    filter,
    getHeaderText,
  );

  const onRowClick = (row: FeatureUsage) =>
    navigate(`features/${row.feature?.id}`);

  if (listQuery.error) throw listQuery.error;
  if (countQuery.error) throw countQuery.error;

  const noFeatureUsage = countQuery?.data?.count === 0;
  const loading =
    listQuery.isLoading || countQuery.isLoading || !listQuery.data;

  const renderCompanyFeaturesTable = () => {
    if (noFeatureUsage) {
      return (
        <CompanyPlanEntitlementsTableBlankState
          onCreate={() => navigate(`plans/`)}
        />
      );
    }

    if (loading) {
      return <TableLoader />;
    }

    return (
      listQuery?.data && (
        <Table
          columns={columns}
          data={listQuery.data}
          pageCount={pageCount}
          pageIndex={pageIndex}
          pageSize={pageSize}
          setPagination={setPagination}
          onRowClick={onRowClick}
        />
      )
    );
  };

  return (
    <div>
      {!noFeatureUsage && (
        <TableHeader
          headerText={headerText}
          className="pt-8"
          onSearch={handleSearch}
          searchPlaceholder="Search features"
        />
      )}

      {renderCompanyFeaturesTable()}
    </div>
  );
};
