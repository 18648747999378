import * as ReactToast from "@radix-ui/react-toast";
import cx from "classnames";
import React from "react";
import { Icon } from "../Icon";

interface ToastProps {
  title: string | React.ReactNode;
  description?: string | React.ReactNode;
  color?: "white" | "blue";
  open: boolean;
  setOpen: (open: boolean) => void;
  position?: "bottom-right" | "center" | "builder-top-right";
  duration?: number;
  close?: boolean;
}

export const Toast = ({
  title,
  description,
  color = "white",
  open,
  setOpen,
  position = "bottom-right",
  duration = 5000,
  close = false,
}: ToastProps) => {
  const positionMap = {
    "bottom-right": "fixed bottom-0 right-0",
    center: "absolute left-1/2 -translate-x-1/2",
    "builder-top-right": "fixed top-[10%] right-6",
  };

  const colorMap = {
    white: "bg-white border border-gray-400/20 py-3 px-6",
    blue: "bg-blue-200 px-6 py-6",
  };

  return (
    <ReactToast.Provider>
      <ReactToast.Root
        className={cx(
          "rounded-lg shadow-md items-center flex flex-col relative transition-all",
          colorMap[color],
          close && "pr-20",
          open ? "animate-toastEnter" : "animate-toastLeave",
        )}
        open={open}
        onOpenChange={setOpen}
        duration={duration}
      >
        <ReactToast.Title className="font-medium text-base">
          {title}
        </ReactToast.Title>
        {description && (
          <ReactToast.Description className="m-0 text-xs" asChild>
            {description}
          </ReactToast.Description>
        )}

        {close && (
          <div
            onClick={() => setOpen(false)}
            className="hover:opacity-70 cursor-pointer"
          >
            <Icon
              className="text-3xl leading-none absolute top-[50%] translate-y-[-50%] right-6"
              name="close"
            />
          </div>
        )}
      </ReactToast.Root>
      <ReactToast.Viewport
        className={cx(
          positionMap[position],
          "flex flex-col p-6 gap-2.5 max-w-full m-0 list-none z-50 outline-none",
        )}
      />
    </ReactToast.Provider>
  );
};
