import { Alert } from "@components/ui/Alert";
import { FormikSelect } from "@forms/FormikSelect";
import { Elevate } from "@ui/Elevate";

export const PlanChangeRules = () => {
  return (
    <Elevate className="flex flex-col">
      <div className="flex flex-col space-y-2 pb-8">
        <div className="text-xl">Plan Change Rules</div>
        <div className="text-sm">
          These rules apply when companies make plan changes using a component
        </div>
      </div>
      <div className="flex py-6">
        <div className="flex flex-col space-y-2 pr-8 min-w-[260px]">
          <div className="text-lg">On upgrade</div>
          <div className="text-sm  whitespace-nowrap text-gray-400">
            Switching to a higher cost plan
          </div>
        </div>
        <div className="flex flex-col w-full">
          <FormikSelect
            disabled
            label="Billing Rule"
            name="upgradeRule"
            options={[
              {
                label: "Upgrade immediately, prorate next bill",
              },
            ]}
            selectClassName="z-[1]"
          />
          <div className="relative -mt-4">
            <Alert style="gray" size="sm" className="w-full space-y-10 pt-12">
              <div className="flex flex-col space-y-2">
                <div className="font-semibold text-sm">
                  Entitlement Behaviour
                </div>
                <div>Increase limits immediately.</div>
              </div>
            </Alert>
          </div>
        </div>
      </div>
      <div className="-mx-8  w-[calc(100%_+4rem)] h-[1px] bg-gray-300 my-2" />
      <div className="flex pt-6 ">
        <div className="flex flex-col space-y-2 pr-8 min-w-[260px]">
          <div className="text-lg">On downgrade</div>
          <div className="text-sm whitespace-nowrap text-gray-400">
            Switching to a lower cost plan
          </div>
        </div>
        <Alert style="gray" size="sm" className="w-full space-y-10">
          <div className="flex flex-col space-y-2">
            <div className="font-semibold text-sm">Billing Behaviour</div>
            <div>Downgrade immediately, provide credits for unused time.</div>
          </div>
          <div className="flex flex-col space-y-2">
            <div className="font-semibold text-sm">Entitlement Behaviour</div>
            <div>
              Require user to be compliant with new limits before allowing
              downgrade.
            </div>
          </div>
        </Alert>
      </div>
    </Elevate>
  );
};
