import { ViewWrapper } from "@ui/ViewWrapper";
import { Outlet } from "react-router-dom";

export const QuickstartView = () => {
  return (
    <ViewWrapper>
      <Outlet />
    </ViewWrapper>
  );
};
