import { PlanEntitlement } from "@models/entitlement";
import { Feature } from "@models/feature";
import {
  CompanyOverridesTable,
  PlanEntitlementsTable,
} from "@modules/features";
import { PlanEntitlementEditOverlay } from "@modules/plans";
import { useState } from "react";
import { Navigate, useOutletContext } from "react-router-dom";
import { FeatureSubview } from "../../types";

export const FeatureEntitlementsTab = () => {
  const { feature } = useOutletContext<{ feature: Feature }>();
  const flag = feature.flags[0];
  const [planEntitlementOverlay, setPlanEntitlementOverlay] = useState(false);
  const [planEntitlement, setPlanEntitlement] = useState<PlanEntitlement>();

  const onPlanEntitlementOverlayClose = () => {
    setPlanEntitlement(undefined);
    setPlanEntitlementOverlay(false);
  };

  const openPlanEntitlementOverlay = (planEntitlement?: PlanEntitlement) => {
    if (planEntitlement) setPlanEntitlement(planEntitlement);
    setPlanEntitlementOverlay(true);
  };

  if (!flag) {
    return <Navigate to={FeatureSubview.Usage} replace />;
  }

  return (
    <div className="space-y-8 pb-16">
      <PlanEntitlementsTable
        featureId={feature.id}
        openOverlay={openPlanEntitlementOverlay}
      />
      {planEntitlementOverlay && (
        <PlanEntitlementEditOverlay
          onClose={onPlanEntitlementOverlayClose}
          feature={feature}
          planEntitlement={planEntitlement}
        />
      )}
      <CompanyOverridesTable feature={feature} />
    </div>
  );
};
