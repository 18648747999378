import { TableLoader } from "@components/loaders/TableLoader";
import { usePermission } from "@hooks/usePermission";
import useTablePagination from "@hooks/useTablePagination";
import { ListWebhooksParams, WebhookResponseData } from "@models/api";
import { ClerkUserPermission } from "@models/clerkUser";
import { WebhookStatus } from "@models/webhook";
import { pillColorWebhookStatus } from "@modules/settings/consts";
import {
  countWebhooks,
  listWebhooks,
} from "@modules/settings/queries/webhooks";
import { ColumnDef } from "@tanstack/react-table";
import { DropdownDots } from "@ui/DropdownDots";
import { Pill } from "@ui/Pill";
import { Table } from "@ui/Table";
import { formatDate } from "@utils/date";
import pluralize from "pluralize";
import React, { useMemo, useState } from "react";
import { WebhooksBlankState } from "../blanks/WebhooksBlankState";
import { WebhookDeleteOverlay } from "../overlays/WebhookDeleteOverlay";
import { WebhookDetailsOverlay } from "../overlays/WebhookDetailsOverlay";
import { WebhookEditOverlay } from "../overlays/WebhookEditOverlay";

type WebhooksTableProps = {
  filter: ListWebhooksParams;
  onCreate?: () => void;
};

export const WebhooksTable = ({ filter, onCreate }: WebhooksTableProps) => {
  const [viewOverlay, setViewOverlay] = useState<string | null>(null);
  const [editOverlay, setEditOverlay] = useState<WebhookResponseData | null>();
  const [deleteOverlay, setDeleteOverlay] =
    useState<WebhookResponseData | null>();

  const webhookEditAllowed = usePermission(ClerkUserPermission.webhooks_edit);

  const columns = useMemo<ColumnDef<WebhookResponseData>[]>(() => {
    return [
      {
        id: "name",
        header: "Name",
        accessorKey: "name",
        size: 160,
        cell: (cellInfo) => {
          const name = cellInfo.row.original.name;
          return (
            <div className="truncate max-w-[160px] text-base" title={name}>
              {name}
            </div>
          );
        },
      },
      {
        id: "url",
        header: "URL",
        accessorKey: "url",
        cell: (cellInfo) => {
          const url = cellInfo.row.original.url;
          return (
            <div className="truncate max-w-[160px] text-base" title={url}>
              {url}
            </div>
          );
        },
      },
      {
        id: "status",
        header: "Status",
        accessorKey: "status",
        size: 120,
        cell: (cellInfo) => {
          const status = cellInfo.row.original.status;
          return (
            <Pill color={pillColorWebhookStatus(status as WebhookStatus)}>
              {status}
            </Pill>
          );
        },
      },
      {
        id: "triggers",
        header: "Listening for",
        size: 160,
        cell: (cellInfo) => {
          const webhook = cellInfo.row.original;

          const triggersCount = webhook.requestTypes.length || 0;

          return <>{pluralize("trigger", triggersCount, true)}</>;
        },
      },
      {
        id: "createdAt",
        header: "Created",
        accessorKey: "createdAt",
        cell: (cellInfo) => {
          const webhook = cellInfo.row.original;
          return <>{formatDate(webhook.createdAt)}</>;
        },
      },
      {
        id: "actions",
        header: "",
        maxSize: 75,
        cell: (cellInfo) => {
          const webhook = cellInfo.row.original;

          return (
            <div className="flex flex-row items-end justify-end">
              <DropdownDots
                links={[
                  {
                    label: "View",
                    onClick: () => {
                      setViewOverlay(webhook.id);
                    },
                    disabled: !webhookEditAllowed,
                  },
                  {
                    label: "Edit",
                    onClick: () => {
                      setEditOverlay(webhook);
                    },
                    disabled: !webhookEditAllowed,
                  },
                  {
                    label: "Delete",
                    onClick: () => {
                      setDeleteOverlay(webhook);
                    },
                    disabled: !webhookEditAllowed,
                  },
                ]}
              />
            </div>
          );
        },
      },
    ];
  }, [webhookEditAllowed]);

  const onRowClick = (row: WebhookResponseData) => {
    setViewOverlay(row.id);
  };

  const {
    countQuery,
    listQuery,
    pageCount,
    pageIndex,
    pageSize,
    setPagination,
  } = useTablePagination<WebhookResponseData, ListWebhooksParams>(
    ["webhooks"],
    listWebhooks,
    countWebhooks,
    filter,
  );

  const noWebhooksCreated = countQuery?.data?.count === 0;

  const handleCreate = () => {
    onCreate && onCreate();
  };

  const renderWebhooksTable = () => {
    switch (true) {
      case noWebhooksCreated:
        return (
          <WebhooksBlankState
            onCreate={handleCreate}
            disabled={!webhookEditAllowed}
          />
        );
      case listQuery.isLoading:
        return <TableLoader rows={4} />;
      default:
        return (
          listQuery?.data && (
            <Table
              columns={columns}
              data={listQuery.data}
              onRowClick={webhookEditAllowed ? onRowClick : undefined}
              pageCount={pageCount}
              pageIndex={pageIndex}
              pageSize={pageSize}
              setPagination={setPagination}
            />
          )
        );
    }
  };

  if (listQuery.error) throw listQuery.error;
  // if (!listQuery.data) return null;

  return (
    <div className="w-full">
      {renderWebhooksTable()}

      {viewOverlay && (
        <WebhookDetailsOverlay
          webhookId={viewOverlay}
          onClose={() => {
            setViewOverlay(null);
          }}
          onEdit={(webhook: WebhookResponseData) => {
            setEditOverlay(webhook);
          }}
        />
      )}

      {editOverlay && (
        <WebhookEditOverlay
          webhook={editOverlay}
          onClose={() => {
            setEditOverlay(null);
          }}
        />
      )}

      {deleteOverlay && (
        <WebhookDeleteOverlay
          webhook={deleteOverlay}
          onClose={() => {
            setDeleteOverlay(null);
          }}
        />
      )}
    </div>
  );
};
