import {
  CompanyOverrideResponseData,
  CreateCompanyOverrideRequestBodyValueTypeEnum,
  CreateOrUpdateConditionRequestBodyMetricPeriodEnum,
  CreateOrUpdateConditionRequestBodyMetricPeriodMonthResetEnum,
  CreatePlanEntitlementRequestBodyValueTypeEnum,
  PlanEntitlementResponseData,
} from "@models/api";
import { Feature } from "@models/feature";
import { Plan } from "@models/plan";

export type CompanyOverride = CompanyOverrideResponseData & {
  feature?: Feature;
  metricPeriod?: CreateOrUpdateConditionRequestBodyMetricPeriodEnum;
  metricPeriodMonthReset?: CreateOrUpdateConditionRequestBodyMetricPeriodMonthResetEnum;
  valueType: CreateCompanyOverrideRequestBodyValueTypeEnum;
};

export type PlanEntitlement = PlanEntitlementResponseData & {
  feature?: Feature;
  plan?: Plan;
  metricPeriod?: CreateOrUpdateConditionRequestBodyMetricPeriodEnum;
  metricPeriodMonthReset?: CreateOrUpdateConditionRequestBodyMetricPeriodMonthResetEnum;
  valueType: CreatePlanEntitlementRequestBodyValueTypeEnum;
};

export enum EntitlementValueType {
  Boolean = "boolean",
  Numeric = "numeric",
  Unlimited = "unlimited",
  Trait = "trait",
}
