import { FormikControl } from "@forms/FormikControl";
import {
  EntityTraitDefinitionResponseData,
  EntityTraitValue,
} from "@models/api";
import { EntityTraitType } from "@models/entityTrait";
import { listEntityTraitDefinitionValues } from "@modules/settings/queries/entityTraits";

import CurrencyInput from "react-currency-input-field";

import BooleanSelect from "./BooleanSelect";

interface TraitValueInputProps {
  trait: EntityTraitDefinitionResponseData;
  name: string;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  setFieldValue?: (field: string, value: any) => void;
  label: string;
  value?: string;
  disabled?: boolean;
}

// The TraitValue type is string, but unfortunately there are some cases where it can be converted
// to a date type over the wire; in this case we need to convert it back so that React doesn't get
// upset with us. We should get rid of this automatic date conversion in the future, this is a bandaid.
const handleDateValue = (value: string) => {
  const isDate = (value as any) instanceof Date;
  const label = isDate ? (value as unknown as Date).toISOString() : value;
  return {
    value: label,
    label,
  };
};

const TraitValueInput = ({
  label,
  name,
  setFieldValue,
  trait,
  value,
  disabled = false,
}: TraitValueInputProps) => {
  if (trait?.traitType === EntityTraitType.Currency) {
    return (
      <div className="flex flex-col">
        {label && (
          <label htmlFor={name} className="label-md">
            {label}
          </label>
        )}

        <CurrencyInput
          allowNegativeValue={false}
          className="rounded-lg px-4 border border-gray-300 h-[44px] max-w-[150px] "
          decimalsLimit={2}
          defaultValue={value ? parseFloat(value) : 0}
          id={name}
          name={name}
          prefix="$"
          disabled={disabled}
          onValueChange={(value, name) => {
            if (!name || !setFieldValue) return;

            setFieldValue(
              name,
              value ? (parseFloat(value as string) * 100).toString() : "0",
            );
          }}
        />
      </div>
    );
  }

  if (trait?.traitType === EntityTraitType.Boolean) {
    return (
      <BooleanSelect
        disabled={disabled}
        label={label}
        name={name}
        selected={value === "true"}
      />
    );
  }

  // Keep key to re-render component, so it would call loadOptions on trait change
  return (
    <FormikControl
      key={trait?.id}
      control="creatable-async-select"
      className="!min-w-fit max-w-[150px]"
      label={label}
      name={name}
      disabled={disabled}
      defaultOptions
      loadOptions={listEntityTraitDefinitionValues}
      loadOptionsMappers={{
        requestFilter: { id: trait.id },
        mapperFunction: (traitValue: EntityTraitValue) =>
          handleDateValue(traitValue.value),
      }}
      selectedOption={value && handleDateValue(value)}
      onCreate={(option: string) => {
        return {
          label: option,
          value: option,
        };
      }}
      formatCreateLabel={(inputValue: string) => inputValue}
    />
  );
};

export default TraitValueInput;
