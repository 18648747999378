import SchematicOverlayLoader from "@components/loaders/SchematicOverlayLoader";
import { LabeledTooltip } from "@components/ui/LabeledTooltip";
import { deleteFeature } from "@data/features";
import { errorMessage } from "@data/index";
import { useNavigateEnvironment } from "@hooks/useNavigateEnvironment";
import { Feature, FeatureFlag } from "@models/feature";
import { ColumnDef } from "@tanstack/react-table";
import { Alert } from "@ui/Alert";
import { ButtonProps } from "@ui/Button";
import { ButtonGroup } from "@ui/ButtonGroup";
import { Icon } from "@ui/Icon";
import { MiniObject } from "@ui/MiniObject";
import { Overlay, OverlayModal } from "@ui/Overlay";
import { timeAgo } from "@utils/date";
import { useMemo, useState } from "react";

type FeatureDeleteOverlayProps = {
  onClose: () => void;
  onDelete: () => void;
  feature: Feature;
};

export const FeatureDeleteOverlay = ({
  onClose,
  onDelete,
  feature,
}: FeatureDeleteOverlayProps) => {
  const navigate = useNavigateEnvironment();
  const [apiError, setApiError] = useState<string | undefined>();
  const [loading, setLoading] = useState(false);

  const miniObjectColumns = useMemo<ColumnDef<FeatureFlag>[]>(() => {
    const flag = feature.flags[0];
    if (!flag) {
      return [];
    }

    const variations = [
      ...new Set(
        [
          flag.defaultValue,
          ...(flag.rules || []).map((rule) => rule.value),
        ].filter((v) => typeof v !== "undefined"),
      ),
    ];

    return [
      {
        id: "flag_name",
        header: "Flag Name",
        accessorKey: "flag_name",
        cell: () => {
          return (
            <div className="flex flex-row items-center">
              <div className="flex flex-col ml-3 space-y-1">
                <div className="leading-none font-medium">{flag.name}</div>
                <div className="leading-none text-gray-400">{flag.key}</div>
              </div>
            </div>
          );
        },
      },
      {
        id: "variations",
        header: "Variations",
        accessorKey: "variations",
        cell: () => {
          return (
            <>
              {variations.map((variation, i) => (
                <span className="pr-1" key={i}>
                  {variation ? "🟢" : "🔴"}
                </span>
              ))}
            </>
          );
        },
      },
      {
        id: "last_checked",
        header: "Last Checked",
        accessorKey: "last_checked",
        cell: () => {
          return (
            <div>
              {flag.lastCheckedAt ? timeAgo(flag.lastCheckedAt) : "Never"}
            </div>
          );
        },
      },
    ];
  }, [feature.flags]);

  const handleDelete = async () => {
    setLoading(true);
    try {
      await deleteFeature(feature.id);
      setApiError(undefined);
      onClose();
      onDelete();
      navigate("features");
    } catch (error) {
      setApiError(errorMessage(error));
    }
    setLoading(false);
  };

  const actionButtons: ButtonProps[] = [
    {
      children: "Cancel",
      onClick: onClose,
    },
    {
      children: "Delete feature",
      color: "blue",
      onClick: handleDelete,
    },
  ];

  return (
    <Overlay onClose={onClose} className="flex items-center justify-center">
      {loading && <SchematicOverlayLoader />}
      <OverlayModal size="md">
        <LabeledTooltip
          label="All Environments"
          description="Features exist in all environments"
          className="right-14 top-8"
          position="absolute"
          placement="bottom-center"
        />

        <div
          onClick={onClose}
          className="inline-flex absolute z-[500] top-6 right-6 hover:cursor-pointer text-black/50 hover:text-blue-400"
        >
          <Icon name="close" className="text-3xl" />
        </div>

        <div className="p-12 space-y-8">
          <div className="mb-8 space-y-2">
            <div className="text-2xl">Delete feature?</div>
            <div className="text-gray-400">
              Remove features you no longer need.
            </div>
          </div>

          <Alert style="yellow" size="xs" className="flex">
            <div className="mr-3">
              <Icon
                name="exclamation-rounded-filled"
                className="text-2xl leading-none text-yellow-300"
              />
            </div>
            <div>
              Deleting this feature will remove any plan and company override
              entitlements associated with this feature.
            </div>
          </Alert>

          {feature.flags.length && (
            <>
              <Alert style="yellow" size="xs" className="flex">
                <div className="mr-3">
                  <Icon
                    name="exclamation-rounded-filled"
                    className="text-2xl leading-none text-yellow-300"
                  />
                </div>
                <div>
                  The associated flag will not be deleted but will lose all
                  targeting associated with this feature.
                </div>
              </Alert>

              <MiniObject columns={miniObjectColumns} size="sm" />
            </>
          )}

          {apiError && (
            <div className="px-2">
              <Alert size="xs" style="red">
                <div className="flex items-center justify-center space-x-2">
                  <div className="text-base font-body ">
                    <span className="font-semibold">Uh-oh!</span> {apiError}
                  </div>
                </div>
              </Alert>
            </div>
          )}

          <div className="flex justify-end">
            <ButtonGroup buttons={actionButtons} />
          </div>
        </div>
      </OverlayModal>
    </Overlay>
  );
};
