import { Button } from "@ui/Button";
import { Elevate } from "@ui/Elevate";
import { Icon } from "@ui/Icon";
import cx from "classnames";
import React from "react";

export type QuickStartCardData = {
  title: string;
  bodyText: string;
  imageUrl: string;
  altText: string;
  buttonText: string;
  buttonColor?: "white" | "blue";
  onClick?: () => void;
  completed?: boolean;
};

type QuickstartCardProps = {
  data: QuickStartCardData;
};

export const QuickstartCard = ({
  data: {
    title,
    bodyText,
    imageUrl,
    altText,
    buttonText,
    buttonColor = "blue",
    onClick,
    completed,
  },
}: QuickstartCardProps) => {
  const opaqueStyle = completed ? "opacity-40" : "";
  return (
    <Elevate className="flex flex-col items-center max-w-xs space-y-4 justify-between">
      <h3 className={cx("text-xl font-medium font-display", opaqueStyle)}>
        {title}
      </h3>
      <h5 className={cx("text-sm font-body font-normal", opaqueStyle)}>
        {bodyText}
      </h5>
      <div
        className={cx(
          "flex h-60 overflow-hidden justify-center items-center",
          opaqueStyle,
        )}
      >
        <img src={imageUrl} className="w-full" alt={altText} />
      </div>
      {!completed && (
        <Button color={buttonColor} type="button" onClick={onClick}>
          {buttonText}
        </Button>
      )}
      {completed && (
        <div className="flex justify-center">
          <Icon
            name="check-rounded"
            className="text-4xl text-green-300 leading-none"
          />
        </div>
      )}
    </Elevate>
  );
};
