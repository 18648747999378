import { BillingPriceDetailResponseData } from "@models/api/models/BillingPriceDetailResponseData";
import { Icon } from "@ui/Icon";

interface BillingPriceOptionLabelProps {
  price: BillingPriceDetailResponseData;
}

export const BillingPriceOptionLabel = ({
  price,
}: BillingPriceOptionLabelProps) => {
  return (
    <div className="flex items-center space-x-4 justify-between">
      <div>{price.productName}</div>
      <span className="stripe-label-id leading-none text-xs text-gray-400 flex items-center justify-center">
        <Icon
          name="stripe"
          className="text-[#6772E5] bg-gray-200 border border-gray-300 rounded-full w-4 h-4 inline-flex justify-center items-center mr-2"
        />
        <span>{price.externalPriceId}</span>
      </span>
    </div>
  );
};
