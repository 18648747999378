import SchematicOverlayLoader from "@components/loaders/SchematicOverlayLoader";
import * as integrationsApi from "@data/integrations";
import { ImportStarted } from "@modules/settings/components/overlays/StripeIntegrationOverlay/ImportStarted";
import { SelectMatchingCriteria } from "@modules/settings/components/overlays/StripeIntegrationOverlay/SelectMatchingCriteria";
import { SelectMatchingFieldCriteria } from "@modules/settings/components/overlays/StripeIntegrationOverlay/SelectMatchingField";
import { useMutation } from "@tanstack/react-query";
import { Alert } from "@ui/Alert";
import { Button } from "@ui/Button";
import { Icon } from "@ui/Icon";
import { Overlay, OverlayModal } from "@ui/Overlay";
import React, { useState } from "react";

type StripeIntegrationInstallOverlayProps = {
  onClose: () => void;
  stripeApiKey: string;
  stripePublicKey: string;
};

export const StripeIntegrationInstallOverlay = ({
  onClose,
  stripeApiKey,
  stripePublicKey,
}: StripeIntegrationInstallOverlayProps) => {
  // step 0 - select criteria, step 1 - select field for billing_meta_object criteria, step 2 - finish screeen
  const [step, setStep] = useState<number>(0);

  const installIntegrationMutation = useMutation({
    mutationFn: integrationsApi.installIntegration,
  });

  const [matchingCriteria, setMatchingCriteria] = useState<string | undefined>(
    undefined,
  );

  const handleCriteriaSelection = (criteria: string) => {
    setMatchingCriteria(criteria);
    if (criteria === "billing_meta_object") {
      setStep(1);
    } else {
      installIntegrationMutation.mutate(
        {
          config: {
            api_key: stripeApiKey,
            public_key: stripePublicKey,
          },
          type: "stripe",
          company_matching_criteria: matchingCriteria,
          company_matching_field: "none",
        },
        {
          onSuccess: () => setStep(2),
          onError: () => setStep(2),
        },
      );
    }
  };

  const handleMatchingKeySection = async (key: string) => {
    if (!key) {
      console.error("No field selected");
      return;
    }
    installIntegrationMutation.mutate(
      {
        config: {
          api_key: stripeApiKey,
          public_key: stripePublicKey,
        },
        type: "stripe",
        company_matching_criteria: matchingCriteria,
        company_matching_field: key,
      },
      {
        onSuccess: () => setStep(2),
        onError: () => setStep(2),
      },
    );
  };

  return (
    <Overlay onClose={onClose} className="flex items-center justify-center">
      <OverlayModal size={step == 0 ? "xl" : "md"}>
        <div
          onClick={onClose}
          className="inline-flex absolute z-[500] top-6 right-6 hover:cursor-pointer text-black/50 hover:text-blue-400"
        >
          <Icon name="close" className="text-3xl" />
        </div>

        {installIntegrationMutation.isError && (
          <div className="px-12 py-12">
            <div className="text-2xl text-center">
              Failed to install integration
            </div>
            <Alert size="xs" style="red" className={"mt-6"}>
              <div className="flex items-center justify-center space-x-2">
                <div className="text-base font-body ">
                  <span className="font-semibold">Uh-oh!</span>{" "}
                  {installIntegrationMutation.error.message}
                </div>
              </div>
            </Alert>
          </div>
        )}

        {!installIntegrationMutation.isError && (
          <div className="p-12 space-y-1">
            {step == 0 && (
              <SelectMatchingCriteria onSelected={handleCriteriaSelection} />
            )}

            {step == 1 && (
              <SelectMatchingFieldCriteria
                onSelected={handleMatchingKeySection}
                stripeApiKey={stripeApiKey}
              />
            )}

            {installIntegrationMutation.isPending && <SchematicOverlayLoader />}
            {installIntegrationMutation.isSuccess && (
              <div>
                <ImportStarted matching_criteria={matchingCriteria ?? "none"} />
                <div className="flex flex-row justify-end">
                  <Button onClick={onClose}>Close</Button>
                </div>
              </div>
            )}
          </div>
        )}
      </OverlayModal>
    </Overlay>
  );
};
