import SchematicOverlayLoader from "@components/loaders/SchematicOverlayLoader";
import * as integrationsApi from "@data/integrations";
import { ImportStarted } from "@modules/settings/components/overlays/StripeIntegrationOverlay/ImportStarted";
import { SelectMatchingCriteria } from "@modules/settings/components/overlays/StripeIntegrationOverlay/SelectMatchingCriteria";
import { useMutation } from "@tanstack/react-query";
import { Alert } from "@ui/Alert";
import { Button } from "@ui/Button";
import { Icon } from "@ui/Icon";
import { Overlay, OverlayModal } from "@ui/Overlay";
import React, { useState } from "react";
import { SelectMatchingFieldCriteriaV2 } from "./SelectMatchingFieldV2";

type StripeIntegrationInstallOverlayProps = {
  onClose: () => void;
  integrationId: string;
};

export const StripeIntegrationInstallOverlayV2 = ({
  onClose,
  integrationId,
}: StripeIntegrationInstallOverlayProps) => {
  const [apiError] = useState<string | undefined>();
  // step 0 - select criteria, step 1 - select field for billing_meta_object criteria, step 2 - finish screeen
  const [step, setStep] = useState<number>(0);

  const startDataImportMutation = useMutation({
    mutationFn: integrationsApi.startDataImport,
  });

  const [matchingCriteria, setMatchingCriteria] = useState<string | undefined>(
    undefined,
  );

  const handleCriteriaSelection = (criteria: string) => {
    setMatchingCriteria(criteria);
    if (criteria === "billing_meta_object") {
      setStep(1);
    } else {
      startDataImportMutation.mutate(
        {
          integration_id: integrationId,
          company_matching_criteria: matchingCriteria,
          company_matching_field: "none",
        },
        {
          onSuccess: () => setStep(2),
          onError: () => setStep(2),
        },
      );
    }
  };

  const handleMatchingKeySection = async (key: string) => {
    if (!key) {
      console.error("No field selected");
      return;
    }
    startDataImportMutation.mutate(
      {
        integration_id: integrationId,
        company_matching_criteria: matchingCriteria,
        company_matching_field: key,
      },
      {
        onSuccess: () => setStep(2),
        onError: () => setStep(2),
      },
    );
  };

  return (
    <Overlay onClose={onClose} className="flex items-center justify-center">
      <OverlayModal size={step == 0 ? "xl" : "md"}>
        <div
          onClick={onClose}
          className="inline-flex absolute z-[500] top-6 right-6 hover:cursor-pointer text-black/50 hover:text-blue-400"
        >
          <Icon name="close" className="text-3xl" />
        </div>

        <div className="p-12 space-y-1">
          {step == 0 && (
            <SelectMatchingCriteria onSelected={handleCriteriaSelection} />
          )}

          {step == 1 && (
            <SelectMatchingFieldCriteriaV2
              onSelected={handleMatchingKeySection}
            />
          )}

          {startDataImportMutation.isPending && <SchematicOverlayLoader />}
          {startDataImportMutation.isSuccess && (
            <div>
              <ImportStarted matching_criteria={matchingCriteria ?? "none"} />
              <div className="flex flex-row justify-end">
                <Button onClick={onClose}>Close</Button>
              </div>
            </div>
          )}
        </div>

        {apiError && (
          <div className="px-12 py-12">
            <Alert size="xs" style="red">
              <div className="flex items-center justify-center space-x-2">
                <div className="text-base font-body ">
                  <span className="font-semibold">Uh-oh!</span> {apiError}
                </div>
              </div>
            </Alert>
          </div>
        )}
      </OverlayModal>
    </Overlay>
  );
};
