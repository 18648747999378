import { EntityTraitDefinitionResponseData } from "@models/api";
import { EntityType } from "@models/entityTrait";
import { Pill } from "@ui/Pill";
import { formatDate } from "@utils/date";
import { OptionLabel } from "../OptionLabel";

export const EntityTraitLabel = ({
  entityTrait: { createdAt, entityType, hierarchy },
}: {
  entityTrait: EntityTraitDefinitionResponseData;
}) => {
  const pillColor = entityType === EntityType.Company ? "blue" : "green";

  const label = (
    <div className="flex items-center justify-between space-x-2">
      <span>{hierarchy.join(".")}</span>
      <Pill text="normal" className="capitalize text-xs" color={pillColor}>
        {entityType}
      </Pill>
    </div>
  );

  return (
    <OptionLabel label={label}>
      <span className="mt-1 traits">
        First seen {formatDate(createdAt, false)}
      </span>
    </OptionLabel>
  );
};
