import { BlankStateOnCreate } from "@ui/PageBlankState";
import { TableBlankState } from "@ui/TableBlankState";

export const CompanyOverridesTableBlankState = ({
  onCreate,
  disabled,
}: BlankStateOnCreate) => {
  return (
    <TableBlankState
      title="📦 Add company override"
      description={
        <>
          Create an override to grant access to a feature specifically for this
          company. <br />
          This override gets evaluated ahead of all other rules.
        </>
      }
      buttonText="Add override"
      onCreate={onCreate}
      disabled={disabled}
    />
  );
};
