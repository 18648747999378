import { useNavigateEnvironment } from "@hooks/useNavigateEnvironment";
import { IntegrationsPage } from "@modules/integrations/consts";
import { Button } from "@ui/Button";
import { Elevate } from "@ui/Elevate";
import { Icon } from "@ui/Icon";
import { Link } from "react-router-dom";

type ConnectStripeBreakdownProps = {
  page: "config" | "details-tab";
  onSyncStripeButtonClick?: () => void;
};

export const ConnectStripeBreakdown = ({
  page,
  onSyncStripeButtonClick,
}: ConnectStripeBreakdownProps) => {
  const navigate = useNavigateEnvironment();

  return (
    <Elevate className="flex flex-col">
      <div className="flex justify-between">
        <div className="flex flex-col">
          <div className="text-xl">
            {page === "config" && (
              <>
                Connect Stripe to unlock plan life cycle management, and more.
              </>
            )}
            {page === "details-tab" && (
              <>Synchronize Schematic plans to Stripe products</>
            )}
          </div>
          <div className="text-xs mt-2">
            These rules apply when companies make plan changes using a
            component.
          </div>
          {page === "config" && (
            <div className="flex mt-6 space-x-2 items-start">
              <Button
                type="button"
                color="blue"
                onClick={() => {
                  navigate(
                    `integrations/${IntegrationsPage.StripeIntegration}`,
                  );
                }}
              >
                Connect Stripe
              </Button>
              <Button
                type="button"
                onClick={() =>
                  window.open(
                    "https://docs.schematichq.com/integrations/stripe",
                    "_blank",
                  )
                }
              >
                View docs
              </Button>
            </div>
          )}
          {page === "details-tab" && (
            <div className="flex mt-6 space-x-6 items-start">
              <Button
                type="button"
                color="blue"
                onClick={onSyncStripeButtonClick}
              >
                Sync Stripe product with plan
              </Button>
              <div className="h-full flex items-center">
                Requires{" "}
                <Link
                  to="https://docs.schematichq.com/integrations/stripe"
                  target="_blank"
                  className="text-blue-400 ml-1"
                >
                  Stripe integration
                </Link>
              </div>
            </div>
          )}
        </div>

        <div className="pr-8">
          <img
            alt="Connect Stripe to Schematic"
            src="/connect-stripe.png"
            className="max-h-36"
          />
        </div>
      </div>
      <table className="mt-8">
        <thead>
          <tr className="text-sm text-gray-400">
            <th className="pb-2 font-normal text-left">Feature</th>
            <th className="pb-2 px-10 font-normal whitespace-nowrap">
              Non-Stripe
            </th>
            <th className="pb-2 px-10 font-normal whitespace-nowrap">
              With Stripe
            </th>
          </tr>
        </thead>
        <tbody>
          <tr className="border-t border-gray-300">
            <td className="py-4">
              <div className="flex flex-col space-y-2">
                <div className="text-sm font-semibold">Base platform</div>
                <div className="text-xs">
                  Flags, features, entitlement, plans, events, audit log, and
                  more.
                </div>
              </div>
            </td>
            <td className="py-4">
              <div className="flex justify-center">
                <Icon
                  name="check-rounded"
                  className="text-3xl text-green-300 leading-none"
                />
              </div>
            </td>
            <td className="py-4">
              <div className="flex justify-center">
                <Icon
                  name="check-rounded"
                  className="text-3xl text-green-300 leading-none"
                />
              </div>
            </td>
          </tr>
          <tr className="border-t border-gray-300">
            <td className="py-4">
              <div className="flex flex-col space-y-2">
                <div className="text-sm font-semibold">
                  Read-only Component elements
                </div>
                <div className="text-xs">
                  Plan viewer, features, usage, limits, generics (button and
                  text)
                </div>
              </div>
            </td>
            <td className="py-4">
              <div className="flex justify-center">
                <Icon
                  name="check-rounded"
                  className="text-3xl text-green-300 leading-none"
                />
              </div>
            </td>
            <td className="py-4">
              <div className="flex justify-center">
                <Icon
                  name="check-rounded"
                  className="text-3xl text-green-300 leading-none"
                />
              </div>
            </td>
          </tr>
          <tr className="border-t border-gray-300">
            <td className="py-4">
              <div className="flex flex-col space-y-2">
                <div className="text-sm font-semibold">
                  Subscription lifecycle management
                </div>
                <div className="text-xs">
                  View Company subscriptions and sync billing products to plans.
                  Changes made in Schematic to a company’s entitlements impact
                  their subscription.
                </div>
              </div>
            </td>
            <td className="py-4"></td>
            <td className="py-4">
              <div className="flex justify-center">
                <Icon
                  name="check-rounded"
                  className="text-3xl text-green-300 leading-none"
                />
              </div>
            </td>
          </tr>
          <tr className="border-t border-gray-300">
            <td className="py-4">
              <div className="flex flex-col space-y-2">
                <div className="text-sm font-semibold">
                  Subscription Component elements
                </div>
                <div className="text-xs">
                  Checkout: upgrade, downgrade, subscribe, unsubscribe, edit
                  quantity. View upcoming bill, manage payment method, and see
                  invoices.
                </div>
              </div>
            </td>
            <td className="py-4"></td>
            <td className="py-4">
              <div className="flex justify-center">
                <Icon
                  name="check-rounded"
                  className="text-3xl text-green-300 leading-none"
                />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </Elevate>
  );
};
