import cx from "classnames";
import { FC } from "react";
import { Button, ButtonProps } from "../Button";

interface ButtonGroupProps {
  buttons: ButtonProps[];
  className?: string;
  ButtonComponent?: FC<ButtonProps>;
  style?: "vertical" | "horizontal";
}

export const ButtonGroup = ({
  buttons,
  className,
  ButtonComponent = Button,
  style = "horizontal",
}: ButtonGroupProps) => {
  const styleMap = {
    vertical: "space-y-2",
    horizontal: "space-x-2",
  };
  const styles = cx("", styleMap[style], className);
  return (
    <div className={styles}>
      {buttons.map((buttonProps: ButtonProps, index: number) => {
        const { children, ...rest } = buttonProps;
        return (
          children && (
            <ButtonComponent {...rest} key={index}>
              {children}
            </ButtonComponent>
          )
        );
      })}
    </div>
  );
};
