/* tslint:disable */
/* eslint-disable */
/**
 * Schematic API
 * Schematic API
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from "../runtime";
/**
 *
 * @export
 * @interface CreateEntityTraitDefinitionRequestBody
 */
export interface CreateEntityTraitDefinitionRequestBody {
  /**
   *
   * @type {string}
   * @memberof CreateEntityTraitDefinitionRequestBody
   */
  displayName?: string;
  /**
   *
   * @type {string}
   * @memberof CreateEntityTraitDefinitionRequestBody
   */
  entityType: CreateEntityTraitDefinitionRequestBodyEntityTypeEnum;
  /**
   *
   * @type {Array<string>}
   * @memberof CreateEntityTraitDefinitionRequestBody
   */
  hierarchy: Array<string>;
  /**
   *
   * @type {string}
   * @memberof CreateEntityTraitDefinitionRequestBody
   */
  traitType: CreateEntityTraitDefinitionRequestBodyTraitTypeEnum;
}

/**
 * @export
 */
export const CreateEntityTraitDefinitionRequestBodyEntityTypeEnum = {
  Company: "company",
  User: "user",
} as const;
export type CreateEntityTraitDefinitionRequestBodyEntityTypeEnum =
  (typeof CreateEntityTraitDefinitionRequestBodyEntityTypeEnum)[keyof typeof CreateEntityTraitDefinitionRequestBodyEntityTypeEnum];

/**
 * @export
 */
export const CreateEntityTraitDefinitionRequestBodyTraitTypeEnum = {
  Boolean: "boolean",
  Currency: "currency",
  Date: "date",
  Number: "number",
  String: "string",
  Url: "url",
} as const;
export type CreateEntityTraitDefinitionRequestBodyTraitTypeEnum =
  (typeof CreateEntityTraitDefinitionRequestBodyTraitTypeEnum)[keyof typeof CreateEntityTraitDefinitionRequestBodyTraitTypeEnum];

/**
 * Check if a given object implements the CreateEntityTraitDefinitionRequestBody interface.
 */
export function instanceOfCreateEntityTraitDefinitionRequestBody(
  value: object,
): value is CreateEntityTraitDefinitionRequestBody {
  if (!("entityType" in value) || value["entityType"] === undefined)
    return false;
  if (!("hierarchy" in value) || value["hierarchy"] === undefined) return false;
  if (!("traitType" in value) || value["traitType"] === undefined) return false;
  return true;
}

export function CreateEntityTraitDefinitionRequestBodyFromJSON(
  json: any,
): CreateEntityTraitDefinitionRequestBody {
  return CreateEntityTraitDefinitionRequestBodyFromJSONTyped(json, false);
}

export function CreateEntityTraitDefinitionRequestBodyFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): CreateEntityTraitDefinitionRequestBody {
  if (json == null) {
    return json;
  }
  return {
    displayName:
      json["display_name"] == null ? undefined : json["display_name"],
    entityType: json["entity_type"],
    hierarchy: json["hierarchy"],
    traitType: json["trait_type"],
  };
}

export function CreateEntityTraitDefinitionRequestBodyToJSON(
  value?: CreateEntityTraitDefinitionRequestBody | null,
): any {
  if (value == null) {
    return value;
  }
  return {
    display_name: value["displayName"],
    entity_type: value["entityType"],
    hierarchy: value["hierarchy"],
    trait_type: value["traitType"],
  };
}
