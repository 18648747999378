import { TableLoader } from "@components/loaders/TableLoader";
import { usePermission } from "@hooks/usePermission";
import useTablePagination from "@hooks/useTablePagination";
import { ComponentResponseData, ListComponentsParams } from "@models/api";
import { ClerkUserPermission } from "@models/clerkUser";
import { ComponentType, ComponentTypeDisplay } from "@models/component";
import { FeaturesTableEmptyFilterState } from "@modules/features";
import { ColumnDef } from "@tanstack/react-table";
import { Button, ButtonProps } from "@ui/Button";
import { DropdownDots } from "@ui/DropdownDots";
import { Table } from "@ui/Table";
import { TableHeader } from "@ui/TableHeader";
import pluralize from "pluralize";
import React, { SyntheticEvent, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ComponentsBlankState } from "./ComponentsBlankState";
import { ComponentDeleteOverlay } from "./overlays";
import { countComponents, listComponents } from "../queries";

export interface ComponentsTableProps {
  onCreate?: () => void;
}

export const ComponentsTable = ({ onCreate }: ComponentsTableProps) => {
  const navigate = useNavigate();
  const [filter] = useState<ListComponentsParams>({});

  const [deleteOverlay, setDeleteOverlay] =
    useState<ComponentResponseData | null>();

  const componentEditAllowed = usePermission(
    ClerkUserPermission.components_edit,
  );

  const columns = useMemo<ColumnDef<ComponentResponseData>[]>(() => {
    return [
      {
        id: "name",
        header: "Name",
        accessorKey: "name",
      },
      {
        id: "type",
        header: "Type",
        accessorKey: "type",
        size: 140,
        cell: (cellInfo) => {
          const { type } = cellInfo.row.original;

          return <div>{ComponentTypeDisplay[type as ComponentType]}</div>;
        },
      },
      {
        id: "actions",
        header: "",
        accessorKey: "actions",
        cell: (cellInfo) => {
          const component = cellInfo.row.original;

          return (
            <div className="flex flex-row items-end justify-end">
              <DropdownDots
                links={[
                  {
                    label: "Delete",
                    disabled: !componentEditAllowed,
                    onClick: () => {
                      setDeleteOverlay(component);
                    },
                  },
                  {
                    label: "Edit",
                    disabled: !componentEditAllowed,
                    onClick: () => {
                      navigate(component.id);
                    },
                  },
                ]}
              />
            </div>
          );
        },
      },
    ];
  }, [componentEditAllowed, navigate]);

  const getHeaderText = (count: number) => {
    return pluralize("Components", count, true);
  };

  const {
    countQuery,
    headerText,
    listQuery,
    pageCount,
    pageIndex,
    pageSize,
    setPagination,
  } = useTablePagination<ComponentResponseData, ListComponentsParams>(
    ["components"],
    listComponents,
    countComponents,
    filter,
    getHeaderText,
  );

  const onRowClick = (row: ComponentResponseData, e?: SyntheticEvent) => {
    e?.preventDefault();
    e?.stopPropagation();
    navigate(row.id);
  };

  if (listQuery.error) throw listQuery.error;
  if (countQuery.error) throw countQuery.error;

  const headerButtons: ButtonProps[] = [
    {
      children: <>New component</>,
      color: "blue",
      disabled: !componentEditAllowed,
      onClick: onCreate,
    },
  ];

  const noComponentsCreated = countQuery?.data?.count === 0;
  const loading =
    listQuery.isLoading || countQuery.isLoading || !listQuery.data;

  const renderComponentsTable = () => {
    switch (true) {
      case noComponentsCreated:
        return (
          <ComponentsBlankState
            onCreate={onCreate}
            disabled={!componentEditAllowed}
          />
        );
      case loading:
        return <TableLoader />;
      case listQuery.data?.length === 0:
        return <FeaturesTableEmptyFilterState />;
      default:
        return (
          listQuery?.data && (
            <Table
              columns={columns}
              data={listQuery.data}
              onRowClick={onRowClick}
              pageCount={pageCount}
              pageIndex={pageIndex}
              pageSize={pageSize}
              setPagination={setPagination}
            />
          )
        );
    }
  };

  const templates = [
    {
      title: "Customer Portal",
      description:
        "All in one. Let users upgrade/downgrade and view their plan, usage, and billing information.",
      image: "/builder/templates/thumbs/customer-portal.jpg",
      button: {
        onClick: onCreate,
        children: "Start with Customer Portal",
      },
    },
    {
      title: "Usage Dashboard",
      description:
        "Transparency. Allow users to monitor feature utilization, see their limits, and upgrade.",
      image: "/builder/templates/thumbs/usage-dashboard.jpg",
      button: {
        onClick: () => {},
        children: "Coming soon",
        disabled: true,
      },
    },
    {
      title: "Dark Mode Mobile",
      description:
        "Sleek on the go. A customer portal optimized for mobile and dark UIs. Customize as needed.",
      image: "/builder/templates/thumbs/dark-mode-mobile.jpg",
      button: {
        onClick: () => {},
        children: "Coming soon",
        disabled: true,
      },
    },
    {
      title: "Account Dashboard",
      description:
        "Sales-led. Provide insights and usage transparency. Encourage customers to book meetings with reps.",
      image: "/builder/templates/thumbs/enterprise-portal.jpg",
      button: {
        onClick: () => {},
        children: "Start with Enterprise Portal",
        disabled: true,
      },
    },
    {
      title: "Pricing Table",
      description:
        "The menu. Users can view all plans, compare features, limits, and while considering buying or upgrading.",
      image: "/builder/templates/thumbs/pricing-table.jpg",
      button: {
        onClick: () => {},
        children: "Coming soon",
        disabled: true,
      },
    },
    {
      title: "Feature Trial Popup",
      description:
        "Appetizer. Encourage usage with  time-limited feature overrides. Alert users with a popup and timer widget.",
      image: "/builder/templates/thumbs/feature-trial-popup.jpg",
      button: {
        onClick: () => {},
        children: "Coming soon",
        disabled: true,
      },
    },
  ];

  return (
    <div className="pb-16">
      {!noComponentsCreated && (
        <TableHeader headerText={headerText} buttons={headerButtons} />
      )}

      {renderComponentsTable()}

      <div className="mt-12">
        <div className="text-3xl mb-8">Templates</div>
        <div className="grid grid-cols-3 gap-12">
          {templates.map((t, index) => {
            return (
              <div
                className="inline-flex flex-col min-w-[200px] overflow-hidden rounded-md bg-white shadow-md"
                key={index}
              >
                <div className="inline-block w-full border-b border-gray-400/40 max-h-[200px] overflow-hidden">
                  <img src={t.image} className="w-full" alt="" />
                </div>
                <div className="flex flex-col space-y-2 p-7 text-black">
                  <h6 className="font-display m-0 text-lg text-left !text-black">
                    {t.title}
                  </h6>
                  <p className="text-sm text-gray-500">{t.description}</p>

                  <div className="pt-4">
                    <Button
                      onClick={t.button.onClick}
                      size="md"
                      disabled={t.button.disabled}
                    >
                      {t.button.children}
                    </Button>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      {deleteOverlay && (
        <ComponentDeleteOverlay
          component={deleteOverlay}
          onClose={() => {
            setDeleteOverlay(null);
          }}
        />
      )}
    </div>
  );
};
